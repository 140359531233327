

























    /* eslint-disable @typescript-eslint/camelcase */  
    import {   bateriaService } from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
 
    @Component({
        components: { },
    })
    export default class IndexGuia extends Vue { 
 
        @gerente.State
		public modulo!: any
 
        private  reloadDatatable = false ; 
        
        private config = {  
            order: [  [2, "asc"] ],
            ajax: {
                url: bateriaService.getUrl() + '/datatable',
            },
            columns: [ 
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'nome',  name: 'nome'  },  
                { data: 'nivel',  name: 'nivel'  },  
                { data: 'ativo',  name: 'ativo'  },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ], 
        };
  
        created() { 
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			} 
            this.config.ajax.url =  bateriaService.getUrl() + '/modulo/' + this.modulo.id + '/datatable'
        }
 
        public tableatualizada( ) {   
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/bateria/edit/' + id);  
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/administracao/bateria/show/' + id);
            });  
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        bateriaService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

        }
    }
 
