

















































































































 
	import { assuntoService, assuntoAdminService }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
	import Busca  from '@/views/v2/administracao/assunto/LocalizarPerguntaResposta.vue';
	import Duplicadas  from '@/views/v2/administracao/assunto/PerguntasDuplicadas.vue';
	import Pergunta  from '@/views/v2/administracao/assunto/Pergunta.vue';
      
    @Component({
        components: { Busca , Duplicadas , Pergunta},
    })
    export default class Principal extends Vue { 

        public model: any = null; 
        public ano: any = '2018'; 
        public principal: any = null; 
        public apagar: any = null; 
        private quantidade: any = 0;

		created() { 
			window.alertProcessando();
			assuntoAdminService.getAssunto( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide(); 
				window.toastErro('Não foi possivel achar Assunto');
			}); 
		}
 
        public liberarQuestoesLocal( ) {  
            window.alertProcessando(); 
            const dados = {
                ano: this.ano
            }
            assuntoService.responderApi( this.$route.params.id , dados   )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('liberarQuestoes realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 10 ){
                    this.liberarQuestoesLocal( );
                    console.log( this.quantidade );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel liberarQuestoes ' , error.data); 
                this.quantidade = 0
            }); 
        }
		
        public tornarPerguntaCriadaAnteriorAoAnoLocal( ) {  
            window.alertProcessando(); 
            const dados = {
                ano: this.ano
            }
            assuntoService.tornarPerguntaCriadaAnteriorAoAno( this.$route.params.id , dados   )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('tornarPerguntaCriada realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 10 ){
                    this.tornarPerguntaCriadaAnteriorAoAnoLocal( );
                    console.log( this.quantidade );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel tornarPerguntaCriada ' , error.data); 
                this.quantidade = 0
            }); 
        }
		
        public limparSubjectAssunto( ) {  
            if(this.principal != null && this.apagar != null){
                window.alertProcessando(); 
                const dados = {
                    principal: this.principal,
                    delete: this.apagar,
                }
                assuntoService.limparSubjectAssunto( this.$route.params.id , dados   )
                .then(response => {
                    window.alertProcessandoHide();   
                    window.toastSucesso('limparSubjectAssunto realizada com sucesso!!' , response  );    
                })
                .catch(error => { 
                    window.alertProcessandoHide();
                    window.toastErro('Não foi possivel limparSubjectAssunto ' , error.data);  
                }); 
            }
            
        }
		

             
        public adicionarAoAssunto( ) {  
            window.alertConfimacao('Confirma a adicionar Ao Assunto:',  "principal:" +  this.principal + "  delete:" + this.apagar , 
                () => {  
                    this.adicionarAoAssuntoApi( );
                }
            ); 
        }


        public adicionarAoAssuntoApi( ) {  
            if(this.principal != null && this.apagar != null){
                window.alertProcessando(); 
                const dados = {
                    principal: this.principal,
                    delete: this.apagar,
                }
                assuntoService.adicionarAoAssunto( this.$route.params.id , dados   )
                .then(response => {
                    window.alertProcessandoHide();   
                    window.toastSucesso('adicionarAoAssunto realizada com sucesso!!' , response  );    
                })
                .catch(error => { 
                    window.alertProcessandoHide();
                    window.toastErro('Não foi possivel adicionarAoAssunto ' , error.data);  
                }); 
            } 
        }
		     
        public liberarQuestoes( ) {  
            window.alertConfimacao('Confirma a liberarQuestoes ','' , 
                () => {  
                    this.liberarQuestoesLocal( );
                }
            ); 
        }
		     
        public tornarPerguntaCriadaAnteriorAoAno( ) {  
            window.alertConfimacao('Confirma a tornar Pergunta Criada Anterior Ao Ano ','' , 
                () => {  
                    this.tornarPerguntaCriadaAnteriorAoAnoLocal( );
                }
            ); 
        }

		     
        public subirQuestoes( ) {  
            window.alertConfimacao('Confirma a subirQuestoes ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoAdminService.subirQuestoes(   this.$route.params.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('subirQuestoes realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel subirQuestoes ' , error.data); 
                    });  
                }
            ); 
        }
		     
        public tags( ) {  
            window.open('#/assunto/' +  this.$route.params.id + '/tag', '_blank');  
            // this.$router.push('/assunto/' +  this.$route.params.id + '/tag');
        }
		     
        public questionariarPerguntaSemTag( ) {  
            window.alertConfimacao('Confirma a questionariarPerguntaSemTag ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoService.questionariarPerguntaSemTag(   this.$route.params.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('questionariarPerguntaSemTag realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel questionariarPerguntaSemTag ' , error.data); 
                    });  
                }
            ); 
        }
		     
        public migrarPerguntaPrincipalToSecundario( ) {  
            window.alertConfimacao('Confirma a migrarPerguntaPrincipalToSecundario ','' , 
                () => {  
                    this.migrarPerguntaPrincipalToSecundarioApi( ); 
                }
            ); 
        }

        public migrarPerguntaPrincipalToSecundarioApi( ) {  
            window.alertProcessando(); 
            assuntoService.migrarPerguntaPrincipalToSecundario(   this.$route.params.id )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('migrarPerguntaPrincipalToSecundario realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 10 ){
                    this.migrarPerguntaPrincipalToSecundarioApi( );
                    console.log( this.quantidade );
                }
                else{
                    this.quantidade = 0
                }  
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel migrarPerguntaPrincipalToSecundario ' , error.data); 
                this.quantidade = 0
            });  
        }
  
        public migrarPerguntaSecundarioToPrincipal( ) {  
            window.alertConfimacao('Confirma a migrarPerguntaSecundarioToPrincipal ','' , 
                () => {  
                    this.migrarPerguntaSecundarioToPrincipalApi( );
                }
            ); 
        }

        public migrarPerguntaSecundarioToPrincipalApi( ) {   
            window.alertProcessando(); 
            assuntoService.migrarPerguntaSecundarioToPrincipal(   this.$route.params.id )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('migrarPerguntaSecundarioToPrincipal realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 10 ){
                    this.migrarPerguntaSecundarioToPrincipalApi( );
                    console.log( this.quantidade );
                }
                else{
                    this.quantidade = 0
                }  
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel migrarPerguntaSecundarioToPrincipal ' , error.data); 
                this.quantidade = 0
            });   
        }
            
        public duplicadas( ) {  
            window.alertConfimacao('Confirma a duplicadas ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoService.duplicadas(   this.$route.params.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('duplicadas realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel duplicadas ' , error.data); 
                    });  
                }
            ); 
        }
		     
        public adicionarPerguntasDosConceitosAoAssunto( ) {  
            window.alertConfimacao('Confirma a duplicadas ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoService.adicionarPerguntasDosConceitosAoAssunto(   this.$route.params.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('adicionarPerguntasDosConceitosAoAssunto realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel adicionarPerguntasDosConceitosAoAssunto ' , error.data); 
                    });  
                }
            ); 
        }
		     
        public restritarOAB( ) {  
            window.alertConfimacao('Confirma a restritarOAB ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoService.restritarOAB(   this.$route.params.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('restritarOAB realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel restritarOAB ' , error.data); 
                    });  
                }
            ); 
        }


	}
	 
