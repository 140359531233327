export function authHeader() { 
    const token = localStorage.getItem('user');
 

    if (token) {
        return { 'Authorization': 'Bearer ' + JSON.parse( token ) }; 
    } 
    else {
        return {};
    }
}

 