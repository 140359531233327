/* eslint-disable @typescript-eslint/camelcase */  
import { authHeader } from '..'; 
import { administradorModel } from './index.model';   
import axios  from 'axios';  
const url = administradorModel.getUrl() + '/user_plano' ;  
import  store   from '../../store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}



function getModel( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 


 


export const userPlanoAdminService = { 
	getUrl,
	getModel, 
};