

















 
	 
	import { disciplinaAdminService  }  from '@/servicegerente';  
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
    import $ from "jquery" 
   
	@Component({
		components: {  
        },
	 })
    export default class DisciplinaAssuntos extends Vue {


		private disciplina: any= null; 
		private assuntos: any= null; 
		private reloadDatatable: any= false; 
		 
		private url_retorno: any= '/disciplina';  
		 

		private config: any= { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: disciplinaAdminService.getUrl() + '/' + this.$route.params.id + '/assunto/datatable'
			},
			columns: [
				{ data: 'nome', name: 'assunto.nome' },
				{ data: 'descricao', name: 'assunto.descricao' }, 
			],
		};  

 

		created() { 
			window.alertProcessando();
			disciplinaAdminService.getDisciplina( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.disciplina = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Modulo', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}
    
	}

 

