





























































  
	/* eslint-disable @typescript-eslint/camelcase */ 
 
	import { perguntaService }  from '@/servicegerente';

	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class TipoReferenciaCadastro extends Vue {

		public url: any = null;
		public disciplina: any = null; 
		public assunto: any = null; 
		public init: any = null; 
		public page: any = null; 
		 
		public urlRetorno: any= '/v2/administracao/configuracao';

		public errors: any = { };


		salvar() {
			window.alertProcessando();
			const dados = {
				url :this.url,
				disciplina :this.disciplina,  
				assunto :this.assunto,  
				init :this.init,  
				page :this.page,  
			};  
			 
			 
			// perguntaService.cadastrarApi(   dados  )
			// .then(response => {
			// 	window.toastSucesso(response);  
			// 	window.alertProcessandoHide(); 
			// })
			// .catch(error => {  
			// 	window.alertProcessandoHide();
			// 	this.errors = error.data.error; 
			// 	console.log(error);
			// });  
		} 


	}

 

