





















	
	import { userService  }  from '@/servicegerente';
	import { Component, Vue } from 'vue-property-decorator';

	@Component({
        components: { 
        },
    })
    export default class UsuarioHistoricoPerfil extends Vue {

		private config = { 
            order: [[ 3, "desc" ]],
			ajax: { 
				url: userService.getUrl() + '/' + this.$route.params.id + '/perfil/log/datatable'
			},
			columns: [ 
				{ data: 'autor.nome', name: 'autor.nome'  },
				{ data: 'acao', name: 'usuario_perfil_log.acao'  },
				{ data: 'perfil.nome', name: 'perfil.nome'  }, 
				{ data: 'created_at', name: 'created_at'  },
				{ data: 'ip_v4', name: 'ip_v4'  },
				{ data: 'host', name: 'host'  }, 
			],
        };

		private  url_retorno = '/usuario/' + this.$route.params.id + '/perfil' ; 
		

	}
	 
