






























	/* eslint-disable @typescript-eslint/camelcase */  
  
	import { assuntoService  }  from '@/servicegerente';
    import $ from "jquery"  
 
	import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: { 
        },
    })
    export default class PerfilPermissao extends Vue {
 

        private assunto: any= null; 
        
		private reloadDatatable: any= false;  

		private url_retorno: any= '/assunto';

		private config: any= { 
			order: [[ 4, "asc" ]],
			ajax: { 
				url: assuntoService.getUrl() + '/' + this.$route.params.id + '/pergunta/datatable'
			},
			columns: [
				{ data: 'id', name: 'pergunta.id'  },   
				   
				{ data: 'texto_clean', name: 'texto_clean', orderable: false },   
				{ data: 'ano', name: 'ano' },  

				{ data: 'subjects', name: 'subjects' },  
				{ data: 'status', name: 'status' },   
				{ data: 'orgao', name: 'orgao' },  
				{ data: 'correta', name: 'correta' , orderable: false, searchable: false },   
				{ data: 'rendimento', name: 'rendimento', class: 'text-center' },
				{ data: 'tags_count', name: 'tags_count', class: 'text-center',    searchable: false, }, 
				{ data: 'cards_count', name: 'cards_count', class: 'text-center',   searchable: false, }, 
				{ data: 'videoaula', name: 'pergunta.videoaula', class: 'text-center', searchable: false,}, 
				{ data: 'resposta_certa_id', name: 'resposta_certa_id' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		}; 
		

		created() { 
			window.alertProcessando();
			assuntoService.getAssunto( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.assunto = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Assunto', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});   
		}
 
		public tableatualizada( ) {   
			$('[btn-show]').click(  (btn) => {  
				const id =   $(btn.currentTarget).data('id'); 
				window.open('#/v2/administracao/pergunta/show/' + id);   
			}); 
		}
		
	}
	 

