



























































    /* eslint-disable @typescript-eslint/camelcase */ 
 
    import { assuntoAdminService, disciplinaAdminService } from '@/servicegerente';
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class AssuntoCadastro extends Vue {

        public disciplinas: any = null;
         

		public nome: any = null;
		public sigla: any= null;
		public disciplina_id: any= null;
		public descricao: any= null; 
  
		public urlRetorno: any= '/assunto';

		public errors: any = { };


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				sigla :this.sigla, 
				disciplina_id :this.disciplina_id, 
				descricao :this.descricao,  
			};  
			  
			assuntoAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


        created() { 
            disciplinaAdminService.getAll()
                .then(response => {
                    this.disciplinas = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar as disciplinas');
                }); 
        }


    }
    

 

