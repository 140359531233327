/* eslint-disable @typescript-eslint/camelcase */ 

import  { apiModel } from '@/servicegerente/api.url'; 
 
 
const url =  apiModel.getApi( ) + '/administracao'  ;
 

const secret_key = 'fgsdf8sfg*bxdfbsd*bxfdsf2323sf**';

function getUrl(  ) { 
	return  url; 
}


function getKeyCripto(  ) { 
	return  secret_key; 
}


export const gerenteModel = { 
	getUrl, 
	getKeyCripto,
};
