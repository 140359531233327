import { authHeader } from '..'; 
import { segurancaModel } from './index.model'; 
 
 

const url = segurancaModel.getUrl() + '/loginlog' ;  


function getUrl(  ) { 
	return  url; 
}
  

export const logLoginService = {
	 
	getUrl, 

};

