



















	
	import {  simuladoService }  from '@/servicegerente';  
	import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class' 
    const gerente = namespace('gerente') 
      
    @Component({
        components: { },
    })
    export default class ClassificacaoIndex extends Vue { 


        
        @gerente.State
		public modulo!: any
 
        
        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else{
                this.config.ajax.url =  simuladoService.getUrl() + '/modulo/' + this.modulo.id + '/classificacao/datatable'
			}
        }
 

        private config = { 
			order: [[ 1, "desc" ]],
			ajax: { 
				url: simuladoService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'nome', name: 'nome' }, 
				{ data: 'rendimento', name: 'rendimento' },  
				{ data: 'total', name: 'total' },  
				{ data: 'acertos', name: 'acertos' },  
				{ data: 'erros', name: 'erros' },   
			],
		};
 

	}
	 

