













































































































































































 
	/* eslint-disable @typescript-eslint/camelcase */  
	import { referenciaService, manzaTinyEditorColorsMap  }  from '@/servicegerente';
	import { bancaService  }  from '@/servicegerente';
	import {   perguntaService   }  from '@/servicegerente'; 
	import Editor from '@tinymce/tinymce-vue' 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
    const usuario = namespace('usuario')

	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoCadastro extends Vue {


		@gerente.State
		public modulo!: any
	
		@usuario.Getter
		public isAdmin!:  (  ) => boolean

        public colors: any = []; 
		
		public referencias: any = null;
		public bancas: any = null;
		public model: any = null;
	  
		public texto: any= null; 
		public banca_id: any= null; 
		public resumo: any= null;  
		public referencia_id: any= 8; 
		public referencia_questao_nr: any= null;  
		public subjects: any= null;  
		public status: any= null; 
		public videoaula: any= null; 
		public score_dificuldade: any= null; 
  
		public urlRetorno: any= '/v2/administracao/pergunta/show/' + this.$route.params.id  ;

		public errors: any = { };

		created() {

			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			
			this.colors = manzaTinyEditorColorsMap; 
 
			window.alertProcessando();
			perguntaService.getPergunta(this.$route.params.id) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a pergunta', error.data);
				window.alertProcessandoHide();
				
			});
 
			referenciaService.getAll() 
			.then(response => {
				this.referencias = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os referencias');
			});
 
			bancaService.getAll() 
			.then(response => {
				this.bancas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os bancas');
			});
 
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				texto :this.texto,
				resumo :this.resumo, 
				banca_id :this.banca_id, 
				referencia_id :this.referencia_id,
				referencia_questao_nr :this.referencia_questao_nr, 
				subjects :this.subjects, 
				status :this.status,
				videoaula :this.videoaula, 
				score_dificuldade :this.score_dificuldade, 
			};   
 
			perguntaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				window.toastErro('Não foi possivel Salvar ', error.data.message);
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 




		@Watch('model')
		modelAlterado(newVal: any) {
			this.texto = this.model.texto;
			this.resumo = this.model.resumo;  
			this.banca_id = this.model.banca_id;  
			this.status = this.model.status ;  
			this.videoaula = this.model.videoaula ;  
			this.score_dificuldade = this.model.score_dificuldade ;  
			this.referencia_id = this.model.referencia_id ; 
			this.referencia_questao_nr = this.model.referencia_questao_nr ; 
			this.subjects = this.model.subjects ; 
		}




		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		


	}
 

