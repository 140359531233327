


































 
   
	 
    import {  Component, Vue } from 'vue-property-decorator'; 
	import {  moduloService }  from '@/servicegerente'; 
	 

	@Component({
		components: { 
        },
	 })
    export default class FormPerfilPermissao extends Vue { 
         

        private imagem: any = null;  

        
		handleFileUpload(){
			this.imagem = this.$refs.imagem.files[0];
		}

        private add_imagem_id = 'modal-seguranca-pergunta-adicionar-imagem'; 
  

		salvar() {
            window.alertProcessando(); 
            const dados = new FormData(); 
			dados.append('file_imagem',this.imagem );  
 
			moduloService.salvaImagem(   dados  )
			.then(response => {
				window.toastSucesso(response.message);  
				window.alertProcessandoHide(); 
                window.open(response.url); 
			})
			.catch(error => {  
				window.alertProcessandoHide();
				window.toastErro(error.data.message);
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


 
    }

 
