





































	/* eslint-disable @typescript-eslint/camelcase */ 

	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { moduloAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormModuloDisciplinasAssuntos extends Vue {

        @Prop( ) readonly assuntos!: any

        private assunto: any = null; 
        private prioridade: any = null;
 

        private add_assunto_id = 'modal-seguranca-modulo-adicionar-assunto'; 


        AdicionarPermissao() {
            if (this.assunto && this.prioridade  ) {

                window.alertProcessando();
                const dados = {
                    assunto :this.assunto, 
                    prioridade :this.prioridade, 
                    pivot_disciplina_id : this.$route.params.pivotId, 
                    disciplina_id :  this.$route.params.disciplinaId, 
                };   

                moduloAdminService.adicionarAssunto( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("disciplina adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response);
                    // ( $('#' + this.add_assunto_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }
 
 
