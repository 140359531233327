import { authHeader } from '..';   
import axios  from 'axios';   
 
const url = "https://pontuacao.guiaonlinecursos.tec.br/api/pontuacao" ;  
    
function getLogPontuacao(  usuarioId: any, cursoId: any, take: any,skip: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get(`${url}/log?usuario_id=${usuarioId}&curso_id=${cursoId}&take=${take}&skip=${skip}` , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {   
			reject(error.response);
		}) 
	}); 
}
 
    
function getLogGame(  usuarioId: any, cursoId: any, take: any,skip: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get(`${url}/log/game?usuario_id=${usuarioId}&curso_id=${cursoId}&take=${take}&skip=${skip}` , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {   
			reject(error.response);
		}) 
	}); 
}

   
function getPontuacao(  usuarioId: any, cursoId: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get(`${url}/admin?usuario_id=${usuarioId}&curso_id=${cursoId}` , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {   
			reject(error.response);
		}) 
	}); 
}



function update(  dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( `${url}/admin`, dados, {headers: authHeader() })
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
 
export const pontuacaoVpsService = {
	getLogPontuacao,  
	getLogGame,  
	getPontuacao,  
	update,
};

