























































































































 
	/* eslint-disable @typescript-eslint/camelcase */  

	import { planoService } from '@/servicegerente';
	
	import Editor from '@tinymce/tinymce-vue'

	import {Watch, Component, Vue } from 'vue-property-decorator';
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoEdit extends Vue {
		
		private model: any = null;


		private nome: any = null;
		private icon: any= null;
		private color: any= null;
		private valor_mensal: any= null;
		private valor_semestral: any= null;
		private valor_anual: any= null;
		private caracteristica: any= null;
		private obs: any= null;

		private urlRetorno: any= '/admin/plano';
 
		private errors: any = { };

		created() {   
			window.alertProcessando();
			planoService.getModel( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Plano', error.data);
				window.alertProcessandoHide();
			}); 
		}
 
		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.icon = this.model.icon;  
			this.color = this.model.color;  
			this.valor_mensal = this.model.valor_mensal;  
			this.valor_semestral = this.model.valor_semestral;  
			this.valor_anual = this.model.valor_anual;  
			this.caracteristica = this.model.caracteristica;  
			this.obs = this.model.obs;  
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				icon :this.icon, 
				color :this.color, 
				valor_mensal :this.valor_mensal, 
				valor_semestral :this.valor_semestral, 
				valor_anual :this.valor_anual, 
				caracteristica :this.caracteristica, 
				obs :this.obs,
			};  
			 
			 
			planoService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	}
 
 

