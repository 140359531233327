


































































 
	/* eslint-disable @typescript-eslint/camelcase */  
    import { tagService , moduloService, manzaTinyEditorColorsMap } from '@/servicegerente'; 
    import ImagemFormAdicionar from '@/views/v2/administracao/pergunta/_AdicionarImagem.vue';   
    import { Watch , Component , Vue } from 'vue-property-decorator';  
	import myEditor from '@/components/tinymce/Editor.vue' 
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
 
	@Component({
		components: {  
			'imagem-form-adicionar': ImagemFormAdicionar,
			myEditor

        },
	 })
    export default class PlanoCadastro extends Vue {
		 
		public valueAtualizado(dado: any){
			this.descricao = dado;
		}
 
		@gerente.State
		public assunto!: any
 
		@gerente.State
		public modulo!: any

        public colors: any = []; 
 
        public assuntos: any = null;
         
		public nome: any= null;
		public assunto_id: any= null;
        public descricao: any= null;
        
 
		public urlRetorno: any= '/v2/administracao/conceito'; 
		public errors: any = { };
  
        created() {  
			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                if(this.assunto){
					this.assunto_id = this.assunto.id 
				}

				if(this.$route.params.assunto_id){
					this.assunto_id = this.$route.params.assunto_id;
				}

				this.buscarAssunto();
            }  
			this.colors = manzaTinyEditorColorsMap; 
        }




		public buscarAssunto(){
			moduloService.buscarTodosAssunto( this.modulo.id )
			.then(response => {
				this.assuntos = response;
			})
			.catch(() => {
				window.toastErro('Não foi possivel achar as assunto');
			}); 
		}


		salvar( close = false) {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				assunto_id :this.assunto_id, 
				descricao :this.descricao,  
			};    
			tagService.cadastrar( dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				if(close){
					window.close()
				}
				else{
					this.$router.push(  this.urlRetorno )
				} 
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

		

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }
    
 
