













    /* eslint-disable @typescript-eslint/camelcase */  
    import { audioAulaService } from '@/servicegerente'; 
    import AssuntoFormAdicionar from './_AssuntoFormAdicionar.vue'; 
    import $ from "jquery"; 
    import {  Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 

    @Component({
        components: { 
            'assunto-form-adicionar': AssuntoFormAdicionar
        },
    })
    export default class Principal extends Vue { 

        @gerente.State
		public modulo!: any 

        
        private reloadDatatable: any= false; 

        created() { 
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}  
        }
            

        private config: any= {  
            order: [  [1, "asc"] ],
            lengthMenu : [ [ -1],   [  "Todos"]  ],
            dom: '<"top"r>t<"bottom"><"clear">',
            ajax: {
                url: audioAulaService.getUrl() + '/' + this.$route.params.id + '/assunto/datatable'
            },
            columns: [
                {  data: 'nome', name: 'assunto.nome'  },
                { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center' }
            ],
        };  
        
        
        assuntoAdicionado(event: any) {  
            this.reloadDatatable = !this.reloadDatatable;
        }

 

        public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        audioAulaService.removerAssunto( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
                            this.$emit('assuntoAlterado', event);
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Assunto removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide(); 
                            window.toastErro('Não foi possivel remover o Assunto, ' , error.data); 
                        });  
                    }
                ); 
            });

        }
 
    }


 

