













































/* eslint-disable @typescript-eslint/camelcase */   
    import { tagService , manzaTinyEditorColorsMap  } from '@/servicegerente'; 
    import Editor from '@tinymce/tinymce-vue'; 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import myEditor from '@/components/tinymce/Editor.vue' 
	  
	@Component({
		components: { 
			myEditor
        },
	 })
    export default class PlanoCadastro extends Vue {

        public model: any = null;
        public colors: any = []; 
  
		public nome: any= null; 
        public descricao: any= null;
         
		public urlRetorno: any= '/v2/administracao/conceito/show/' + this.$route.params.id;

		public errors: any = { };
		
		public valueAtualizado(dado: any){
			this.descricao = dado;
		}

        created() { 
            window.alertProcessando();
            tagService.getTag(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o Tag', error.data);
                    window.alertProcessandoHide();
                    
                }); 
			this.colors = manzaTinyEditorColorsMap; 
        }


        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.descricao = this.model.descricao;   
        }
         
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome, 
				descricao :this.descricao,  
			};  
			 
			 
			tagService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				window.toastErro('Não foi possivel desativar ' , error.data.message);
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){  
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }



 

