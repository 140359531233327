





























/* eslint-disable @typescript-eslint/camelcase */  
import {  respostaComentarioService } from "@/servicegerente"; 
import $ from "jquery"   
import { Prop , Component, Vue } from 'vue-property-decorator';
  
@Component({
    components: {},
})
export default class FormEditarRespostaComentario extends Vue {  
    @Prop( ) readonly resposta!: any 
    public texto: any  = null;  
    public add_tag_id: any  = null;  
    created() {
        this.add_tag_id = 'modal-seguranca-pergunta-editar-respostas-comentario-' +  this.resposta.id;
        this.texto = this.resposta.texto;
    }

    acessou(){
        this.texto = this.resposta.texto; 
    }

    salvar() {
        if(this.texto){
            const data: any = {}; 
            data["texto"] = this.texto;  
            window.alertProcessando();
            respostaComentarioService.update(this.resposta.id , data)
                .then(response => {
                    window.toastSucesso(response); 
                    this.$emit('respostaAlteradaComentario', response); 
                    ( $('#' + this.add_tag_id) as any ) .modal('toggle');
                    window.alertProcessandoHide(); 
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                }); 
        } 
    } 
} 

