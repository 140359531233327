



































































 
	
	/* eslint-disable @typescript-eslint/camelcase */ 
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { userService  }  from '@/servicegerente'; 

	@Component({
		components: { 
        },
	 })
    export default class UsuarioEdit extends Vue {
		
		private model: any = null;

		private nome: any = null;
		private apelido: any= null;
		private id: any= null;
		private data_fim_pro: any= null;
	 

		private email: any= null;

		private status: any= null;
		private image: any= null;
		private obs: any= null;
		private telefone: any= null;
		private aniversario: any= null; 

	 

		private apiUrl: any= null;
		private urlRetorno: any= null;


		private errors: any = { };

		created() { 
			this.apiUrl =  userService.getUrl() + '/' + this.$route.params.id; 
			this.urlRetorno =  '/usuario'; 
 
			window.alertProcessando();
			userService.getUsuario( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o usuario', error.data);
				window.alertProcessandoHide();
			}); 
		}

		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.apelido = this.model.apelido;  
			this.email = this.model.email;  
			this.data_fim_pro = this.model.data_fim_pro;  
			 
			this.id = this.model.id;   
			this.telefone = this.model.telefone;   
			this.aniversario = this.model.aniversario;   
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				apelido :this.apelido,
				email :this.email,
				data_fim_pro :this.data_fim_pro, 
				telefone :this.telefone, 
				aniversario :this.aniversario, 
			};  
			 
			 
			userService.update( this.$route.params.id ,  dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	} 

