






















	
	import { userPlanoAdminService  }  from '@/servicegerente';
    import $ from "jquery"   
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class RespostaIndex extends Vue { 

        private  reloadDatatable = false ; 

        private config = { 
			order: [[ 0, "desc" ]],
			ajax: { 
				url: userPlanoAdminService.getUrl() + '/datatable'
			},
			columns: [
			{ data: 'id', name: 'id'  },
			{ data: 'assinatura_id', name: 'assinatura_id' }, 
			{ data: 'plano_id', name: 'plano_id' }, 
			{ data: 'usuario_id', name: 'usuario_id' }, 
			{ data: 'modulo_id', name: 'modulo_id' }, 
			{ data: 'tipo', name: 'tipo' }, 
			{ data: 'data_fim', name: 'data_fim' }, 
			{ data: 'user_id', name: 'user_id' },  
			],
		};
         
		public tableatualizada( ) {  
             console.log("ok");
		}
		 
	}
	 
