import { VuexModule, Module,  Mutation, Action } from 'vuex-module-decorators'
import { moduloService } from '@/servicegerente';  
 
const KJUR = require('jsrsasign');  

const KEY = 'gerente-variavel-modulo'; 
const KEY_DISCIPLINA = 'gerente-variavel-disciplina'; 
const KEY_ASSUNTO = 'gerente-variavel-assunto'; 
const KEY_CONCEITO = 'gerente-variavel-conceito'; 
const KEY_CONCEITOS = 'gerente-variavel-conceitos'; 
const KEY_EMENTA = 'gerente-variavel-ementa'; 
const KEY_CARD = 'gerente-variavel-card'; 

const modulo = localStorage.getItem(KEY);   
const disciplina = localStorage.getItem(KEY_DISCIPLINA);   
const assunto = localStorage.getItem(KEY_ASSUNTO);   
const conceito = localStorage.getItem(KEY_CONCEITO);   
const conceitos = localStorage.getItem(KEY_CONCEITOS);   
const ementa = localStorage.getItem(KEY_EMENTA);   
const card = localStorage.getItem(KEY_CARD);   
 

@Module({
    namespaced: true, 
})

class User extends VuexModule {
       
    //=========================================================================================
    //================================ MODULO =================================================
    //=========================================================================================

    public modulo: any = modulo ?   JSON.parse( modulo ) : null; 
 
    @Mutation
    public alterarModuloMutation(  modulo: any ) {
        this.modulo = modulo; 
    }
     
    @Mutation 
    public resetModuloMutation( ) {  
        this.modulo = ''; 
    }
 
    @Action
    public alterarModulo(modulo: any) {  
        localStorage.removeItem(KEY);
        localStorage.setItem(KEY, JSON.stringify(modulo));
        this.context.commit('alterarModuloMutation', modulo );
        this.context.dispatch('resetDisciplina'); 
    }
 
    @Action
    public resetModulo( ): void { 
        localStorage.removeItem(KEY);
        this.context.commit('resetModuloMutation' ) ; 
        this.context.dispatch('resetDisciplina'); 
    }

    @Action
    public async buscarModulo(){ 
        moduloService.getModulo( 2 ) 
			.then((response: any) => {
                localStorage.removeItem(KEY);
                localStorage.setItem(KEY, JSON.stringify(response));
                this.context.commit('alterarModuloMutation', response );   
			})
			.catch(error => { 
				console.log(error); 
			}); 
    }
 
    //=========================================================================================
    //================================ disciplina =================================================
    //=========================================================================================
 
    public disciplina: any = disciplina ?   JSON.parse( disciplina ) : null; 
 
    @Mutation
    public alterarDisciplinaMutation(  disciplina: any ) {
        this.disciplina = disciplina;  
    }
     
    @Mutation 
    public resetDisciplinaMutation( ) {  
        this.disciplina = '';  
    }
 
    @Action
    public alterarDisciplina(disciplina: any) {  
        localStorage.removeItem(KEY_DISCIPLINA);
        localStorage.setItem(KEY_DISCIPLINA, JSON.stringify(disciplina));
        this.context.commit('alterarDisciplinaMutation', disciplina );
        this.context.dispatch('resetAssunto');   
    }
    

    @Action
    public resetDisciplina( ): void { 
        localStorage.removeItem(KEY_DISCIPLINA);
        this.context.commit('resetDisciplinaMutation' ) ; 
        this.context.dispatch('resetAssunto'); 
    }
 
    //=========================================================================================
    //================================ assunto =================================================
    //=========================================================================================
 
    public assunto: any = assunto ?   JSON.parse( assunto ) : null; 
 
    @Mutation
    public alterarAssuntoMutation(  assunto: any ) {
        this.assunto = assunto; 
    }
     
    @Mutation 
    public resetAssuntoMutation( ) {  
        this.assunto = ''; 
    }
 
    @Action
    public alterarAssunto(assunto: any) {  
        localStorage.removeItem(KEY_ASSUNTO);
        localStorage.setItem(KEY_ASSUNTO, JSON.stringify(assunto));
        this.context.commit('alterarAssuntoMutation', assunto );
        this.context.dispatch('resetEmenta'); 
        this.context.dispatch('resetCard'); 
        this.context.dispatch('resetConceito');  
        this.context.dispatch('resetConceitos');  
    }
 
    @Action
    public resetAssunto( ): void { 
        localStorage.removeItem(KEY_ASSUNTO);
        this.context.commit('resetAssuntoMutation' );
        this.context.dispatch('resetEmenta'); 
        this.context.dispatch('resetCard'); 
        this.context.dispatch('resetConceito');  
        this.context.dispatch('resetConceitos');  
    }
 
    //=========================================================================================
    //================================ Ementa =================================================
    //=========================================================================================
 
    public ementa: any = ementa ?   JSON.parse( ementa ) : null; 
 
    @Mutation
    public alterarEmentaMutation(  ementa: any ) {
        this.ementa = ementa; 
    }
     
    @Mutation 
    public resetEmentaMutation( ) {  
        this.ementa = ''; 
    }
 
    @Action
    public alterarEmenta(ementa: any) {  
        localStorage.removeItem(KEY_EMENTA);
        localStorage.setItem(KEY_EMENTA, JSON.stringify(ementa));
        this.context.commit('alterarEmentaMutation', ementa )   
    }
 
    @Action
    public resetEmenta( ): void { 
        localStorage.removeItem(KEY_EMENTA);
        this.context.commit('resetEmentaMutation' ) ;  
    }
 
 
    //=========================================================================================
    //================================ card  =================================================
    //=========================================================================================
 
    public card: any = card ?   JSON.parse( card ) : null; 
 
    @Mutation
    public alterarCardMutation(  card: any ) {
        this.card = card; 
    }
     
    @Mutation 
    public resetCardMutation( ) {  
        this.card = ''; 
    }
 
    @Action
    public alterarCard(card: any) {  
        localStorage.removeItem(KEY_CARD);
        localStorage.setItem(KEY_CARD, JSON.stringify(card));
        this.context.commit('alterarCardMutation', card )   
    }
 
    @Action
    public resetCard( ): void { 
        localStorage.removeItem(KEY_CARD);
        this.context.commit('resetCardMutation' ) ;  
    }
 
    
    //=========================================================================================
    //================================ Conceito =================================================
    //=========================================================================================
 
    public conceito: any = conceito ?   JSON.parse( conceito ) : null; 
    public conceitos: Array<any> = conceitos ?   JSON.parse( conceitos ) : []; 
 
    @Mutation
    public alterarConceitoMutation(  conceito: any ) {
        this.conceito = conceito; 
    }
     
    @Mutation 
    public resetConceitoMutation( ) {  
        this.conceito = ''; 
    }
 
    @Action
    public alterarConceito(conceito: any) {  
        localStorage.removeItem(KEY_CONCEITO);
        localStorage.setItem(KEY_CONCEITO, JSON.stringify(conceito));
        this.context.commit('alterarConceitoMutation', conceito )   
        this.context.commit('addConceitosMutation', conceito )   
    }
 
    @Action
    public resetConceito( ): void { 
        localStorage.removeItem(KEY_CONCEITO);
        this.context.commit('resetConceitoMutation' ) ;  
    }
    
 
    
    @Mutation
    public addConceitosMutation(  conceito: any ) {  
        const existem = this.conceitos.filter((item) => item.id == conceito.id);
        if(existem.length == 0){
            this.conceitos.push(conceito); 
            localStorage.setItem(KEY_CONCEITOS, JSON.stringify(this.conceitos)); 
        } 
    }
  
    @Mutation 
    public resetConceitosMutation( ) {  
        this.conceitos = [];  
    }
  
    @Action
    public resetConceitos( ): void {  
        this.context.commit('resetConceitosMutation' ) ;  
        localStorage.removeItem(KEY_CONCEITOS); 
    }

}
export default User