

















































































































































































































































	/* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
	  
	import { cardService }  from '@/servicegerente';
	import {Watch, Component, Vue } from 'vue-property-decorator';
    import CardShow from './_Show.vue'; 
      
    @Component({
        components: {
			CardShow
		 },
    })
    export default class Principal extends Vue { 

		public urlRetorno: any= '/v2/administracao/card';
        public model: any = null;

		public liberado  = false;
		
        @Watch('$route')
		tabelaAtualizada(newVal: any) {
			this.buscarModel(); 
        }
         
        buscarModel() {
			this.liberado = false;
			window.alertProcessando();
			cardService.getCard( this.$route.params.id , true )
			.then(response => {
				this.liberado = true;
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide(); 
				window.toastErro('Não foi possivel achar Card');
			});   
        }

		created() { 
			this.buscarModel();  
		}

		showPergunta(id: any) {  
			window.open('#/v2/administracao/pergunta/show/' + id);  
		}

		editPergunta(id: any) {  
			window.open('#/v2/administracao/pergunta/edit/' + id);  
		}
 
	}
	 
