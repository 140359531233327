


































    /* eslint-disable @typescript-eslint/camelcase */ 
    import {Prop, Component, Vue } from 'vue-property-decorator'; 
	import { ementaTagService ,  tagService}  from '@/servicegerente'; 
	import $ from "jquery" ;
    import { authHeader } from '@/servicegerente';  

	@Component({
		components: {  },
	 })
    export default class FormPerfilPermissao extends Vue {

        @Prop( ) readonly assunto_id!: any  

		public tags: any = null;

		public sequencia: any = null;
		public tag_id: any = null;
		  
        public add_permissao_id = 'modal-emeta-adicionar-tag'; 
        public errors: any = { };
         
        AdicionarTag() {
            if (this.tag_id && this.sequencia) { 
                window.alertProcessando();
                const dados = {
                    ementa_id :this.$route.params.id,  
                    sequencia :this.sequencia, 
                    tag_id :this.tag_id, 
                };    
                ementaTagService.cadastrar( dados )
                .then(response => {
                    window.toastSucesso("permissao adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('ementaTagAdicionada', response);
                    this.sequencia = parseInt( this.sequencia ) + 10;
                    this.tag_id = null; 
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500); 
            }
        }  
 
        public processa(params: any){
            return {
                term: params.term,  
                assunto: this.assunto_id ,  
            };
        }

        public ajaxOptions = {
            url: tagService.getUrl() + '/buscar/ajax'  ,  
            dataType: 'json',
            type: "GET",
            delay: 1000,
            headers: authHeader(), 
            data: this.processa, 
            processResults: function(data: any ) {
                return {
                    results: $.map(data, function (item) {
                    return {
                        text: item.nome    , 
                        id: item.id
                    }
                }) 
                }	 
            },
            cache: true
        } 
 
    } 
 
