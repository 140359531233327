import Vue from 'vue'
import Vuex from 'vuex'
import Usuario from '@/store/modules/Usuario'
import Ambiente from '@/store/modules/Ambiente'
import Gerente from '@/store/modules/Gerente'

Vue.use(Vuex)
 
const store = new Vuex.Store({

   modules: {  
      usuario:Usuario,
      ambiente:Ambiente,
      gerente:Gerente
   }
})


export default store;

 