import { authHeader } from '..'; 
import { segurancaModel } from './index.model'; 
 


import axios  from 'axios';  

 
const url = segurancaModel.getUrl() + '/perfil' ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}



function getPerfil( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  

function buscarPermissoes( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + '/permissao/adicionar'  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  

function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function adicionarPermissao( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/permissao" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function removerPermissao( id: any , perfil: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/permissao/' + perfil ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


 


function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


export const perfilService = {
	getPerfil,
	getUrl, 
	buscarPermissoes,
	excluir,
	adicionarPermissao,
	removerPermissao,
	update,
	cadastrar,
	
};
 