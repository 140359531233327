





























































	/* eslint-disable @typescript-eslint/camelcase */ 
	 
	import { moduloService, userService } from '@/servicegerente'; 
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator';  
	import moment from 'moment';  
	import $ from "jquery" ;  

 

	@Component({
		components: {  
        },
	})
	export default class FormUsuarioPlano extends Vue {
 
	 
		@Prop() readonly planos!: any;

		public add_plano_id = 'modal-seguranca-usuario-adicionar-plano'; 
		public plano: any =  '1'   ;
		public data_fim: any = new Date()  ;

		public modulo: any =  '2'   ;
		public modulos: any= null; 
		public tipo: any= 1; 


		created() {
			window.alertProcessando(); 
            moduloService.getAll( )
                .then(response => {
                    this.modulos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
			this.data_fim.setDate(new Date().getDate() + 31 ); 
			this.data_fim = moment(this.data_fim).format('YYYY-MM-DD') 
		}
		
		AdicionarPlano() { 
			if( parseInt( this.tipo ) != 1 && parseInt( this.tipo ) != 2 ){
				this.tipo = 1;
			}
			if (this.plano) { 
				window.alertProcessando(); 
				const dados = {
                    plano :this.plano, 
                    modulo :this.modulo, 
                    data_fim :this.data_fim, 
                    tipo :this.tipo, 
                };  
				userService.adicionarPlano(  this.$route.params.id , dados )
				.then(response => {
					window.alertProcessandoHide(); 
					window.toastSucesso("plano adicionado com successo.");  
					this.$emit('planoAdicionado', response ) ; 
					( $('#' + this.add_plano_id) as any ) .modal('toggle');  
				})
				.catch(error => { 
					window.alertProcessandoHide();
					window.toastErro('Não foi possivel adiciona ' , error.data.message); 
				});   
			} 
		}  
	}

