




























































	import { resumoService  , moduloService }  from '@/servicegerente'; 
	import $ from "jquery";
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 
    const usuario = namespace('usuario')


    @Component({
        components: { },
    })
    export default class ResumosIndex extends Vue { 
        
        @usuario.Getter
		public isAdmin!:  (  ) => boolean
        
        @gerente.State
		public modulo!: any

        @gerente.State
		public disciplina!: any


		@gerente.State
		public assunto!: any


        @gerente.Action
        public resetDisciplina!: (   ) => void

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }

        
        @gerente.Action
        public resetAssunto!: (   ) => void

        public limparAssunto( ) {  
            this.resetAssunto();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
 

        public atualizaUrl( ) {  
            if(!this.disciplina){
				this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/resumos/datatable'
			}
			else if(!this.assunto){
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/resumos/datatable'
			}
            else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id +'/assunto/' + this.assunto.id + '/resumos/datatable'
            }
        }

        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                this.atualizaUrl( );
            }
 
        }

        private datatableKey = 0;
        private  reloadDatatable = false ; 

        private config = {
            order: [[ 1, "asc" ]],
			ajax: { 
				url: moduloService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'titulo', name: 'titulo' },  
				{ data: 'disciplina.nome', name: 'disciplina.nome' }, 
				{ data: 'assunto.nome', name: 'assunto.nome' },  
				{ data: 'restrito', name: 'restrito' }, 
				{ data: 'acessos', name: 'acessos' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
        };


        

        public tableatualizada( ) {  
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/administracao/resumo/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                this.$router.push('/v2/administracao/resumo/show/' + id);
                // window.open('#/v2/administracao/resumo/show/' + id); 
            }); 
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        resumoService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            }); 
        }
 
	}
	 

