















































































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import { tagAdminService,  assuntoAdminService } from '@/servicegerente';

    import Editor from '@tinymce/tinymce-vue';


    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoCadastro extends Vue {

        private assuntos: any = null;
        

		private nome: any= null;
		private assunto_id: any= null;
        private descricao: any= null;
        
 
		private urlRetorno: any= '/tag';

		private errors: any = { };
 

        created() { 
			if(this.$route.params.assunto_id){
				this.assunto_id = this.$route.params.assunto_id;
			}
            assuntoAdminService.getAll()
                .then(response => {
                    this.assuntos = response;
                })
                .catch(() => {
                    window.toastErro('Não foi possivel achar as assunto');
                }); 
        }



		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				assunto_id :this.assunto_id, 
				descricao :this.descricao,  
			};  
			 
			 
			tagAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }
    
 
