







































 
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { moduloAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormModuloDisciplinas extends Vue {

        @Prop( ) readonly disciplinas!: any

        private disciplina: any = null;
        private peso: any = null;

        private add_disciplina_id = 'modal-seguranca-modulo-adicionar-disciplina'; 


        AdicionarPermissao() {
            if (this.disciplina && this.peso  ) {

                window.alertProcessando();
                const dados = {
                    disciplina :this.disciplina, 
                    peso :this.peso, 
                };   

                moduloAdminService.adicionarDisciplina( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("disciplina adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('disciplinaAdicionado', response);
                    ( $('#' + this.add_disciplina_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }
 

