import $ from "jquery" 


  window.alertProcessando = function() {
     $('body').addClass('loading');
    // ( window as any).$('body').addClass('loading');
  }
 

window.alertProcessandoHide = function() {
   $('body').removeClass('loading');
}
   

  window.alertErro = function( titulo: any, texto = "", posicao = "center", funcao: any  ) {
    ( window as any).iziToast.show({
        theme: 'dark',
        position: posicao,
        color: '#DD4B39',
        title: titulo,
        titleColor: '#fff',
        titleSize: '14',
        message: texto,
        messageColor: '#fff',
        timeout: 10000,
        icon: 'fa fa-ban',
        iconColor: '#fff',
        closeOnEscape: true,
        onClosed: funcao
    });
  }

 

  window.toastErro = function(titulo: any , texto = "", funcao: any) {
    ( window as any).alertErro(titulo, texto, 'bottomRight', funcao);
  }

   
  

  window.alertNotificacao = function( titulo: any , texto = "", posicao = "center", funcao: any) {
    ( window as any).iziToast.show({
        theme: 'dark',
        position: posicao,
        color: '#ffc107',
        title: titulo,
        titleColor: '#fff',
        titleSize: '32',
        message: texto,
        messageColor: '#fff',
        timeout: false,
        icon: 'fa fa-ban',
        iconColor: '#fff',
        closeOnEscape: true,
        onClosing: funcao
    });
  }

 
  
  window.notificacao = function( titulo: any , texto = "", funcao: any) { 
    (window as any).alertNotificacao(titulo, texto, 'topRight', funcao);
  }
 
  

  window.alertSucesso = function( titulo: any, texto = "", posicao = "center", funcao: any , timeout = 10000 ) {
    ( window as any).iziToast.show({
        theme: 'dark',
        timeout: timeout, 
        position: posicao,
        color: '#1F5688',
        title: titulo,
        titleColor: '#fff',
        titleSize: '14',
        message: texto,
        messageColor: '#fff',
        
        icon: 'fa fa-check',
        iconColor: '#fff',
        closeOnEscape: true,
        onClosed: funcao
    });
  }
 


  window.toastSucesso = function( titulo: any , texto = "", funcao: any ,  timeout = 2000   ) {
    ( window as any ).alertSucesso(titulo, texto, 'topRight', funcao , timeout );
  }
 


  window.alertConfimacao = function( titulo: any , texto: any , funcaoSIM: any ) {
    ( window as any ).iziToast.show({
        theme: 'dark',
        color: '#3C8DBC',
        titleColor: '#fff',
        messageColor: '#fff',
        timeout: false,
        icon: 'fa fa-question-circle-o',
        iconColor: '#fff',
        close: false,
        overlay: true,
        toastOnce: true,
        zindex: 999,
        title: titulo,
        message: texto,
        position: 'center',
        buttons: [
        
        ['<button>Sim</button>', function (instance: any , toast: any ) {
            instance.hide({
                transitionOut: 'fadeOutUp', 
            }, toast);
            funcaoSIM();
        }],
        
        ['<button><b>Não</b></button>', function(instance: any , toast: any ) {
            instance.hide({
                transitionOut: 'fadeOutUp', 
            }, toast ); 
        }, true]
  
        ],
        id: 'iziToastConfirmacao'
    });
  }

 