









































































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import {   controleService  } from '@/servicegerente'; 
    import Editor from '@tinymce/tinymce-vue'; 
    import { Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class MsnTodosusuarios extends Vue {
 
		private assunto: any= null; 
		private skip: any = 0; 
        private mensagem: any= null;
         
		public urlRetorno: any= '/controle'; 
		public errors: any = { };
 
 

		salvar() {
            if(this.assunto && this.mensagem && ( this.skip >= 0 ) ){
                window.alertConfimacao('Confirma o envio para todos os usuarios ','' ,      
                    () => {  
                        this.salvar2(); 
                    }
                ); 
            } 
        } 


        salvar2() {
             
            window.alertProcessando();
            
            const dados = {
                assunto :this.assunto, 
                skip :this.skip, 
                mensagem :this.mensagem,  
            };   
            controleService.enviarMensagemTodosUsuario(   dados )
            .then(response => {
                window.toastSucesso(response);  
                window.alertProcessandoHide();
                this.skip =  0 + parseInt(this.skip )  + 40 ;
                this.salvar2(); 
            })
            .catch(error => {  
                window.alertProcessandoHide();
                this.errors = error.data.error; 
                console.log(error);
            });  
             
            
        } 
        


		testeEnviarMensagem() {
            if(this.assunto && this.mensagem){
                window.alertProcessando();
                const dados = {
                    assunto :this.assunto, 
                    mensagem :this.mensagem,  
                };   
                controleService.testeEnviarMensagem(   dados )
                .then(response => {
                    window.toastSucesso(response);  
                    window.alertProcessandoHide(); 
                })
                .catch(error => {  
                    window.alertProcessandoHide();
                    this.errors = error.data.error; 
                    console.log(error);
                });  
            }
		} 

          
        
        
    }
    
 
