



































	
	import { resumoService }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class ShowResumo extends Vue { 

		public urlRetorno: any= '/v2/administracao/resumo';
        public model: any = null;
        public fundo: any = "escuro-tag";
		
		roxo() { 
			this.fundo = "escuro-roxo";
		}
		
		black() { 
			this.fundo = "escuro-tag";
		}
		
		claro() { 
			this.fundo = "claro-tag";
		}

		created() { 
			window.alertProcessando();
			resumoService.getResumo( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				
				window.toastErro('Não foi possivel achar Resumo');
			}); 
		}


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		 

	}
	 
