





















	/* eslint-disable @typescript-eslint/camelcase */  
  
	import { assuntoAdminService  }  from '@/servicegerente';
    import $ from "jquery"  
 
	import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: { 
        },
    })
    export default class PerfilPermissao extends Vue {
 

        private assunto: any= null; 
        
		private reloadDatatable: any= false;  

		private url_retorno: any= '/assunto';

		private config: any= { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: assuntoAdminService.getUrl() + '/' + this.$route.params.id + '/pergunta/datatable'
			},
			columns: [
				{ data: 'texto_clean', name: 'texto_clean', orderable: false },
				{ data: 'status', name: 'pergunta.status' },  
				{ data: 'tags_count', name: 'tags_count', class: 'text-center', orderable: false, searchable: false, },

				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		}; 
		

		created() { 
			window.alertProcessando();
			assuntoAdminService.getAssunto( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.assunto = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Assunto', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});   
		}


		  


		public tableatualizada( ) {   
			$('[btn-show]').click(  (btn) => {  
				const id =   $(btn.currentTarget).data('id');  
				window.open('#/pergunta/show/' + id);
			});

		}
		

		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }


	}
	 

