


























































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { tagService }  from '@/servicegerente';


	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        public url_retorno: any = '/v2/administracao/conceito' ;
        public model: any = null ;
		public fundo: any = "escuro-tag";
		
		roxo() { 
			this.fundo = "escuro-roxo";
		}
		
		black() { 
			this.fundo = "escuro-tag";
		}
		 
		orange() { 
			this.fundo = "escuro-orange";
		}
		 
		titanium() { 
			this.fundo = "escuro-titanium";
		}
		  
		green() { 
			this.fundo = "escuro-green";
		}
		 
		cian() { 
			this.fundo = "escuro-cian";
		}
		 
		grey() { 
			this.fundo = "escuro-grey";
		}
		 
		marrom() { 
			this.fundo = "escuro-marrom";
		}
		
		claro() { 
			this.fundo = "claro-tag";
		}

        public config = { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: tagService.getUrl() + '/' + this.$route.params.id + '/assuntos/datatable'
			},
			columns: [
			{ data: 'nome', name: 'assunto.nome' },
			{ data: 'descricao', name: 'assunto.descricao' }, 
			],
		};


		created() { 
			window.alertProcessando();
			tagService.getTag( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				 
				window.toastErro('Não foi possivel achar Tag');
			}); 
		}


	}
 

