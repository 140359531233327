


































































































/* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
  	/* eslint-disable @typescript-eslint/camelcase */ 
      
	import { cardService , moduloService }  from '@/servicegerente';  
	import $ from "jquery"   
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 
    const usuario = namespace('usuario')
    
    @Component({
        components: { },
    })
    export default class AudiosIndex extends Vue { 
        

        @usuario.Getter
		public isAdmin!:  (  ) => boolean
 
        @gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public assunto!: any

        @gerente.State
		public card!: any
 
        @gerente.Action
        public alterarDisciplina!: ( disciplina: any ) => void

        @gerente.Action
        public resetDisciplina!: (   ) => void
 

        @gerente.Action
        public alterarCard!: ( card: any  ) => void


        @gerente.Action
        public resetCard!: (   ) => void

        public perguntaKey = 0;
        public  ischeckingtag = false ; 
        public  ischeckingpergunta = false ; 

        @Watch('ischeckingtag')
		public checkingtag( ) {    
            if(this.ischeckingtag){
                this.config.ajax.url =  cardService.getUrl() + '/modulo/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/assunto/' + this.assunto.id +  '/checkingtag/datatable'  
            }
            else{
                this.atualizaUrl( ); 
            }
            this.perguntaKey += 1;
		}

        @Watch('ischeckingpergunta')
		public checkingpergunta( ) {    
            if(this.ischeckingpergunta){
                this.config.ajax.url =  cardService.getUrl() + '/modulo/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/assunto/' + this.assunto.id +  '/checkingpergunta/datatable'  
            }
            else{
                this.atualizaUrl( ); 
            }
            this.perguntaKey += 1;
		}

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }

        
        @gerente.Action
        public resetAssunto!: (   ) => void

        public limparAssunto( ) {  
            this.resetAssunto();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
 

        public desativarInstrucao( ) {  
            window.alertProcessando();
            moduloService.removerInstrucao(  this.modulo.id , this.disciplina.id )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('desativação realizada com sucesso!!' ); 
                const dados = JSON.parse(JSON.stringify(this.disciplina));
                dados.has_instrucao = 0 ; 
                this.alterarDisciplina(dados);
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel desativar ' , error.data.message); 
            });   
        }
 

        public ativarInstrucao( ) {  
            window.alertProcessando();
            moduloService.adicionarInstrucao(  this.modulo.id , this.disciplina.id )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('ativação realizada com sucesso!!' );  
                const dados = JSON.parse(JSON.stringify(this.disciplina));
                dados.has_instrucao = 1 ; 
                this.alterarDisciplina(dados);
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel ativar ' , error.data.message); 
            });   
        }
 

        public atualizaUrl( ) {  
            if(!this.disciplina){
				this.config.ajax.url =  cardService.getUrl() + '/modulo/' + this.modulo.id + '/datatable'
			}
			else if(!this.assunto){
                this.config.ajax.url =  cardService.getUrl() + '/modulo/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/datatable'
			} 
            else{
                this.config.ajax.url =  cardService.getUrl() + '/modulo/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/assunto/' + this.assunto.id +  '/datatable' 
            }
        }



        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                 this.atualizaUrl( );
            }  
        }

        public datatableKey = 0;

        public  reloadDatatable = false ; 

        public config = {
            order: [[ 1, "asc" ]],
			ajax: { 
				url: moduloService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'titulo', name: 'titulo' },  
				{ data: 'tipo', name: 'tipo' }, 
				{ data: 'disciplina_id', name: 'disciplina_id' }, 
				{ data: 'assunto_id', name: 'assunto_id' },  
				{ data: 'nivel', name: 'nivel' }, 
				{ data: 'proximo_secundario_id', name: 'proximo_secundario_id' }, 
				{ data: 'proximo_id', name: 'proximo_id' }, 
				{ data: 'anterior_id', name: 'anterior_id' }, 
				{ data: 'fonte_id', name: 'fonte_id' }, 
				{ data: 'videoaula', name: 'videoaula' }, 
				{ data: 'tag_id', name: 'tag_id' }, 
				{ data: 'pergunta_id', name: 'pergunta_id' }, 
				{ data: 'audio', name: 'audio' },  
				{ data: 'id', name: 'id', orderable: false, searchable: false, class: 'text-center'},
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
            columnDefs: [ 
                {
                    targets: 14,
                    render: ( row: any, type: any, val: any, meta: any ) => {  
                        let texto = '' ; 
                        let buttom = '' ;  
                        for (const key in val) { 
                            if(key != 'action' && key != 'descricao'){
                                buttom = buttom + ' data-' + key + '="'+ val[key] +'"'  
                            } 
                        }  
                        buttom = buttom + ' data-sigla="'+ val["titulo"] +'"';  
                        texto =  texto + '<button title="Add Ementa" class="btn btn-success btn-sm" btn-select '+ buttom +' "><i class="fa fa-check"></i> </button>' ;   
                        return texto;  
                    }, 
                } 
            ], 
        };


        public tableatualizada( ) {  
            
            $('[btn-select]').click(  (btn) => {   
                const dados = $(btn.currentTarget).data( ); 
                this.alterarCard(dados);
                window.toastSucesso('card selecionado com sucesso!!' );  
            });
             
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/card/edit/' + id ); 
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.open('#/v2/administracao/card/show/' + id); 
            });
     
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        cardService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
     
        }
 
	}
	 

