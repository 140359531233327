





































	
	import { moduloService  }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 
      
    @Component({
        components: { },
    })
    export default class TentativaIndex extends Vue { 


		@gerente.State
		public modulo!: any

 		@gerente.State
		public disciplina!: any

		
		@gerente.Action
        public resetDisciplina!: (   ) => void

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }


		 public atualizaUrl( ) {  

			 if(!this.disciplina){
				this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/tentativas/datatable'
			}
			else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/tentativas/datatable'
			}  
        }


		
        private config = { 
			order: [[ 5, "desc" ]],
			ajax: { 
				url:  moduloService.getUrl() + '/datatable'
			},
			columns: [ 
			{ data: 'usuario.nome', name: 'usuario.nome' }, 
			{ data: 'disciplina.nome', name: 'disciplina.nome' }, 
			{ data: 'pergunta_id', name: 'pergunta_id' }, 
			{ data: 'resposta_id', name: 'resposta_id' }, 
			{ data: 'acerto', name: 'acerto' }, 
			{ data: 'created_at', name: 'created_at' },  
			{ data: 'tipo', name: 'tipo' }, 

			],
		} ;



		created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else {
                 this.atualizaUrl( );
            } 
        }

        private datatableKey = 0;



 
	}
	  
