/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '@/servicegerente';  
import { gerenteModel } from './index.model';  
import * as CryptoJS from 'crypto-js'; 
import moment  from 'moment'; 
import axios  from 'axios'; 

const secret_key =  gerenteModel.getKeyCripto() ;
 
const url = gerenteModel.getUrl() + '/modulo' ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}

function buscarTodasDisciplina( id: any ,   api = false ) {  
	return  new Promise((resolve, reject) => {  
		const disciplinas = localStorage.getItem('global_model_disciplinas_todos_modulo_' + id );  
		if(disciplinas && !api){  
			const data_criacao = localStorage.getItem('data_global_model_disciplinas_todos_modulo_' + id );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(disciplinas, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		} 
		axios.get( url + '/' + id + "/disciplinas" , {headers: authHeader() }  )
		.then(response => {  
			const encripted_disciplinas = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_disciplinas_todos_modulo_' + id , encripted_disciplinas  );    
			localStorage.setItem('data_global_model_disciplinas_todos_modulo_' + id , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
}
  




function InciarSimulado( id: any  ) { 
	return  new Promise((resolve, reject) => {
		axios.post(  url + '/' + id + "/criar/simulado"  , { }  , {headers: authHeader() })
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}



function finalizarSimulado( id: any  ) { 
	return  new Promise((resolve, reject) => {
		axios.post(  url + '/' + id + "/finalizar/simulado"  , { } , {headers: authHeader() } )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}


















function salvaImagem(   dados: any ) { 
	
	const head: any = authHeader(); 
	head["Content-Type"] = "multipart/form-data"; 
	
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/cadastrar/imagem'  , dados , { headers: head }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

















function getAll( api = false  ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('global_model_modulos_todos' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('data_global_model_modulos_todos'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/all'  , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_modulos_todos' , encripted_conceitos  );    
			localStorage.setItem('data_global_model_modulos_todos'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}
 





function getConceitos(  id: any, disciplina_id: any ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id );  
		if(conceitos){  
			const data_criacao = localStorage.getItem('data_global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id);   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		}  
		axios.get( url  + '/' + id + '/disciplina/' + disciplina_id + '/conceitos/principais'  , {headers: authHeader() }  )
		.then(response => {     
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id , encripted_conceitos  );    
			localStorage.setItem('data_global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
}
  


function getModulo( id: any  , api = false ) {  
	return  new Promise((resolve, reject) => {  
		const modulo = localStorage.getItem('global_model_modulo_id_' + id );  
		if(modulo && !api){  
			const data_criacao = localStorage.getItem('data_global_model_modulo_id_' + id );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(modulo, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		}    
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			const encripted_modulo = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_modulo_id_' + id , encripted_modulo  );    
			localStorage.setItem('data_global_model_modulo_id_' + id , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});   

 
}



 



  




function buscarDisciplina( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/disciplina/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

 
function buscarDesafios( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/desafio/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function buscarTodosAssunto( id: any  ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/assuntos/all" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function buscarAssunto( id: any , disciplinaId: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/disciplina/" + disciplinaId + "/assunto/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function removerDisciplina( id: any , perfil: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/disciplina/' + perfil ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function removerAssunto( id: any , disciplina: any , assunto: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/disciplina/' + disciplina + '/delete/assunto/'  + assunto ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function adicionarDisciplina( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/disciplina" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function adicionarAssunto( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/assunto" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function removerInstrucao( id: any , disciplina: any  ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/disciplina/' + disciplina + '/instrucao',  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


 
function adicionarInstrucao( id: any , disciplina: any) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/' + id + "/disciplina/" + disciplina + "/instrucao" , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function cadastrarBateria( id: any , bateria_id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post(  url + '/' + id + "/bateria/" + bateria_id + "/cadastrar", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function cadastrarBateriaImport( id: any , bateria_id: any , dados: any  ) { 
	return  new Promise((resolve, reject) => {  
		axios.post(  url + '/' + id + "/bateria/" + bateria_id + "/cadastrar/import", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function buscarPerguntaIds( id: any , bateria_id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post(  url + '/' + id + "/bateria/" + bateria_id + "/buscar", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


export const moduloService = {
	getAll , 
	getUrl,
	getModulo,
	buscarAssunto,
	buscarDisciplina,
	buscarDesafios,
	buscarTodasDisciplina,
	getConceitos,
	update,
	cadastrar,
	ativar,
	desativar,
	excluir,
	removerDisciplina,
	adicionarDisciplina,
	adicionarAssunto,
	removerAssunto,
	salvaImagem,
	buscarTodosAssunto,
	finalizarSimulado,
	InciarSimulado,
	buscarPerguntaIds,
	cadastrarBateria,
	removerInstrucao,
	adicionarInstrucao,
	cadastrarBateriaImport,
};