












































































































 
 
    import {   pontuacaoVpsService  } from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    
  
    @Component({
        name: "ControladorIndex",
        components: { },   
    }) 
    export default class ControladorIndex extends Vue {
         
        public tipo: any = 1
        public cursoId: any = 2
        public dados: any = null;
        public skip: any = 0;
        public take: any = 10; 
            
        created(){   
            this.buscar() ;
        }   
         
		buscar() {
            this.tipo = 1 
            window.alertProcessando();
			pontuacaoVpsService.getLogPontuacao( this.$route.params.id ,  this.cursoId,  this.take, this.skip )
			.then(response => {
				this.dados =  response; 
				window.alertProcessandoHide(); 
			})
			.catch(error => {  
				window.alertProcessandoHide(); 
				console.log(error);
			});  
		} 
         
		buscarGame() { 
            this.tipo = 2 
            window.alertProcessando();
			pontuacaoVpsService.getLogGame( this.$route.params.id ,  this.cursoId,  this.take, this.skip )
			.then(response => {
				this.dados =  response; 
				window.alertProcessandoHide(); 
			})
			.catch(error => {  
				window.alertProcessandoHide(); 
				console.log(error);
			});  
		} 

   
    } 
