import { render, staticRenderFns } from "./_ConceitoAjaxAdicionar.vue?vue&type=template&id=4266e671&scoped=true"
import script from "./_ConceitoAjaxAdicionar.vue?vue&type=script&lang=ts"
export * from "./_ConceitoAjaxAdicionar.vue?vue&type=script&lang=ts"
import style0 from "./_ConceitoAjaxAdicionar.vue?vue&type=style&index=0&id=4266e671&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4266e671",
  null
  
)

export default component.exports