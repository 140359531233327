
































































  
	/* eslint-disable @typescript-eslint/camelcase */  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { pontuacaoVpsService  }  from '@/servicegerente'; 

	@Component({
		components: { 
        },
	 })
    export default class UsuarioEdit extends Vue {
		
		public model: any = null;

		public pontos: any = null;
		public nivel: any= null; 
		public curso_id: any= null; 
		public erros: any= null; 
		public acertos: any= null; 
    
		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.pontos = this.model.pontos;
			this.nivel = this.model.nivel;  
			this.erros = this.model.erros;  
			this.acertos = this.model.acertos;    
		}


		buscar( ) {
            if(this.curso_id){
                window.alertProcessando();
                pontuacaoVpsService.getPontuacao( this.$route.params.id , this.curso_id ) 
                .then(response => {
                    this.model = response ;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o usuario', error.data);
                    window.alertProcessandoHide();
                });   
            } 
		}
 
		salvar() {
			window.alertProcessando();
			const dados = {
				pontos :this.pontos,
				nivel :this.nivel,
				erros :this.erros,
				usuario_id : this.$route.params.id , 
				curso_id :this.model.curso_id, 
				acertos :this.acertos, 
			};   
			pontuacaoVpsService.update(  dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide(); 
			})
			.catch(error => {  
				window.alertProcessandoHide(); 
				console.log(error);
			});  
		} 
 
	} 

