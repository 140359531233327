























import $ from "jquery"  

import { Component, Vue } from 'vue-property-decorator';

import { userService } from '@/servicegerente'; 


@Component({
    components: {
         
    },
})
export default class UsuarioIndex extends Vue {
    
    private config = { 
        responsive: {
            details: false
        },
        order: [[3, "desc"]],
        ajax: {
            url: userService.getUrl() + "/datatable",
        },
        columns: [
            { data: "id",  name: "id", },
            { data: "nome",  name: "nome"  },
            { data: "email",  name: "email" , class:'desktop' },
            {  data: "created_at", name: "created_at" , class:'desktop', },
            {  data: "data_fim_pro", name: "data_fim_pro" , class:'desktop', },
            { data: "identificador",  name: "identificador" , class:'desktop', },
            { data: "status",  name: "status" , class:'desktop', },
            { data: "action", name: "action",  orderable: false, searchable: false, class: "text-center", },
        ],
    };

    private  reloadDatatable = false ; 

        
    public tableatualizada( ) {  
        
        
        $('[btn-editar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/usuario/edit/' + id);
        });

        $('[btn-visualizar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            // this.$router.push('/usuario/show/' + id);
            window.open('#/usuario/show/' + id) ; 
        });

        $('[btn-visualizar-plano]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            this.$router.push('/usuario/' + id + '/plano');
        });

        $('[btn-visualizar-modulo]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            this.$router.push('/usuario/' + id + '/modulo');
        });

        $('[btn-visualizar-perfil]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            this.$router.push('/usuario/' + id + '/perfil');
        });

        $('[btn-relatorio-semanal]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma o Reset de Senha ','' , 
                 () => {  
                    window.alertProcessando(); 
                    userService.buscarRelatorioSemanal(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        window.toastSucesso('Reset realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Resetar ' , error.data.message); 
                    });  
                }
            ); 
        });


        $('[btn-resetar-senha]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma o Reset de Senha ','' , 
                 () => {  
                    window.alertProcessando(); 
                    userService.resetarSenha(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        window.toastSucesso('Reset realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Resetar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-desativar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Desativação ','' , 
                 () => {  
                    window.alertProcessando(); 
                    userService.desativar(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('desativação realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel desativar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-ativar]').click(  (btn) => {   
            const id =   $(btn.currentTarget).data('id'); 
            window.alertConfimacao('Confirma a Ativação ','' , 
                 () => {  
                    window.alertProcessando(); 
                    userService.ativar(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('ativação realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel ativar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-excluir]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Exclusão ','' , 
                 () => {  
                    window.alertProcessando(); 
                    userService.excluir(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                    });  
                }
            ); 
        });

    }


}



