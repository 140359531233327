





















import $ from "jquery"  

import { Component, Vue } from 'vue-property-decorator';

 
import { usuarioService  }  from '@/servicegerente';  

@Component({
    components: {
         
    },
})
export default class UsuarioIndex extends Vue {
    
    public config = { 
        responsive: {
            details: false
        },
        order: [[3, "desc"]],
        ajax: {
            url: usuarioService.getUrl() + "/datatable",
        },
        columns: [
            { data: "id",  name: "id", },
            { data: "nome",  name: "nome"  },
            { data: "email",  name: "email" , class:'desktop' },
            {  data: "created_at", name: "created_at" , class:'desktop', },
            {  data: "data_fim_pro", name: "data_fim_pro" , class:'desktop', }, 
            { data: "action", name: "action",  orderable: false, searchable: false, class: "text-center", },
        ],
    };

    public  reloadDatatable = false ; 

        
    public tableatualizada( ) {  
          
        $('[btn-visualizar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/v2/administracao/usuario/show/' + id);
        });

        // $('[btn-visualizar-plano]').click(  (btn) =>{  
        //     const id =   $(btn.currentTarget).data('id');  
        //     this.$router.push('/usuario/' + id + '/plano');
        // });

    }


}



