


























































 
    /* eslint-disable @typescript-eslint/camelcase */ 
	 
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { audioAulaService , disciplinaService , moduloService }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormPerfilPermissao extends Vue { 
        
        @Prop( ) readonly modulo!: any 

        private assuntos: any = null; 

        private assunto: any = null; 

        private disciplinas: any = null; 
		private disciplina: any = null;


        private modulos: any = null; 
		private modulo_id: any = null;

        private add_assunto_id = 'modal-seguranca-audio-adicionar-assunto'; 

        
        
        @Watch('disciplina')
		alterouDisciplina(newVal: any) { 
            this.assunto = null ; 
            this.assuntos = []; 
			if(this.disciplina){
				this.buscarAssunto(this.disciplina); 
			} 
		}
        

        @Watch('modulo_id')
		alterouModulo(newVal: any) { 
            
            this.assuntos = [] ; 
			this.assunto = null ; 
			this.disciplinas = [] ; 
			this.disciplina = null ; 
            
            if(this.modulo_id){
				moduloService.buscarTodasDisciplina( this.modulo_id ) 
                .then(response => {
                    this.disciplinas = response ;
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar as disciplinas');
                });   
			}
			
		}


        

        created() {    
            this.modulo_id = this.modulo.id;
            this.buscarModulos( ); 
		}



        buscarAssunto(id: any) { 
            window.alertProcessando();
            audioAulaService.buscarAssuntoDisciplina(this.$route.params.id , id , this.modulo_id)
                .then(response => {
                    this.assuntos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os assuntos para adiocionar", error.data);
                    window.alertProcessandoHide();
                });
        }


         
        buscarModulos( ) {  
            moduloService.getAll( )
                .then(response => {
                    this.modulos = response; 
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os cursos", error.data); 
                });
        }


         



        AdicionarAssunto() {
            if (this.assunto) { 
                window.alertProcessando();
                const dados = {
                    assunto : this.assunto  
                };    
                audioAulaService.adicionarAssunto( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("assunto adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response);  
                    ( $('#' + this.add_assunto_id) as any ) .modal('toggle');  
                    this.disciplina = null ;
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }

 
