var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model)?_c('div',[_c('br'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('custom-card',{attrs:{"title":"Alterar Conceito","voltar":_vm.urlRetorno},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"card-footer text-right"},[_vm._t("footer"),_c('button',{staticClass:" btn btn-success ",on:{"click":_vm.salvar}},[_vm._v(" Salvar "),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp')}}),_c('i',{staticClass:" fa fa-check "})])],2)]},proxy:true}],null,true)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nome),expression:"nome"}],staticClass:"form-control",class:{ 'is-invalid': _vm.errors.nome },attrs:{"required":"","type":"text","id":"nome","name":"nome"},domProps:{"value":(_vm.nome)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nome=$event.target.value}}}),(_vm.errors.nome)?_c('div',{staticClass:"text-red"},[_vm._v(_vm._s(_vm.errors.nome[0]))]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('crudFormElemento',[_c('label',{attrs:{"for":"descricao"}},[_vm._v("Conteúdo:")]),_c('editor',{attrs:{"api-key":"wfmp2fmb67ocbs0x86o0v1whb3stwck3lhdpx52xd9988ebe","init":{
                                                height: 450,
                                                menubar: true,
                                                relative_urls: false,
                                                remove_script_host: false,
                                                fontsize_formats: '7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 22pt 24pt 36pt 48pt',
                                                plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                                ], 
												formats: {
													underline: [
														{ inline: 'span', styles: { 'text-decoration': 'underline' }, exact: true },
														{ inline: 'u', exact : true } ],
													italic: { inline: 'i', exact : true }
												},
                                                toolbar:
                                                'undo redo | formatselect fontselect fontsizeselect | bold italic underline strikethrough backcolor forecolor  | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | charmap fullscreen  preview  removeformat | help'
                                            }},model:{value:(_vm.descricao),callback:function ($$v) {_vm.descricao=$$v},expression:"descricao"}})],1)],1)])])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }