




































































































































































	import { controleService  }  from '@/servicegerente'; 
    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 
        

		private quantidade: any = 0; 

        
        public atualizacaoDiaria( ) {  
            window.alertConfimacao('Confirma a Atualização Diária ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.atualizacaoDiaria( )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('Atualização Diária realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel realizar  Atualização Diária' , error.data); 
                    });  
                }
            ); 
        }
             

        public atualizacaoDisciplinaModulo( ) {  
            window.alertConfimacao('Confirma a Atualização  Disciplina Modulo ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.atualizacaoDisciplinaModulo( )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('Atualização Diária realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel realizar  Atualização Diária' , error.data); 
                    });  
                }
            ); 
        }
             

        public atualizacaoRespostas( ) {  
            window.alertConfimacao('Confirma a Atualização  Respostas ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.atualizacaoRespostas( )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('Atualização Diária realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel realizar  Atualização Diária' , error.data); 
                    });  
                }
            ); 
        }


        public atualizacaoUsuarioGeral( ) {  
            window.alertConfimacao('Confirma a Atualização  Usuarios ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.atualizacaoUsuarioGeral( )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('Atualização Diária realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel realizar  Atualização Diária' , error.data); 
                    });  
                }
            ); 
        }


        
        public limparTabelaPerguntaAssunto( ) {  
            window.alertConfimacao('Confirma limparTabelaPerguntaAssunto ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.limparTabelaPerguntaAssunto( )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('limparTabelaPerguntaAssunto realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel realizar  limparTabelaPerguntaAssunto' , error.data); 
                    });  
                }
            ); 
        }
 
        
        public limparTabelaTagPergunta( ) {  
            window.alertConfimacao('Confirma limparTabelaTagPergunta ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.limparTabelaTagPergunta( )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('limparTabelaTagPergunta realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel realizar  limparTabelaTagPergunta' , error.data); 
                    });  
                }
            ); 
        }
 
             

        public processarImagemDasPerguntas( ) {  
            window.alertConfimacao('Confirma a Atualização  das imagens das perguntas ','' , 
                () => {  
                     this.processarImagemDasPerguntasRequest( );
                    // window.alertProcessando(); 
                    // controleService.processarImagemDasPerguntas( )
                    // .then(response => {
                    //     window.alertProcessandoHide();   
                    //     window.toastSucesso('Atualização das imagens das perguntas  realizada com sucesso!!' , response  ); 
                    // })
                    // .catch(error => { 
                    //     window.alertProcessandoHide();
                    //     window.toastErro('Não foi possivel realizar  Atualização  das imagens das perguntas ' , error.data); 
                    // });  
                }
            ); 
        }



        
        public processarImagemDasPerguntasRequest( ) {  
            window.alertProcessando(); 
            controleService.processarImagemDasPerguntas( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização das imagens das perguntas  realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1;

                if(  this.quantidade < 5 ){
                    this.processarImagemDasPerguntasRequest( );
                }
                else{
                    this.quantidade = 0
                }
                
            })
            .catch(error => { 
                window.alertProcessandoHide();
                this.quantidade = 0
                window.toastErro('Não foi possivel realizar  Atualização  das imagens das perguntas ' , error.data); 
            });  
        }



        

        
        public processarlimparPerguntasDuplicada( ) {  
            window.alertConfimacao('Confirma limpar Perguntas Duplicada ','' , 
                () => {  
                     this.limparPerguntasDuplicada( ); 
                }
            ); 
        }
        
        public limparPerguntasDuplicada( ) {  
            window.alertProcessando(); 
            controleService.limparPerguntasDuplicada( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização das imagens das perguntas  realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 3 ){
                    this.limparPerguntasDuplicada( );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                this.quantidade = 0
                window.toastErro('Não foi possivel realizar  Atualização  das imagens das perguntas ' , error.data); 
            });  
        }



        
        public processarlimparPerguntasGrande( ) {  
            window.alertConfimacao('Confirma limparPerguntasGrande ','' , 
                () => {  
                     this.limparPerguntasGrande( ); 
                }
            ); 
        }
        
        public processarlimparPerguntasSuspensa( ) {  
            window.alertConfimacao('Confirma limparPerguntasSuspensa ','' , 
                () => {  
                     this.limparPerguntasSuspensa( ); 
                }
            ); 
        }
        
        public processarlimparPerguntasFinalizada( ) {  
            window.alertConfimacao('Confirma limparPerguntasFinalizada ','' , 
                () => {  
                     this.limparPerguntasFinalizada( ); 
                }
            ); 
        }
        
        public processarprocessarPerguntaDuplicada( ) {  
            window.alertConfimacao('Confirma processarPerguntaDuplicada ','' , 
                () => {  
                     this.processarPerguntaDuplicada( ); 
                }
            ); 
        }
        
        public adicionarPerguntaFirestoreJob( ) {  
            window.alertConfimacao('Confirma adicionarPerguntaFirestoreJob ','' , 
                () => {  
                     this.addPerguntaFirestoreJob( ); 
                }
            ); 
        }

        
        public addPerguntaFirestoreJob( ) {  
            window.alertProcessando(); 
            controleService.adicionarPerguntaFirestoreJob( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização  Respostas TextoPuro com sucesso!!' , response  );   
            })
            .catch(error => { 
                window.alertProcessandoHide(); 
                window.toastErro('Não foi possivel ajustar Respostas TextoPuro ' , error.data); 
            });  
        }
        
        public migrarPerguntaPrincipalToSecundario( ) {  
            window.alertProcessando(); 
            controleService.migrarPerguntaPrincipalToSecundario( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização  Respostas TextoPuro com sucesso!!' , response  );   
            })
            .catch(error => { 
                window.alertProcessandoHide(); 
                window.toastErro('Não foi possivel ajustar Respostas TextoPuro ' , error.data); 
            });  
        }

        
        public processarPerguntaDuplicada( ) {  
            window.alertProcessando(); 
            controleService.processarPerguntaDuplicada( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização  Respostas TextoPuro com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 120 ){
                    this.processarPerguntaDuplicada( );
                    console.log( this.quantidade );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                this.quantidade = 0
                window.toastErro('Não foi possivel ajustar Respostas TextoPuro ' , error.data); 
            });  
        }
        
        public limparPerguntasGrande( ) {  
            window.alertProcessando(); 
            controleService.limparPerguntasGrande( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização das imagens das perguntas  realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 3 ){
                    this.limparPerguntasGrande( );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                this.quantidade = 0
                window.toastErro('Não foi possivel realizar  Atualização  das imagens das perguntas ' , error.data); 
            });  
        }
        
        public limparPerguntasFinalizada( ) {  
            window.alertProcessando(); 
            controleService.limparPerguntasFinalizada( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização limpar Perguntas Finalizada realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 3 ){
                    this.limparPerguntasFinalizada( );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                this.quantidade = 0
                window.toastErro('Não foi possivel realizar  limparPerguntasFinalizada' , error.data); 
            });  
        }
        
        public limparPerguntasSuspensa( ) {  
            window.alertProcessando(); 
            controleService.limparPerguntasSuspensa( )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('Atualização limpar Perguntas Suspensa realizada com sucesso!!' , response  ); 
                this.quantidade = this.quantidade + 1; 
                if(  this.quantidade < 3 ){
                    this.limparPerguntasSuspensa( );
                }
                else{
                    this.quantidade = 0
                } 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                this.quantidade = 0
                window.toastErro('Não foi possivel realizar  limparPerguntasSuspensa' , error.data); 
            });  
        }

             
 
 
	}
	 

