










































	/* eslint-disable @typescript-eslint/camelcase */ 


	import {   profileService  }  from '@/servicegerente';
	import moment  from 'moment';
    import { namespace } from 'vuex-class'
    const usuario = namespace('usuario')


	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class ProfileIndex extends Vue { 
         

		@usuario.State
	  	public user!: string
	  
        private planos: any = '';

		created() { 
			this.getPlanos();  
		}

		getPlanos() { 
			window.alertProcessando();   
			profileService.getMeusPlanos(  )	 
			.then(response => {
				this.planos = response ;  
				window.alertProcessandoHide();
			}) 
			.catch(error => { 
				window.alertProcessandoHide();
				 
			});	  
		}
			

		data_br(value: any): string {
			return moment(value).format("DD/MM/YYYY");
		}

	}


/*

	export default {
 
		filters: {
            data_br: function (value) { 
                return  moment(value).format("DD/MM/YYYY") ;
            }
        },
 
	}
*/
