






























	import FormUsuarioPlano from './_PlanoFormAdicionar.vue'  
	import Curso from './_Curso.vue'  
	import FormBonificarPRO from './_formBonificarPRO.vue'  
	 
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
    import $ from "jquery" 

	import { userService  }  from '@/servicegerente';

	@Component({
		components: { 
			'plano-form-adicionar': FormUsuarioPlano,
			'plano-form-bonificar': FormBonificarPRO,
			'curso': Curso,
        },
	 })
    export default class UsuarioPlano extends Vue {

        @Prop() readonly usuario!: any;
         
		public planos: any= null; 
		public reloadDatatable: any= false; 
 
				 
		public url_retorno: any= '/usuario'; 
		public url_historico: any= '/usuario/' + this.usuario.id + '/plano/historico'; 
		 
		
		public config: any= { 
			order: [[ 4, "desc" ]],
			ajax: { 
				url: userService.getUrl() + '/' + this.usuario.id + '/plano/datatable'
			},
			columns: [
				{ data: 'plano_id', name: 'usuario_plano.plano_id'  },
				{ data: 'nome', name: 'planos.nome' },
				{ data: 'valor_mensal', name: 'planos.valor_mensal' }, 
				{ data: 'id_responsavel', name: 'id_responsavel' }, 
				{ data: 'data_fim', name: 'data_fim' }, 
				{ data: 'modulo_id', name: 'modulo_id' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  
        
        created() { 
			this.buscarPlanos(); 
		}


		 
		@Watch('usuario')
		usuarioAlterado(newVal: any) {
			this.buscarPlanos();
		}
 
		 
		planoAdicionado(event: any) {
			this.planos = event; 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		planoAlterado(event: any) { 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		
        
		salvarMensagem2() {
			window.alertProcessando(); 
            userService.adicionarBonificaçãoCadastralAoUsuario( this.usuario.id )
            .then(response => {
				this.reloadDatatable = !this.reloadDatatable; 
                window.alertProcessandoHide(); 
                window.toastSucesso("usuario bonificado.");  
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
                window.toastErro('Não foi possivel adiciona ' , error.data.message); 
            });   
		} 
 


		buscarPlanos() {
			window.alertProcessando();
			userService.buscarPlanos( this.usuario.id )   
			.then(response => {
				this.planos = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os planos para adiocionar", "");
				window.alertProcessandoHide();
			});   
		}
 
		public tableatualizada( ) {  
			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        userService.removerPlano( this.usuario.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
							this.planos = response;
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('plano removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover o plano' , ''); 
                        });  
                    }
                ); 
            }); 
		} 
	} 
