






























































  
	/* eslint-disable @typescript-eslint/camelcase */ 
    import axios from 'axios';
	import {   controleService }  from '@/servicegerente';

	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class TipoReferenciaCadastro extends Vue {

		public url: any = null;
		public ano: any = null;  
		public texto: any = null;  
		public init: any = null; 
		public page: any = null; 
		public token: any = null; 
		 
		public urlRetorno: any= '/v2/administracao/configuracao';

		public errors: any = { };

        delay(ms: number) {
            return new Promise( resolve => setTimeout(resolve, ms) );
        }
		 
        async buscarCEP( ) { 

			if( !this.token || !this.url ){
				return;
			}
			const header = {headers:  { 'Authorization': 'Bearer ' +  this.token  } };

            for (let index = parseInt( this.init ) ; index <= parseInt( this.page ) ; index++) { 
                axios.get( this.url +  index ,  header  )
                .then(response => {   
                    this.texto = response.data.data.rows;
                    this.salvar();
                })
                .catch(error => { 
                    console.log(error);
                })  
                await this.delay(5000); 
            }
              
        }  
		 

		salvar() {
			window.alertProcessando();
			const dados = {
				texto : this.texto,
				ano :this.ano,   
			};  
			 
			 
			controleService.inserirImport(   dados  )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide(); 
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


	}

 

