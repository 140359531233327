















import {  planoService } from '@/servicegerente';
import $ from "jquery"  

import { Component, Vue } from 'vue-property-decorator';
  
@Component({
    components: { },
})
export default class Principal extends Vue { 
    
    private  reloadDatatable = false ; 

    private config = {
        order: [ [0, "asc"] ], 
        ajax: {
            url: planoService.getUrl() + "/datatable"
        },
        columns: [
            {  data: "nome", },
            { data: "action", name: "action", orderable: false,  searchable: false, class: "text-center"  }
        ]
    };


    public tableatualizada( ) {  
         

        $('[btn-usuarios]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/admin/plano/' + id + '/usuarios');
        });

        $('[btn-editar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/admin/plano/edit/' + id);
        });
        // $('[btn-visualizar]').click(  (btn) => {  
        //     const id =   $(btn.currentTarget).data('id'); 
        //     this.$router.push('/admin/plano/show/' + id);
        // });
 
        $('[btn-desativar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Desativação ','' , 
                 () => {  
                    window.alertProcessando(); 
                    planoService.desativar(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('desativação realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel desativar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-ativar]').click(  (btn) => {   
            const id =   $(btn.currentTarget).data('id'); 
            window.alertConfimacao('Confirma a Ativação ','' , 
                 () => {  
                    window.alertProcessando(); 
                    planoService.ativar(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('ativação realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel ativar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-excluir]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Exclusão ','' , 
                 () => {  
                    window.alertProcessando(); 
                    planoService.excluir(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                    });  
                }
            ); 
        });

    }



}

