


























    /* eslint-disable @typescript-eslint/camelcase */ 
    import {  userService } from '@/servicegerente';

	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 

	import moment from 'moment'; 
	
	import $ from "jquery" ;
 
	@Component({
		components: {  
        },
	})
	export default class FormUsuarioModulo extends Vue {


        @Prop() readonly modulos!: any;

		private add_modulo_id = 'modal-seguranca-usuario-adicionar-modulo'; 
		private modulo: any =  ''   ;
		  
		AdicionarModulo() { 
			if (this.modulo) { 
				window.alertProcessando(); 
				const dados = {
                    modulo :this.modulo, 
                };  
				userService.adicionarModulo(  this.$route.params.id , dados )
				.then(response => {
					window.alertProcessandoHide(); 
					window.toastSucesso("modulo adicionado com successo.");  
					this.$emit('moduloAdicionado', response ) ; 
					( $('#' + this.add_modulo_id) as any ) .modal('toggle');  
				})
				.catch(error => { 
					window.alertProcessandoHide();
					window.toastErro('Não foi possivel adiciona ' , error.data.message); 
				});   
			} 
		}  
 
    }
 
