













































































 
    /* eslint-disable @typescript-eslint/camelcase */ 
     
    import { disciplinaAdminService } from '@/servicegerente';

    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class DisciplinaEdit extends Vue {


		private model: any = null;



		private nome: any = null;
		private sigla: any= null;
		private meta: any= null;
		private descricao: any= null;
		private nivel: any= null;
		private is_principal: any= null;

		private urlRetorno: any= '/disciplina';

		private errors: any = { };



        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.sigla = this.model.sigla;  
			this.meta = this.model.meta;  
			this.descricao = this.model.descricao;   
			this.nivel = this.model.nivel;   
			this.is_principal = this.model.is_principal;   
        }
        

        created() {
            window.alertProcessando(); 
            disciplinaAdminService.getDisciplina(this.$route.params.id , true)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Disciplina', error.data);
                    window.alertProcessandoHide();
                    
                }); 
        }



		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao, 
				sigla :this.sigla, 
				meta :this.meta,  
				nivel :this.nivel,  
				is_principal :this.is_principal,  
			};  
			 
			 
			disciplinaAdminService.update( this.$route.params.id ,  dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

    }
    
 

