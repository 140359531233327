










































 
 
    import { bancaService } from '@/servicegerente'; 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class GuiaEdit extends Vue {
 
        public model: any = null; 

		public sigla: any= null;  
		public nome: any= null;
		public descricao: any= null;

		public urlRetorno: any= '/v2/administracao/banca'; 
		public errors: any = { };
  
        created() { 
			 
            window.alertProcessando();
            bancaService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o configuração ', error.data);
                    window.alertProcessandoHide();
                     
                }); 
        }
 
        @Watch('model')
		tabelaAtualizada(newVal: any) {  
			this.nome = this.model.nome;   
			this.sigla = this.model.sigla;   
			this.descricao = this.model.descricao;   
        }
         
		salvar() {
			window.alertProcessando();
			const dados = { 
				sigla: this.sigla,  
				nome: this.nome,   
				descricao: this.descricao,   
			};  
			  
			bancaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide(); 
				console.log(error);
			});  
		} 
  
    }
 
