
import { RouteConfig } from 'vue-router' 



import UsuarioPontuacao from '@/views/v2/seguranca/usuario/PontuacaoEdit.vue';
import UsuarioLog from '@/views/v2/seguranca/usuario/LogPontuacao.vue';
import UsuarioIndex from '@/views/v2/seguranca/usuario/Index.vue';
import UsuarioEdit from '@/views/v2/seguranca/usuario/Edit.vue';
import UsuarioShow from '@/views/v2/seguranca/usuario/Show.vue';
import UsuarioPerfil from '@/views/v2/seguranca/usuario/Perfil.vue'; 
import UsuarioPlano from '@/views/v2/seguranca/usuario/Plano.vue'; 
import UsuarioHistoricoPerfil from '@/views/v2/seguranca/usuario/HistoricoPerfil.vue';
import UsuarioHistoricoPlano from '@/views/v2/seguranca/usuario/HistoricoPlano.vue';



import PerfilIndex from '@/views/v2/seguranca/perfil/Index.vue';
import PerfilCadastro from '@/views/v2/seguranca/perfil/Cadastro.vue';
import PerfilUsuarios from '@/views/v2/seguranca/perfil/Usuarios.vue';
import PerfilPermissao from '@/views/v2/seguranca/perfil/Permissao.vue';
import PerfilHistoricoPermissao from '@/views/v2/seguranca/perfil/HistoricoPermissao.vue';




import PermissaoIndex from '@/views/v2/seguranca/permissao/Index.vue';
import PermissaoCadastro from '@/views/v2/seguranca/permissao/Cadastro.vue';
import PermissaoEdit from '@/views/v2/seguranca/permissao/Edit.vue';
import PermissaoPerfis from '@/views/v2/seguranca/permissao/Perfis.vue';


import LogLogin from '@/views/v2/seguranca/loginlog/Index.vue';

import HistoricoIndex from '@/views/v2/seguranca/historico/Index.vue';
 

import ControleIndex from '@/views/v2/seguranca/controle/Index.vue';
import ControleMsnTodosusuarios from '@/views/v2/seguranca/controle/MensagemTodosUsuarios.vue'; 
import ControleCard from '@/views/v2/seguranca/controle/CardControle.vue'; 

import BarraLateralAdmin from '@/components/layout/BarraLateralAdmin.vue'
import Header from '@/components/layout/Header.vue' 
import Footer from '@/components/layout/Footer.vue'


const routesSeguranca: Array<RouteConfig> = [
     
    
    /*
	|--------------------------------------------------------------------------
	| USUARIO
	|--------------------------------------------------------------------------
	*/

	{
		path: '/usuario',
		name: 'Usuarios',
		components: {
			default: UsuarioIndex,
			sidebar: BarraLateralAdmin, 
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/usuario/edit/:id',
		name: 'Usuarios_Edit',
		components: {
			default: UsuarioEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
	{
		path: '/usuario/show/:id',
		name: 'Usuarios_Show',
		components: {
			default: UsuarioShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
	{
		path: '/usuario/:id/perfil',
		components: {
			default: UsuarioPerfil,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},   
	{
		path: '/usuario/:id/perfil/historico',
		components: {
			default: UsuarioHistoricoPerfil,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},    	

	{
		path: '/usuario/:id/modulo',
		components: {
			default: require('../views/v2/seguranca/usuario/Modulo').default,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
 
	{
		path: '/usuario/:id/plano',
		components: {
			default: UsuarioPlano,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 

	{
		path: '/usuario/:id/plano/historico',
		components: {
			default: UsuarioHistoricoPlano,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},  

	{
		path: '/usuario/:id/pontuacao/historico',
		components: {
			default: UsuarioLog,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},  

	{
		path: '/usuario/:id/pontuacao',
		components: {
			default: UsuarioPontuacao,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},  

 
	



	/*
	|--------------------------------------------------------------------------
	| PERFIL
	|--------------------------------------------------------------------------
	*/
	{
		path: '/perfil',
		components: {
			default: PerfilIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
	{
		path: '/perfil/create',
		components: {
			default: PerfilCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},  
	{
		path: '/perfil/:id/permissao',
		components: {
			default: PerfilPermissao,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/perfil/:id/usuarios',
		components: {
			default: PerfilUsuarios,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},  
	{
		path: '/perfil/:id/permissao/historico',
		components: {
			default: PerfilHistoricoPermissao,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},



	/*
	|--------------------------------------------------------------------------
	| PERMISSÃO
	|--------------------------------------------------------------------------
	*/
	{
		path: '/permissao',  
		components: {
			default: PermissaoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
	{
		path: '/permissao/create',
		components: {
			default: PermissaoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},    
	{
		path: '/permissao/edit/:id',
		components: {
			default: PermissaoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/permissao/:id/perfis',
		components: {
			default: PermissaoPerfis,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},  



	/*
	|--------------------------------------------------------------------------
	| LOG LOGIN
	|--------------------------------------------------------------------------
	*/
	{
		path: '/loginlog',
		components: {
			default: LogLogin,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 



	/*
	|--------------------------------------------------------------------------
	| LOG LOGIN
	|--------------------------------------------------------------------------
	*/
	{
		path: '/historico', 
		components: {
			default: HistoricoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 




	/*
	|--------------------------------------------------------------------------
	| Controle
	|--------------------------------------------------------------------------
	*/

	{
		path: '/controle',
		name: 'Controle',
		components: {
			default: ControleIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/controle/mensagem/todos/usuarios',
		name: 'ControleMsnTodosusuarios',
		components: {
			default: ControleMsnTodosusuarios,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/controle/card',
		name: 'ControleCard',
		components: {
			default: ControleCard,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 

 
  

]

  
 
export default routesSeguranca
