/* eslint-disable @typescript-eslint/camelcase */  
// import { authHeader } from '..'; 
import { administradorModel } from './index.model';   
// import axios  from 'axios';  


const url =  administradorModel.getUrl() +  '/tentativa' ;

// import  store   from '@/store/index' 

// function logout() {  
// 	store.dispatch('usuario/logout'); 
// }

function getUrl(  ) { 
	return  url; 
}
  
export const tentativaService = { 
	getUrl,  
};