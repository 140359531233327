


















 
 
	import { disciplinaAdminService  }  from '@/servicegerente';
 
    import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class DisciplinaModulo extends Vue {

		
		private urlRetorno: any= '/disciplina';
		private disciplina: any= null;


		private config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url: disciplinaAdminService.getUrl() + '/' + this.$route.params.id + '/modulo/datatable'
			},
			columns: [
				{ data: 'nome', name: 'modulo.nome' },
				{ data: 'descricao', name: 'modulo.descricao' }, 
				{ data: 'has_instrucao', name: 'has_instrucao' }, 
				{ data: 'id_responsavel', name: 'id_responsavel' }, 
			],
		};


		created() { 
			window.alertProcessando();
			disciplinaAdminService.getDisciplina( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.disciplina = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Assunto', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.urlRetorno);
			});   
		} 
	}

	 

