














































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { ementaService , ementaTagService , ementaAssuntoService }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
	import $ from "jquery"    

    import EmentaTagCadastro from './_EmentaTagCadastro.vue'
    import EmentaTagEditar from './_EmentaTagEditar.vue'
    import EmentaAssuntoCadastro from './_EmentaAssuntoCadastro.vue'
 
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 

    @Component({
        components: { EmentaTagEditar, EmentaTagCadastro , EmentaAssuntoCadastro  },
    })
    export default class GuiaShow extends Vue { 
 
		@gerente.State
		public modulo!: any

        public url_retorno: any = '/v2/administracao/ementa' ;
        public model: any = null ;
        public modal_atualizar: any =  'modal_ementa_tag_update_' ;
        public dados: any =  null ;
        public ids: any =  null ;
        public identificador_api_min: any =  null ;
        public identificador_api_max: any =  null ;

		public  reloadAssuntoDatatable = false ; 
		public  reloadDatatable = false ; 
		public  disciplina_id: any = null ; 
		public  banca_id: any = null ; 
		public  pesquisa: any = null ; 
        
        public config = {  
            order: [  [3, "asc"] , [2, "asc"] ],
            ajax: {
                url: ementaService.getUrl() + '/' + this.$route.params.id + '/tags/datatable',
            },
            columns: [ 
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'tag_id',  name: 'tag_id'  },   
                { data: 'tag.nome',  name: 'tag.nome' , orderable: false, searchable: false },  
                { data: 'sequencia', name: 'sequencia',  class: 'text-center' },
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ], 
            columnDefs: [ 
                {
                    targets: 4,
                    render: ( row: any, type: any, val: any, meta: any ) => {  
                        let texto = '' ; 
                        let buttom = '' ;  
                        for (const key in val) { 
                            if(key != 'action'){
                                buttom = buttom + ' data-' + key + '="'+ val[key] +'"'  
                            } 
                        } 
                        texto = texto + '<button title="Editar" class="btn btn-success btn-sm" btn-editar '+ buttom +'  data-toggle="modal" data-target="#' +
                        this.modal_atualizar  + '"><i class="fa fa-edit"></i></button>';   
                        return texto;  
                    }, 
                } 
            ], 
        };
        
        
        public configAssunto = {  
            order: [ [0, "asc"] ],
            ajax: {
                url: ementaService.getUrl() + '/' + this.$route.params.id + '/assuntos/datatable',
            },
            columns: [ 
                { data: 'assunto_id', name: 'assunto_id',  class: 'text-center' }, 
                { data: 'assunto.nome',  name: 'assunto.nome' , orderable: false, searchable: false },  
                { data: 'assunto.disciplina_id',  name: 'assunto.disciplina_id' , orderable: false, searchable: false },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ],  
        };


        ementaTagAdicionada( ) { 
            this.reloadDatatable = !this.reloadDatatable;
        }
 

        ementaAssuntoAdicionada( ) { 
            this.reloadAssuntoDatatable = !this.reloadAssuntoDatatable;
        }
 

		created() { 
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else{
                window.alertProcessando();
                ementaService.getModel( this.$route.params.id )
                .then(response => {
                    this.model = response ;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.alertProcessandoHide(); 
                    window.toastErro('Não foi possivel achar Configuração');
                }); 
            } 
		}
  

        public tableatualizadaAssunto( ) {  
            
            $('#ementa-assunto-datatable [btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão do assunto','' , 
                     () => {  
                        window.alertProcessando(); 
                        ementaAssuntoService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadAssuntoDatatable = !this.reloadAssuntoDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });
        } 



        public tableatualizada( ) {  
            $('#ementa-tag-datatable [btn-editar]').click(  (btn) => {   
                this.dados =    $(btn.currentTarget).data( );  
            }); 
            $('#ementa-tag-datatable [btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão da tag','' , 
                     () => {  
                        window.alertProcessando(); 
                        ementaTagService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });
        } 





	}
 

