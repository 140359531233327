
































































































































 

	/* eslint-disable @typescript-eslint/no-explicit-any */ 
	/* eslint-disable @typescript-eslint/no-unused-vars */ 
	/* eslint-disable @typescript-eslint/camelcase */  


	import { resumoService, moduloService  , manzaTinyEditorColorsMap  }  from '@/servicegerente'; 
	import Editor from '@tinymce/tinymce-vue' 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente')

	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class ResumoCadastro extends Vue {
 
		@gerente.State
		public modulo!: any
 
		@gerente.State
		public disciplina!: any
 
		@gerente.State
		public assunto!: any
 
        public modulos: any = null;
        public disciplinas: any = null; 
		public assuntos: any = null;
         
		public titulo: any = null;
		public tipo: any= 'TEXTO';
		public disciplina_restrito: any= "1";
		public restrito: any= "1"; 
		public conteudo: any= null; 
		public modulo_id: any= null; 
		public disciplina_id: any= null; 
		public pdf: any= null; 
		public assunto_id: any= null;  

        public colors: any = []; 
 
		public urlRetorno: any= '/v2/administracao/resumo'; 
		public errors: any = { };

        created() { 

			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}

			this.colors = manzaTinyEditorColorsMap; 
			 
			if(this.disciplina){
				this.disciplina_id = this.disciplina.id 
			}
			 
			if(this.assunto){
				this.assunto_id = this.assunto.id 
			}

			this.modulo_id = this.modulo.id; 

			moduloService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			});
 
			moduloService.buscarTodasDisciplina( this.modulo.id ) 
			.then(response => {
				this.disciplinas = response ;
				if(this.disciplina_id){
					this.buscarAssunto(this.disciplina_id);
				}
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			});  
		}



		@Watch('disciplina_id')
		mudouDisciplina(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = null ;
			this.assunto_id = null ;  
		}




		

		buscarAssunto( id: any) { 
			if(this.disciplinas){
				const filtrada = this.disciplinas.filter( ( disciplina: any) => { 
					if (disciplina.id == id) {
						return true;
					}
				})[0]; 
				if(filtrada){
					this.assuntos = filtrada.assuntos ;
				} 
			} 
		}

  



		salvar() {
            window.alertProcessando();
            
            const dados = new FormData();
             
			if( this.tipo == 'PDF'){
				dados.append('file_pdf',this.pdf );
			}
			if( this.tipo == 'TEXTO'){
				dados.set('conteudo', this.conteudo );
			}
			
			dados.set('titulo', this.titulo);
			dados.set('tipo', this.tipo);
			dados.set('modulo_id', this.modulo_id);
			dados.set('disciplina_id', this.disciplina_id);
			dados.set('disciplina_restrito', this.disciplina_restrito);
			dados.set('restrito', this.restrito);
			dados.set('assunto_id', this.assunto_id);
 
			resumoService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


		handleFileUpload(){
			this.pdf = this.$refs.pdf.files[0];
		}


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
	}
	
   
