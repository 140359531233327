












	import _datatable from './_datatable.vue'

	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { 
			'datatable-plano': _datatable,
		},
    })
    export default class Principal extends Vue { 
 
	}
	 

