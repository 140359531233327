





























































































































/* eslint-disable @typescript-eslint/camelcase */ 
 
	import { assuntoAdminService, moduloAdminService , resumoAdminService , disciplinaAdminService }  from '@/servicegerente';

	import Editor from '@tinymce/tinymce-vue'


	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PermissaoCadastro extends Vue {

        private model: any = null;
        private modulos: any = null;
        private disciplinas: any = null;
		private assuntos: any = null;
         


		private titulo: any = null; 
		private disciplina_restrito: any= null;
		private restrito: any= null; 
		private conteudo: any= null; 
		private modulo_id: any= null; 
		private disciplina_id: any= null;  
		private assunto_id: any= null;  
		  
  
		private urlRetorno: any= '/resumo';

		private errors: any = { };

       	created() {
			
			this.buscarTodosAssunto( ) ; 

			window.alertProcessando();
			
			resumoAdminService.getResumo(this.$route.params.id , true) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a Tipo Referência', error.data);
				window.alertProcessandoHide();
				 
			});

			moduloAdminService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as tipo Referencias');
			});

			disciplinaAdminService.getAll() 
			.then(response => {
				this.disciplinas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			});


		}


		@Watch('disciplina_id')
		mudouDisciplina(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.buscarTodosAssunto( );
		}

		buscarAssunto(id: any) {
			window.alertProcessando();
			disciplinaAdminService.buscarTodosAssuntoDaDisciplina(id) 
			.then(response => {
				this.assuntos = response ;
				window.alertProcessandoHide();

			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os assunto');
				window.alertProcessandoHide();
			}); 
		}

		buscarTodosAssunto( ) {
			assuntoAdminService.getAll() 
				.then(response => {
					this.assuntos = response ;
				})
				.catch(error => {
					window.toastErro('Não foi possivel achar os assunto');

				});
		}


		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.titulo = this.model.titulo;
			this.conteudo = this.model.conteudo;  
			this.modulo_id = this.model.modulo_id;  
			this.disciplina_restrito = this.model.disciplina_restrito;  
			this.restrito = this.model.restrito;  
			this.disciplina_id = this.model.disciplina_id;  
			this.assunto_id = this.model.assunto_id;  
		}

		 
		salvar() {
			window.alertProcessando();
			const dados = {
				titulo :this.titulo, 
				conteudo :this.conteudo, 
				modulo_id :this.modulo_id, 
				disciplina_restrito :this.disciplina_restrito, 
				restrito :this.restrito, 
				disciplina_id :this.disciplina_id, 
				assunto_id :this.assunto_id, 
			};   
			 
			resumoAdminService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
	

		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		
		
		
	}

 


