







































	
	import { permissaoService  }  from '@/servicegerente';
 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class PermissaoCadastro extends Vue {
		
		private model: any = null;


		private nome: any = null;
		private descricao: any= null;

		private urlRetorno: any= null;

		private errors: any = { };

		created() {  
			this.urlRetorno =  '/permissao';  
			window.alertProcessando();
			permissaoService.getPermissao( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a Permissão', error.data);
				window.alertProcessandoHide();
			}); 
		}



		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.descricao = this.model.descricao;  
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao, 
			};  
			 
			 
			permissaoService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	}
 

