var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.usuario)?_c('span',[_c('button',{staticClass:"btn btn-tool",attrs:{"title":"Adicionar","type":"button","data-toggle":"modal","data-target":'#' + _vm.add_plano_id},on:{"click":function($event){return _vm.acessou()}}},[_c('i',{staticClass:"fa fa-award"})]),_c('custom-modal',{attrs:{"id":_vm.add_plano_id,"titulo":"Bonificar Usuário"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-8"},[_c('crudFormElemento',[_c('label',{attrs:{"for":"assunto"}},[_vm._v("Assunto:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assunto),expression:"assunto"}],staticClass:"form-control",attrs:{"required":"","type":"text","id":"assunto","name":"assunto"},domProps:{"value":(_vm.assunto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.assunto=$event.target.value}}})])],1),_c('div',{staticClass:"col-12 col-sm-4"},[_c('crudFormElemento',[_c('label',{attrs:{"for":"assunto"}},[_vm._v("Dias:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dias),expression:"dias"}],staticClass:"form-control",attrs:{"required":"","type":"number","id":"dias","name":"dias"},domProps:{"value":(_vm.dias)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dias=$event.target.value}}})])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.isvisivel)?_c('crudFormElemento',[_c('label',{attrs:{"for":"mensagem"}},[_vm._v("Mensagem:")]),_c('editor',{attrs:{"api-key":"wfmp2fmb67ocbs0x86o0v1whb3stwck3lhdpx52xd9988ebe","init":{
                                height: 350,
                                menubar: true,
                                relative_urls: false,
                                remove_script_host: false,
                                fontsize_formats: '7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 22pt 24pt 36pt 48pt',
                                plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                                ],
                                color_cols: 10,
								color_map: _vm.colors,
                                toolbar:
                                'undo redo | bold italic | bullist numlist | charmap   preview  removeformat '
                            }},model:{value:(_vm.mensagem),callback:function ($$v) {_vm.mensagem=$$v},expression:"mensagem"}})],1):_vm._e()],1)]),_c('section',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-lg-12"},[_c('button',{staticClass:"btn btn-success",on:{"click":_vm.bonificar}},[_vm._v("Enviar")])])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }