
















































































 
	
	/* eslint-disable @typescript-eslint/camelcase */ 
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
    import { usuarioService  }  from '@/servicegerente';  
    import { namespace } from 'vuex-class'
	import Planos from './_Planos.vue'  

    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 


	@Component({
		components: { 
            'planos': Planos,
        },
	 })
    export default class UsuarioShow extends Vue {
		
       
        @gerente.State
		public modulo! 
        
		
		@usuario.State
		public perfis!: any

 
        private model: any = null;

		private apiUrl: any= null;
		private urlRetorno: any= null;
		private urlEditar: any= null;
		private urlPlano: any= null;


		private errors: any = { };

		created() { 

            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                this.apiUrl =  usuarioService.getUrl() + '/' + this.$route.params.id; 
                this.urlRetorno =  '/v2/administracao/usuario'; 
                // this.urlEditar =  '/usuario/edit/' + this.$route.params.id; 
                // this.urlPlano =  '/usuario/' + this.$route.params.id + '/plano' ;  
                this.buscarUsuario() ; 
            } 

			
			
        }
        


        
        buscarUsuario() { 
            usuarioService.getUsuario( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o usuario', error.data);
				window.alertProcessandoHide();
			}); 
        }


        
		
		get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}
		  
		  
   
 
	} 

