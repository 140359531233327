




























































 
  
    import { moduloService } from '@/servicegerente';  
    import { Watch, Component, Vue } from 'vue-property-decorator'; 
    import FormTermoUso from './_termo.vue'  
    import { namespace } from 'vuex-class' 
    const gerente = namespace('gerente') 
    const usuario = namespace('usuario')
    
    @Component({
        components: {
	        FormTermoUso,
        },
    })
    export default class Modulos extends Vue { 


        @gerente.State
		public modulo!: any


        @usuario.State
		public perfis!: any

		public nome: any = null;


		public modulos: any = null;
		public modulosFiltrados: any = null;

        @gerente.Action
        public alterarModulo!: ( modulo: any ) => void
         
        @gerente.Action
        public resetModulo!: (  ) => void
 
        created() { 
            this.getModulos(false);  
        }
        
        
		@Watch('nome')
		filtroAlterado(newVal: any) {
            this.filtrar();
		}
        
		async filtrar() {
            if(!this.nome || this.nome == '' || this.nome == ' ' ){
                this.modulosFiltrados = this.modulos;
            }
            else{
                this.modulosFiltrados = this.modulos.filter( ( mod: any) => {
                    if (mod.nome.toLowerCase().includes(  this.nome.toLowerCase() ) ) {
                        return true;
                    }
                }) ;
            }
            
		} 
		
        
        public getModulos( api: boolean ){
            window.alertProcessando();  
            moduloService.getAll( api )
                .then(response => {
                    this.modulos = response;
                    this.filtrar();
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
        }

        atualizarModulos(){ 
            this.resetModulo(); 
            this.getModulos(true);  
        }


        get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}



    }
