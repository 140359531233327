
























	
	import { resumoAdminService }  from '@/servicegerente';
	
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

      	private urlRetorno: any= '/resumo';
        private model: any = null;

		created() { 
			window.alertProcessando();
			resumoAdminService.getResumo( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				
				window.toastErro('Não foi possivel achar Resumo');
			});
  
		}


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		
		

		

	}
	
 


