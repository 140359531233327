














































    /* eslint-disable @typescript-eslint/camelcase */  
    import {  respostaService, manzaTinyEditorColorsMap } from "@/servicegerente"; 
    import $ from "jquery"   
    import Editor from "@tinymce/tinymce-vue"; 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 

    @Component({
        components: {
            editor: Editor, 
         },
    })
    export default class FormCriarResposta extends Vue { 

        public resposta: any  = null;  
        public add_tag_id = 'modal-seguranca-pergunta-criar-respostas'; 
        public colors: any = []; 
        public isvisivel = false; 
  
        acessou(){
            this.isvisivel = true; 
        }

        created() {
			this.colors = manzaTinyEditorColorsMap;
        }
 
        salvar() {
            if(this.resposta){
                const data: any = {}; 
                data["texto"] = this.resposta;
                data["pergunta_id"] = this.$route.params.id;
                data["correta"] = false;

                window.alertProcessando();
                respostaService
                    .criarResposta(data)
                    .then(response => {
                        window.toastSucesso(response);
                        this.resposta = ""; 
                        this.$emit('respostaCriada', response); 
                        ( $('#' + this.add_tag_id) as any ) .modal('toggle');
                        window.alertProcessandoHide(); 
                    })
                    .catch(error => {
                        console.log(error);
                        window.alertProcessandoHide();
                    }); 
            }
            
        }
 

    }
 
 
        

