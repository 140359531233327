






























































































































































































































































































 
    /* eslint-disable @typescript-eslint/camelcase */ 
    
    import { moduloAdminService , tipoReferenciaService } from '@/servicegerente';
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
		
	import Editor from '@tinymce/tinymce-vue'

	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class ModuloEdit extends Vue {


        private tipoReferencias: any = null;


		private model: any = null;


		private nome: any = null;
		private descricao: any= null;
		private tipo_referencia_id: any= null;

		private nivel: any= 'Aberto';
		private imagem: any= 'livro.jpg';
		private valor: any= 'Gratis';
		private is_desafio: any= null;
 
		private has_conceito: any= 0;
		private has_simulado: any= 0;
		private has_prova_anterior: any= 0;
		private has_noticia: any= 0;


		private has_pergunta: any= false;
		private has_material: any= false;
		private has_relatorio: any= false;
		private ativo: any= false;
		private restrito: any= false;
		private has_audio: any= false;

		private termo_uso: any= '';
		private termo_simulado: any= '';
		private url: any= '';
		private urlresposta: any= '';


		private urlRetorno: any=  '/modulo';

		private errors: any = { };

		  
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao, 
				nivel :this.nivel, 
				imagem :this.imagem, 
				valor :this.valor, 
				is_desafio :this.is_desafio, 
				has_conceito :this.has_conceito, 
				has_simulado :this.has_simulado, 
				has_prova_anterior :this.has_prova_anterior, 
				tipo_referencia_id :this.tipo_referencia_id, 

				has_pergunta :this.has_pergunta, 
				has_material :this.has_material, 
				has_noticia :this.has_noticia, 
				has_relatorio :this.has_relatorio, 
				termo_uso :this.termo_uso, 
				termo_simulado :this.termo_simulado, 
				urlresposta :this.urlresposta, 
				url :this.url, 

				ativo :this.ativo, 
				restrito :this.restrito, 
				has_audio :this.has_audio, 
			};  
			  
			moduloAdminService.update(  this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.descricao = this.model.descricao;  
			this.nivel = this.model.nivel;  
			this.imagem = this.model.imagem;  
			this.valor = this.model.valor;  
			this.is_desafio = this.model.is_desafio;  
			this.has_conceito = this.model.has_conceito;  
			this.has_simulado = this.model.has_simulado;  
			this.has_prova_anterior = this.model.has_prova_anterior;  
			this.tipo_referencia_id = this.model.tipo_referencia_id;  

			this.has_pergunta = this.model.has_pergunta;  
			this.has_material = this.model.has_material;  
			this.has_relatorio = this.model.has_relatorio;  
			this.termo_uso = this.model.termo_uso;  
			this.termo_simulado = this.model.termo_simulado;  
			this.urlresposta = this.model.urlresposta;  
			this.url = this.model.url;  
			this.ativo = this.model.ativo;  
			this.restrito = this.model.restrito;  
			this.has_audio = this.model.has_audio;  
			this.has_noticia = this.model.has_noticia;  
		}

        created() {
            window.alertProcessando();
			
			tipoReferenciaService.getAll()
                .then(response => {
                    this.tipoReferencias = response;
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar as tipo Referencias');
                }); 


            moduloAdminService.getModulo(this.$route.params.id, true)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide();
                     
                });

        }


	}


 

