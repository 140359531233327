































	import { assuntoAdminService   }  from '@/servicegerente';   
	import $ from "jquery"  
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
     
    @Component({
        components: {
             
        },
    })
    export default class AssuntoIndex extends Vue {

        @usuario.State
		public perfis!: any



		public  reloadDatatable = false ; 

		public config = { 
            order: [[ 0, "desc" ]],
			ajax: { 
				url:  assuntoAdminService.getUrl() + '/datatable', 
			},
			columns: [
				{ data: 'id', name: 'assunto.id' , class: 'text-center' },
				{ data: 'nome', name: 'assunto.nome' }, 
				{ data: 'sigla', name: 'assunto.sigla' }, 
				{ data: 'disciplina.nome', name: 'disciplina.nome' },  
				{ data: 'status', name: 'assunto.deleted_at' , class: 'text-center'}, 
				{ data: 'perguntas_count', name: 'perguntas_count', class: 'text-center' },
				{ data: 'perguntas_chs_count', name: 'perguntas_chs_count', class: 'text-center' },
				{ data: 'perguntas_restrita_count', name: 'perguntas_restrita_count', class: 'text-center' },
				{ data: 'perguntas_questionario_count', name: 'perguntas_questionario_count', class: 'text-center' },
				{ data: 'sequencia', name: 'sequencia', class: 'text-center' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};

        get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}



		public tableatualizada( ) {   
            
            $('[btn-criar-tag]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/tag/create/' + id , '_blank');  
            });

            $('[btn-visualizar-tag]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');
                window.open('#/assunto/' + id + '/tag') ;
                // this.$router.push('/assunto/' + id + '/tag');
            });

            $('[btn-visualizar-pergunta]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/assunto/' + id + '/pergunta');
            });
            $('[btn-visualizar-modulo]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/assunto/' + id + '/modulo');
            });
            $('[btn-visualizar-disciplina]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/assunto/' + id + '/disciplina');
			});
			
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/assunto/show/' + id ) ;
                // this.$router.push('/assunto/show/' + id  );
            });

            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/assunto/edit/' + id ) ;
                // this.$router.push('/assunto/edit/' + id  );
            });

			$('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        assuntoAdminService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        assuntoAdminService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
			});
			
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        assuntoAdminService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });




             

            
            $('[btn-subir-questoes]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a finalizacao qq','' , 
                     () => {  
                        window.alertProcessando(); 
                        assuntoAdminService.subirQuestoes(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });


		}
		 
	}



 

