





































 
	import { moduloService ,  simuladoService }  from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator'; 
    import { namespace } from 'vuex-class' 
    const gerente = namespace('gerente') 
     
    @Component({
        components: { 
        },
    })
    export default class DisciplinaIndex extends Vue {


        @gerente.State
		public modulo!: any
    

		private token: any = null;


        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else{
                this.config.ajax.url =  simuladoService.getUrl() + '/modulo/' + this.modulo.id + '/datatable';

                const temptoken = localStorage.getItem('user');
                if(temptoken){
                    this.token =  JSON.parse(temptoken)   ;
                }
			}
        }


        get urlconceitos(): string {
            return   simuladoService.getUrl() + '/modulo/' + this.modulo.id +  '/classificacao/pdf?token=' + this.token; 
		}

		private  reloadDatatable = false ; 

		private config = { 
            order: [[ 0, "desc" ]],
			ajax: { 
				url: simuladoService.getUrl()  
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'nome', name: 'nome' }, 
				{ data: 'ativo', name: 'ativo' }, 
				{ data: 'created_at', name: 'created_at' }, 
				// { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};



		public tableatualizada( ) {    
            $('[btn-select]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                this.alterarDisciplina($(btn.currentTarget).data( ));
                window.toastSucesso('disciplina selecionada com sucesso!!' );  
            });  
		}


        
        

        public iniciarSimulado( ) {  
            window.alertConfimacao('Confirma a criação do Simulado ','' , 
                () => {  
                    window.alertProcessando(); 
                    moduloService.InciarSimulado( this.modulo.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('criação realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel criar simulado ' , error.data); 
                    });  
                }
            ); 
        }
		 
	}

 
