








































































/* eslint-disable @typescript-eslint/camelcase */ 
 
	import { referenciaService }  from '@/servicegerente';


	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class PermissaoCadastro extends Vue {

        private model: any = null;
         
		private nome: any = null;
		private descricao: any= null; 
		private banca: any= null;
		private ano: any= null;
		private fonte: any= null;
		private gabarito: any= null;
		  
		private urlRetorno: any= '/referencia';

		private errors: any = { };

       	created() {
			window.alertProcessando(); 
			referenciaService.getReferencia(this.$route.params.id) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a Referência', error.data);
				window.alertProcessandoHide(); 
			}); 
		}


		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.descricao = this.model.descricao;  
			this.banca = this.model.banca;  
			this.ano = this.model.ano;   
			this.gabarito = this.model.gabarito;   
			this.fonte = this.model.fonte;   
		}

		 
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				ano :this.ano, 
				descricao :this.descricao, 
				banca :this.banca,  
				gabarito :this.gabarito,  
				fonte :this.fonte,  
			};   
			 
			referenciaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


 

	}
	 


