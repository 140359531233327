
import  { apiModel } from '../api.url'; 


 
const url =  apiModel.getApi( ) + '/seguranca'  ;
const urlRelatorio =  apiModel.getApi( ) + '/relatorio'  ;

const urlSite =  apiModel.getSiteApi( )  ;


function getUrl(  ) { 
	return  url; 
}

function getSiteApi(  ) { 
	return  urlSite; 
}

 

function getUrlRelatorio(  ) { 
	return  urlRelatorio; 
}

 
export const segurancaModel = { 
	getUrl, 
	getUrlRelatorio, 
	getSiteApi,
};