





















  


	import FormModuloDisciplinas from './_DisciplinaFormAdicionar.vue' 

	import { moduloAdminService  }  from '@/servicegerente';
 
	import $ from "jquery"  

    import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
            'modulo-adicionar-disciplina':  FormModuloDisciplinas,
        },
    })
    export default class ModuloDisciplinas extends Vue {

        public disciplinas: any= null;  
        public modulo: any= null;  
        public reloadDatatable: any= false;  
		public url_retorno: any= '/modulo';  
		 

		public config: any= {  
            order: [[ 1, "asc" ]],
			ajax: { 
				url: moduloAdminService.getUrl() + '/' + this.$route.params.id + '/disciplina/datatable'
			},
			columns: [
				{ data: 'nome', name: 'disciplina.nome' },
				{ data: 'descricao', name: 'disciplina.descricao' }, 
				{ data: 'peso', name: 'disciplina.peso' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  


        created() {
            window.alertProcessando();
            moduloAdminService.getModulo(this.$route.params.id)
                .then(response => {
                    window.alertProcessandoHide();
                    this.modulo = response; 
                })
                .catch(error => {
                    window.alertProcessandoHide();
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    this.$router.push(this.url_retorno);
                }); 
        }

         

		 
		disciplinaAdicionado(event: any) {
			this.disciplinas = event; 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		buscarDisciplina() {
			window.alertProcessando();
			moduloAdminService.buscarDisciplina( this.$route.params.id )   
			.then(response => {
				this.disciplinas = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os Disciplinas para adiocionar", error.data);
				window.alertProcessandoHide();
			});   
		}
 

        @Watch('modulo')
		perfilAlterado(newVal: any) {
			this.buscarDisciplina();
		}



        public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        moduloAdminService.removerDisciplina( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
							this.disciplinas = response;
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Disciplina removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover a Disciplina' , ''); 
                        });  
                    }
                ); 
            });

        }


	}
	
  

