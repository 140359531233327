
























import { perfilService } from '@/servicegerente';

import $ from "jquery"  

import { Component, Vue } from 'vue-property-decorator';
 
@Component({
    components: {
         
    },
})
export default class PerfilIndex extends Vue {

    private config = { 
        order: [  [1, "asc"] ],
        ajax: {
            url: perfilService.getUrl() + '/datatable'
        },
        columns: [
            {
                data: 'id',
                name: 'id'
            },
            {
                data: 'nome',
                name: 'nome'
            },
            {
                data: 'descricao',
                name: 'descricao'
            },
            {
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false,
                class: 'text-center'
            }
        ],
    };

    private  reloadDatatable = false ; 


    public tableatualizada( ) {  
        
        
        $('[btn-visualizar-permissao]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/perfil/' + id + '/permissao');
        });

        $('[btn-visualizar-usuario]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/perfil/' + id + '/usuarios');
        });

        $('[btn-excluir]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Exclusão ','' , 
                 () => {  
                    window.alertProcessando(); 
                    perfilService.excluir(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                    });  
                }
            ); 
        });

    }




}


