
import { RouteConfig } from 'vue-router' 



import UserPlanoIndex from '@/views/v2/administrador/user_plano/Index.vue';
import ComentarioIndex from '@/views/v2/administrador/comentario/Index.vue';
import ComentarioEdit from '@/views/v2/administrador/comentario/Edit.vue';





import ModuloIndex from '@/views/v2/administrador/modulo/Index.vue';
import ModuloCadastro from '@/views/v2/administrador/modulo/Cadastro.vue';
import ModuloEdit from '@/views/v2/administrador/modulo/Edit.vue';
import ModuloDisciplinas from '@/views/v2/administrador/modulo/Disciplinas.vue';
import ModuloDisciplinasAssuntos from '@/views/v2/administrador/modulo/Assuntos.vue';







import PlanoIndex from '@/views/v2/administrador/plano/Index.vue';
import PlanoCadastro from '@/views/v2/administrador/plano/Cadastro.vue';
import PlanoEdit from '@/views/v2/administrador/plano/Edit.vue';
import PlanoUsuario from '@/views/v2/administrador/plano/Usuarios.vue';




import TipoReferenciaIndex from '@/views/v2/administrador/tipoReferencia/Index.vue';
import TipoReferenciaCadastro from '@/views/v2/administrador/tipoReferencia/Cadastro.vue';
import TipoReferenciaEdit from '@/views/v2/administrador/tipoReferencia/Edit.vue';
import TipoReferenciaReferencia from '@/views/v2/administrador/tipoReferencia/Referencia.vue';





import TextoIndex from '@/views/v2/administrador/texto/Index.vue';
import TextoShow from '@/views/v2/administrador/texto/Show.vue'; 
import TextoEdit from '@/views/v2/administrador/texto/Edit.vue';
import TextoCadastro from '@/views/v2/administrador/texto/Cadastro.vue';


import GuiaIndex from '@/views/v2/administrador/guia/Index.vue';
import GuiaShow from '@/views/v2/administrador/guia/Show.vue'; 
import GuiaEdit from '@/views/v2/administrador/guia/Edit.vue';
import GuiaCadastro from '@/views/v2/administrador/guia/Cadastro.vue';
import GuiaHistorico from '@/views/v2/administrador/guia/Historico.vue';



import RespostaIndex from '@/views/v2/administrador/resposta/Index.vue';
import RespostaEdit from '@/views/v2/administrador/resposta/Edit.vue';






import AssuntoIndex from '@/views/v2/administrador/assunto/Index.vue';
import AssuntoShow from '@/views/v2/administrador/assunto/Show.vue';
import AssuntoTag from '@/views/v2/administrador/assunto/Tag.vue';
import AssuntoModulo from '@/views/v2/administrador/assunto/Modulo.vue'; 
import AssuntoCadastro from '@/views/v2/administrador/assunto/Cadastro.vue';
import AssuntoEdit from '@/views/v2/administrador/assunto/Edit.vue';
import AssuntoPergunta from '@/views/v2/administrador/assunto/Pergunta.vue';





import ReferenciaIndex from '@/views/v2/administrador/referencia/Index.vue';
import ReferenciaCadastro from '@/views/v2/administrador/referencia/Cadastro.vue';
import ReferenciaEdit from '@/views/v2/administrador/referencia/Edit.vue';
import ReferenciaPergunta from '@/views/v2/administrador/referencia/Pergunta.vue';

 



import PerguntaIndex from '@/views/v2/administrador/pergunta/Index.vue';
import PerguntaCadastro from '@/views/v2/administrador/pergunta/Cadastro.vue';
import PerguntaEdit from '@/views/v2/administrador/pergunta/Edit.vue';
import PerguntaShow from '@/views/v2/administrador/pergunta/Show.vue';
import PerguntaHistorico from '@/views/v2/administrador/pergunta/Historico.vue';
// import PerguntaTag from '@/views/v2/administrador/pergunta/Tag.vue';
// import PerguntaReferencia from '@/views/v2/administrador/pergunta/Referencia.vue';




import DisciplinaIndex from '@/views/v2/administrador/disciplina/Index.vue';
import DisciplinaModulo from '@/views/v2/administrador/disciplina/Modulo.vue';
import DisciplinaCadastro from '@/views/v2/administrador/disciplina/Cadastro.vue';
import DisciplinaEdit from '@/views/v2/administrador/disciplina/Edit.vue';
import DisciplinaAssuntos from '@/views/v2/administrador/disciplina/Assuntos.vue';






import TagIndex from '@/views/v2/administrador/tag/Index.vue';
import TagShow from '@/views/v2/administrador/tag/Show.vue';
import TagAssunto from '@/views/v2/administrador/tag/Assunto.vue';
import TagPergunta from '@/views/v2/administrador/tag/Pergunta.vue';
import TagEdit from '@/views/v2/administrador/tag/Edit.vue';
import TagCadastro from '@/views/v2/administrador/tag/Cadastro.vue';
import TagHistorico from '@/views/v2/administrador/tag/Historico.vue';



import ResumoIndex from '@/views/v2/administrador/resumo/Index.vue'; 
import ResumoEdit from '@/views/v2/administrador/resumo/Edit.vue';
import ResumoShow from '@/views/v2/administrador/resumo/Show.vue';
import ResumoVisualizacao from '@/views/v2/administrador/resumo/Visualizacao.vue';
import ResumoCadastro from '@/views/v2/administrador/resumo/Cadastro.vue';
import ResumoHistorico from '@/views/v2/administrador/resumo/Historico.vue';





import AudioAulaIndex from '@/views/v2/administrador/audio_aula/Index.vue'; 
import AudioAulaEdit from '@/views/v2/administrador/audio_aula/Edit.vue'; 
import AudioAulaCadastro from '@/views/v2/administrador/audio_aula/Cadastro.vue';



import TentativaIndex from '@/views/v2/administrador/tentativa/Index.vue';


import GraficoDiarioIndex from '@/views/v2/administrador/grafico_admin/Index.vue'; 


import BarraLateralAdmin from '@/components/layout/BarraLateralAdmin.vue'
import Header from '@/components/layout/Header.vue' 
import Footer from '@/components/layout/Footer.vue'

const routesAdministrador: Array<RouteConfig> = [
     

	

	/*
	|--------------------------------------------------------------------------
	| Audio
	|--------------------------------------------------------------------------
	*/
	{
		path: '/audio_aula', 
		components: {
			default: AudioAulaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	},  

	 
	
	{
		path: '/audio_aula/edit/:id', 
		components: {
			default: AudioAulaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	}, 
	 
	 
	{
		path: '/audio_aula/create', 
		components: {
			default: AudioAulaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	},
 
  
	 
	/*
	|--------------------------------------------------------------------------
	| User Planos
	|--------------------------------------------------------------------------
	*/
	{
		path: '/userplanos', 
		components: {
			default: UserPlanoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	},
 
  


	
	/*
	|--------------------------------------------------------------------------
	| RESUMO
	|--------------------------------------------------------------------------
	*/
	{
		path: '/resumo', 
		components: {
			default: ResumoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	},  
 
	{
		path: '/resumo/edit/:id', 
		components: {
			default: ResumoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	}, 
	{
		path: '/resumo/show/:id', 
		components: {
			default: ResumoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	}, 
	{
		path: '/resumo/visualizar/:id', 
		components: {
			default: ResumoVisualizacao,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	}, 
	{
		path: '/resumo/create', 
		components: {
			default: ResumoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true, requiresPerfil: ['Admin']}
	},
	{
		path: '/resumo/historico', 
		components: {
			default: ResumoHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
	 


	
	/*
	|--------------------------------------------------------------------------
	| TAG
	|--------------------------------------------------------------------------
	*/
	{
		path: '/tag', 
		components: {
			default: TagIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},
	 
	{
		path: '/tag/edit/:id', 
		components: {
			default: TagEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 
	{
		path: '/tag/show/:id', 
		 components: {
			default: TagShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},  
	{
		path: '/tag/create/:assunto_id?', 
		components: {
			default: TagCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},   
	{
		path: '/tag/:id/assuntos', 
		components: {
			default: TagAssunto,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},  
	{
		path: '/tag/:id/perguntas',  
		components: {
			default: TagPergunta,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 
	{
		path: '/tag/historico', 
		components: {
			default: TagHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 

 

	
	/*
	|--------------------------------------------------------------------------
	| DISCIPLINA
	|--------------------------------------------------------------------------
    */
    
	{
		path: '/disciplina', 
		components: {
			default: DisciplinaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},
	{
		path: '/disciplina/create', 
		components: {
			default: DisciplinaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},    
	{
		path: '/disciplina/edit/:id', 
		components: {
			default: DisciplinaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 
	{
		path: '/disciplina/:id/assuntos', 
		components: {
			default: DisciplinaAssuntos,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},   
	{
		path: '/disciplina/:id/modulos', 
		components: {
			default: DisciplinaModulo,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},  





	
    /*
	|--------------------------------------------------------------------------
	| Grafico	
	|--------------------------------------------------------------------------
	*/
	{
		path: '/relatorio/admin/grafico/diario', 
		components: {
			default: GraficoDiarioIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},  


    /*
	|--------------------------------------------------------------------------
	| ESTATISTICA	
	|--------------------------------------------------------------------------
	*/
	{
		path: '/tentativa', 
		components: {
			default: TentativaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	}, 

	
	/*
	|--------------------------------------------------------------------------
	| MODULO
	|--------------------------------------------------------------------------
	*/
	{
		path: '/modulo', 
		components: {
			default: ModuloIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},
	{
		path: '/modulo/create', 
		components: {
			default: ModuloCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},    
	{
		path: '/modulo/edit/:id', 
		components: {
			default: ModuloEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  
	{
		path: '/modulo/:id/pivot/:pivotId/disciplina/:disciplinaId/assunto', 
		components: {
			default: ModuloDisciplinasAssuntos,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},   
	{
		path: '/modulo/:id/disciplina', 
		 components: {
			default: ModuloDisciplinas,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},   
	 




       
	/*
	|--------------------------------------------------------------------------
	| Comentario	
	|--------------------------------------------------------------------------
	*/ 
	{
		path: '/comentario', 
		components: {
			default: ComentarioIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},     
	{
		path: '/comentario/edit/:id', 
		components: {
			default: ComentarioEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	}, 

	 
	




	
	/*
	|--------------------------------------------------------------------------
	| TIPO DE REFERENCIA
	|--------------------------------------------------------------------------
	*/
	{
		path: '/tipoReferencia', 
		components: {
			default: TipoReferenciaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},
	{
		path: '/tipoReferencia/create', 
		components: {
			default: TipoReferenciaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},    
	{
		path: '/tipoReferencia/edit/:id', 
		components: {
			default: TipoReferenciaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},  
	{
		path: '/tipoReferencia/:id/referencias', 
		components: {
			default: TipoReferenciaReferencia,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 


 


	
	/*
	|--------------------------------------------------------------------------
	| PLANO
	|--------------------------------------------------------------------------
	*/ 
	{
		path: '/admin/plano', 
		components: {
			default: PlanoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin']}
	}, 
	{
		path: '/admin/plano/create', 
		components: {
			default: PlanoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},
	{
		path: '/admin/plano/edit/:id', 
		components: {
			default: PlanoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin']}
	}, 
	{
		path: '/admin/plano/:id/usuarios', 
		components: {
			default: PlanoUsuario,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin']}
	}, 




	
	
	/*
	|--------------------------------------------------------------------------
	| Texto
	|--------------------------------------------------------------------------
	*/
	{
		path: '/texto', 
		components: {
			default: TextoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin'] }
	},
	 
	{
		path: '/texto/edit/:id', 
		components: {
			default: TextoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin'] }
	}, 
	{
		path: '/texto/show/:id', 
		components: {
			default: TextoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},  
	{
		path: '/texto/create', 
		components: {
			default: TextoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},   
	 



	/*
	|--------------------------------------------------------------------------
	| GUIA
	|--------------------------------------------------------------------------
	*/
	{
		path: '/guia_online', 
		components: {
			default: GuiaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin'] }
	},
	 
	{
		path: '/guia_online/edit/:id', 
		components: {
			default: GuiaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin'] }
	}, 
	{
		path: '/guia_online/show/:id', 
		components: {
			default: GuiaShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},  
	{
		path: '/guia_online/create', 
		components: {
			default: GuiaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin'] }
	},   
	 

	{
		path: '/guia_online/historico', 
		components: {
			default: GuiaHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
 

	
	/*
	|--------------------------------------------------------------------------
	| RESPOSTA
	|--------------------------------------------------------------------------
	*/
	{
		path: '/resposta', 
		components: {
			default: RespostaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/resposta/edit/:id', 
		components: {
			default: RespostaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin'] }
	},



	

	/*
	|--------------------------------------------------------------------------
	| REFERENCIA
	|--------------------------------------------------------------------------
	*/
	{
		path: '/referencia',  
		components: {
			default: ReferenciaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},
	{
		path: '/referencia/create', 
		components: {
			default: ReferenciaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},    
	{
		path: '/referencia/edit/:id', 
		 components: {
			default: ReferenciaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	}, 

	{
		path: '/referencia/:id/perguntas', 
		components: {
			default: ReferenciaPergunta,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	}, 


 

 



	/*
	|--------------------------------------------------------------------------
	| ASSUNTO
	|--------------------------------------------------------------------------
	*/
	{
		path: '/assunto', 
		components: {
			default: AssuntoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},
	{
		path: '/assunto/create', 
		components: {
			default: AssuntoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},    
	{
		path: '/assunto/edit/:id', 
		 components: {
			default: AssuntoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/assunto/show/:id', 
		 components: {
			default: AssuntoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	}, 

	{
		path: '/assunto/:id/tag', 
		components: {
			default: AssuntoTag,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},   
	{
		path: '/assunto/:id/pergunta', 
		components: {
			default: AssuntoPergunta,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},   
	{
		path: '/assunto/:id/modulo', 
		components: {
			default: AssuntoModulo,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin']}
	},    
	    
	 
	

	/*
	|--------------------------------------------------------------------------
	| PERGUNTA
	|--------------------------------------------------------------------------
	*/
	{
		path: '/pergunta', 
		components: {
			default: PerguntaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin'] }
	},
	{
		path: '/pergunta/create', 
		components: {
			default: PerguntaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},    
	{
		path: '/pergunta/historico', 
		components: {
			default: PerguntaHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin'] }
	},    
	{
		path: '/pergunta/edit/:id', 
		components: {
			default: PerguntaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	}, 
	{
		path: '/pergunta/show/:id', 
		components: {
			default: PerguntaShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},   
	 


]

  
 
export default routesAdministrador
