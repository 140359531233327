/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '@/servicegerente';  
import { gerenteModel } from './index.model';  
// import moment  from 'moment'; 
import axios  from 'axios'; 
import  store   from '@/store/index' 
// import * as CryptoJS from 'crypto-js';

// const secret_key =  gerenteModel.getKeyCripto() ;
 
const url = gerenteModel.getUrl() + '/simulado' ;  



function getUrl(  ) { 
	return  url; 
}


function logout() {  
	store.dispatch('usuario/logout'); 
}

  



function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

  

export const simuladoService = {
 
	getUrl,
	 
};
