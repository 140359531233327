
























	/* eslint-disable @typescript-eslint/camelcase */  
	import TagFormAdicionarMultiple from './_TagFormAdicionarMultiple.vue'
	import TagFormAdicionar from './_TagFormAdicionar.vue'
	import TagFormImportar from './_TagFormImportar.vue'
  
	import { assuntoAdminService  }  from '@/servicegerente';
    import $ from "jquery"  
 
	import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
            'assunto-form-adicionar': TagFormAdicionar,
            'assunto-form-importar': TagFormImportar,
            'assunto-form-importar-multiple': TagFormAdicionarMultiple,
        },
    })
    export default class PerfilPermissao extends Vue {

        public assuntos: any= null; 

        public assunto: any= null; 
        
		public reloadDatatable: any= false; 

		public tags: any= false; 

		public url_retorno: any= '/assunto';

		public config: any= { 
			order: [[0, "asc" ]],
			ajax: { 
				url: assuntoAdminService.getUrl() + '/' + this.$route.params.id + '/tag/datatable'
			},
			columns: [
				{ data: 'nome', name: 'tag.nome' },
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		}; 
		

		created() { 
			window.alertProcessando();
			assuntoAdminService.getAssunto( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.assunto = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Assunto', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});   

			assuntoAdminService.getAll(   )  
			.then(response => {
				this.assuntos = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os Assuntos', error.data);
			});   
		}


		@Watch('assunto')
		perfilAlterado(newVal: any) {
			this.buscarTag();
		}


		tagAdicionado(event: any) {
			this.tags = event;
			this.reloadDatatable = !this.reloadDatatable; 
		}

		atualizarAssunto( ) {
			assuntoAdminService.getAll( true ) 
			.then(response => {
				this.assuntos = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os Assuntos', error.data);
			}); 
		}

		buscarTag() {
			window.alertProcessando();
			assuntoAdminService.buscarTag( this.$route.params.id )   
			.then(response => {
				this.tags = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os Tags para adiocionar", error.data);
				window.alertProcessandoHide();
			});   
		}



		public tableatualizada( ) {    
			
			$('[btn-excluir]').click(  (btn) => {  
				const id =   $(btn.currentTarget).data('id');  
				window.alertConfimacao('Confirma a Exclusão ','' , 
						() => {  
						window.alertProcessando(); 
						assuntoAdminService.removerTag( this.$route.params.id,  id )
						.then(response => {
							window.alertProcessandoHide();  
							this.tags = response;
							this.reloadDatatable = !this.reloadDatatable; 
							window.toastSucesso('tags removido com sucesso!!' ); 
						})
						.catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
							window.alertProcessandoHide();
							window.toastErro('Não foi possivel remover a tags' , ''); 
						});  
					}
				); 
			});
  
		}
		


	}
	 

