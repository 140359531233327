




























































































































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import { moduloService , noticiaService , manzaTinyEditorColorsMap } from '@/servicegerente';

    import Editor from '@tinymce/tinymce-vue';

    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class GuiaEdit extends Vue {

        public modulos: any = null; 
        public model: any = null;
 
        public nome: any= null;
        public descricao: any= null;
        public resumo: any= null; 
		public imagem: any= null; 
		public has_descricao: any= false; 
 
		public urlRetorno: any= '/v2/administracao/noticia/show/' + this.$route.params.id; 
		public errors: any = { };
        public colors: any = []; 
  
        created() { 
			this.colors = manzaTinyEditorColorsMap; 

			moduloService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			}); 
            window.alertProcessando();
            noticiaService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o configuração ', error.data);
                    window.alertProcessandoHide();
                     
                }); 
        }


        @Watch('model')
		tabelaAtualizada(newVal: any) { 
			this.descricao = this.model.descricao;   
			this.resumo = this.model.resumo;   
			this.imagem = this.model.imagem;   
			this.nome = this.model.nome;   
			this.has_descricao = this.model.has_descricao;  
        }
        


		salvar() {
			window.alertProcessando();
			const dados = { 
				descricao :this.descricao,  
				resumo :this.resumo,  
				imagem :this.imagem,  
				has_descricao :this.has_descricao,    
				modulo_id :this.model.modulo_id,  
				nome :this.nome,  
			};  
			 
			 
			noticiaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){  
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }



 

