




















	
    import {  comentarioService }  from '@/servicegerente';   
	import $ from "jquery"    
	import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 

    @Component({
        components: { },
    })
    export default class ComentarioIndex extends Vue { 

		private  reloadDatatable = false ; 

        @gerente.State
		public modulo!: any


        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else{
                this.config.ajax.url =  comentarioService.getUrl() + '/modulo/' + this.modulo.id + '/datatable'
			}
        }



        private config = {
			order: [[ 0, "desc" ]],
			ajax: { 
				url: comentarioService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'texto', name: 'texto' }, 
				{ data: 'pergunta_id', name: 'pergunta_id' },  
				{ data: 'status', name: 'status' },  
				{ data: 'usuario.nome', name: 'usuario.nome' },  
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};


		public tableatualizada( ) {   
             
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
				window.open('#/v2/administracao/comentario/edit/' + id ); 
                // this.$router.push('/v2/administracao/comentario/edit/' + id  );
			});
			
			  
  

		}
		

	}
	 

