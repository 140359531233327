






































































	import Logo from './_Logo.vue'
	
	import UserMenu from './_User.vue'
	
	 
    import { namespace } from 'vuex-class'
    import { Component, Vue } from 'vue-property-decorator';
    import $ from "jquery" 

    const usuario = namespace('usuario')
    const gerente = namespace('gerente')

    @Component({
        components: {
			 
			'logo': Logo,
            'user-menu': UserMenu,
        },
    })
    export default class MenuLateral extends Vue {
        
		@gerente.State
		public modulo!: any

		@gerente.State
		public assunto!: any
		
       	@usuario.State
		public user!: any


		@usuario.State
		public perfis!: any


		@usuario.State
		public status!: any

		@usuario.Action
      	public logout!: ( ) => void

		get loggingIn(): boolean {
			if (this.status) {
				return  this.status.loggedIn ;
			}
			return false;
		}


		get usuario(): string {
			if (this.user) {
				return  this.user.apelido ;
			}
			return '';
		}
		
		
		get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}


		
		
		get coordenador(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Coordenador") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}
		  
		  
		  

		get gerente(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Gerente") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}


	 


    }

 

