








































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import {   controleService  } from '@/servicegerente';  
    import { Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class MsnTodosusuarios extends Vue {
 
		public modulo_id: any= null; 
		public disciplina_id: any = 0;   
		public urlRetorno: any= '/controle';  
  
        salvar() {
             
            window.alertProcessando();
            
            const dados = {
                disciplina_id :this.disciplina_id, 
                modulo_id :this.modulo_id,  
            };   
            controleService.atualizacaoCardCacheJob( dados )
            .then(response => {
                window.toastSucesso(response);  
                window.alertProcessandoHide(); 
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
            });  
             
        } 
          
    }
    
 
