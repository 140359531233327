

















	import ModuloFormAdicionar from './_ModuloFormAdicionar.vue'
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
    import $ from "jquery"  
	import { moduloService , userService  }  from '@/servicegerente';


	@Component({
		components: { 
			'modulo-form-adicionar': ModuloFormAdicionar, 
        },
	 })
    export default class UsuarioPlano extends Vue {


		private usuario: any= null; 
		private modulos: any= null; 
		private reloadDatatable: any= false; 
  
		private config: any= { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: userService.getUrl() + '/' + this.$route.params.id + '/modulo/datatable'
			},
			columns: [
				{ data: 'modulo_id', name: 'usuario_modulo.modulo_id'  },
				{ data: 'nome', name: 'modulo.nome' },  
				{ data: 'id_responsavel', name: 'id_responsavel' },  
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  

		created() { 
			window.alertProcessando();
			userService.getUsuario( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.usuario = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Usuário', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}


		@Watch('usuario')
		usuarioAlterado(newVal: any) {
			this.buscarModulos();
		}


		 
		moduloAdicionado(event: any) {
			this.modulos = event; 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		moduloAlterado(event: any) { 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		buscarModulos() {
			window.alertProcessando();
			userService.buscarModulos( this.$route.params.id )   
			// moduloService.getAll(  )   
			.then(response => {
				this.modulos = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os modulos para adiocionar", "");
				window.alertProcessandoHide();
			});   
		}


		public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        userService.removerModulo( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
							this.modulos = response;
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('modulo removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover o modulo' , ''); 
                        });  
                    }
                ); 
            });

		}
		  
	}

