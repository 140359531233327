




















































































/* eslint-disable @typescript-eslint/camelcase */ 
 
	import { tagService, perguntaService  , cardService}  from '@/servicegerente'; 
	import $ from "jquery"   
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 

    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        public url_retorno: any = '/v2/administracao/conceito' + '/show/' + this.$route.params.id ;
        public tag: any = null ;
        public identificador: any = 1 ;
        public  reloadDatatable = false ; 
        public  iscardoff = true ; 
        public  isassuntooff = true ; 

		public perguntaKey = 0;

		@gerente.State
		public card!: any

		@gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public assunto!: any
 
        @gerente.Action
        public resetDisciplina!: (   ) => void

		@gerente.Action
        public resetAssunto!: (   ) => void

		@gerente.Action
        public resetCard!: (   ) => void

        public config = { 
			order: [[ 7, "desc" ] , [ 8, "asc" ], [ 3, "desc" ]  ],
			lengthMenu : [ [    12, 15, 20,  40, 50, 70, 100, 150, -1],   [   12, 15,  20,   40, 50, 70, 100, 150, "Todos"]  ],
			ajax: { 
				url: tagService.getUrl() + '/' + this.$route.params.id + '/perguntas/datatable'
			},
			columns: [
				{ data: 'id', name: 'id' }, 
				{ data: 'texto_clean', name: 'texto_clean', orderable: false  },  
				{ data: 'correta', name: 'correta' , orderable: false, searchable: false },  
				{ data: 'ano', name: 'ano' }, 
				{ data: 'videoaula', name: 'pergunta.videoaula' },
				{ data: 'status', name: 'pergunta.status' },
				{ data: 'cards_count', name: 'cards_count', class: 'text-center',  searchable: false, },  
				{ data: 'rendimento', name: 'rendimento', class: 'text-center' },
				{ data: 'tags_count', name: 'tags_count', class: 'text-center',    searchable: false, }, 
				{ data: 'baterias_count', name: 'baterias_count' ,    searchable: false },  
				{ data: 'score_dificuldade', name: 'pergunta.score_dificuldade', class: 'text-center' },  
				{ data: 'banca', name: 'pergunta.banca', class: 'text-center' },  
				{ data: 'banca_id', name: 'pergunta.banca_id', class: 'text-center' },  
				{ data: 'orgao', name: 'pergunta.orgao', class: 'text-center' },  
				{ data: 'id', name: 'id', class: 'text-center', orderable: false, searchable: false, },  
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center' }
			],
			columnDefs: [ 
                {
                    targets: 14,
                    render: ( row: any, type: any, val: any, meta: any ) => {  
                        let texto = '' ; 
                        let buttom = '' ;  
                        for (const key in val) { 
                            if(key != 'action' && key != 'descricao'){
                                buttom = buttom + ' data-' + key + '="'+ val[key] +'"'  
                            } 
                        }  
                        texto =  texto + '<button title="Add Ementa" class="btn btn-warning btn-sm" btn-add-card '+ buttom +' "><i class="fa fa-plus"></i> Card </button>' ;   
                        return texto;  
                    }, 
                } 
            ], 
		};

		@Watch('iscardoff')
		public rota( ) {   
            this.config.ajax.url  =  this.iscardoff || !this.card ? tagService.getUrl() + '/' + this.$route.params.id + '/perguntas/datatable' : tagService.getUrl() + '/' + this.$route.params.id + '/perguntas/card/' + this.card.id  + '/datatable'  ; 
			this.perguntaKey += 1;
		}

		@Watch('isassuntooff')
		public rotaAssunto( ) {   
            this.config.ajax.url  =  this.isassuntooff || !this.assunto ? tagService.getUrl() + '/' + this.$route.params.id + '/perguntas/datatable' : tagService.getUrl() + '/' + this.$route.params.id + '/perguntas/assunto/' + this.assunto.id  + '/datatable'  ; 
			this.perguntaKey += 1;
		}

 

		created() { 
			window.alertProcessando();
			tagService.getTag( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.tag = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o tag', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}

		
		public padrao( ) {   
            this.config.order =   [[ 7, "desc" ] , [ 8, "asc" ], [ 3, "desc" ]  ];
            this.config.lengthMenu = [ [    12, 15, 20,  40, 50, 70, 100, 150, -1],   [   12, 15,  20,   40, 50, 70, 100, 150, "Todos"]  ];
			this.identificador = 1 ;
		}

		public duplicadas( ) {   
            this.config.order =  [[ 12, "asc" ] , [ 11, "asc" ], [ 13, "asc" ], [ 3, "desc" ]  ];
			this.config.lengthMenu = [ [   50,  5, 10, 20,  40 ,70, 100, 150, -1 ],   [   50,  5, 10, 20,  40, 70, 100, 150, "Todos"  ]  ];
			this.identificador = 2 ;
		}

		
        public adicionarCard( id: any ) {
            if (id  && this.card ) { 
                window.alertProcessando();
                const dados = {
                    fonte_id :this.card.id,   
                    pergunta_id : id , 
                };    
                cardService.storeCardPergunta( dados )
                .then(response => {
                    window.toastSucesso("card adicionado com successo.");
                    window.alertProcessandoHide(); 
                    this.reloadDatatable = !this.reloadDatatable; 
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500); 
            }
        }  


		public tableatualizada( ) {   

			$('[btn-add-card]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.adicionarCard(id);
            });


            $('[btn-visualizar]').click(  (btn) => {  
				const id =   $(btn.currentTarget).data('id');  
				window.open('#/v2/administracao/pergunta/show/' + id); 
            }); 

			$('[btn-excluir]').click(  (btn) => {  
				const id =   $(btn.currentTarget).data('id');   
				window.alertConfimacao('Confirma a Exclusão da pergunta ','' , 
					() => {  
						this.finalizarlight( id  ) ;
                    }
                ); 
            });
		}

		
        finalizarlight( id: any ) {  
            window.alertProcessando(); 
            perguntaService.finalizarlight(  id )
            .then(response => {
                window.alertProcessandoHide();   
				this.reloadDatatable = !this.reloadDatatable; 
                window.toastSucesso('Exclusão realizada com sucesso!!' ); 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel Exclusão ' , error.data.message); 
            });   
        }


	}

 
