






















	
	import { tentativaService  }  from '@/servicegerente';
 
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class TentativaIndex extends Vue { 

        private config = { 
			order: [[ 5, "desc" ]],
			ajax: { 
				url:  tentativaService.getUrl() + '/datatable'
			},
			columns: [ 
			{ data: 'usuario.nome', name: 'usuario.nome' }, 
			{ data: 'disciplina.nome', name: 'disciplina.nome' }, 
			{ data: 'pergunta_id', name: 'pergunta_id' }, 
			{ data: 'resposta_id', name: 'resposta_id' }, 
			{ data: 'acerto', name: 'acerto' }, 
			{ data: 'created_at', name: 'created_at' },  
			{ data: 'modulo_id', name: 'modulo_id' }, 
			{ data: 'tipo', name: 'tipo' }, 

			],
		} ;
 
	}
	  
