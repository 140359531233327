





























	/* eslint-disable @typescript-eslint/camelcase */ 

	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { moduloAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormModuloDisciplinasAssuntos extends Vue { 
        @Prop( ) readonly assuntos!: any 
        @Prop( ) readonly disciplina!: any 
        @Prop( ) readonly modulo!: any 
        public assunto: any = null;  
        public add_assunto_id = 'modal-seguranca-modulo-adicionar-assunto-card'; 
 
        AdicionarPermissao() {
            if (this.assunto   ) {

                window.alertProcessando();
                const dados = {
                    assunto :this.assunto,  
                };   

                moduloAdminService.adicionarAssuntoCard( this.modulo.id ,  dados )
                .then(response => {
                    window.toastSucesso("assunto adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response); 
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }
 
 
