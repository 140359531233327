
















































    /* eslint-disable @typescript-eslint/camelcase */  
    import {  respostaService , manzaTinyEditorColorsMap } from "@/servicegerente"; 
    import $ from "jquery"   
    import Editor from "@tinymce/tinymce-vue"; 
    import { Prop , Watch, Component, Vue } from 'vue-property-decorator'; 
      
    @Component({
        components: {
            editor: Editor, 
         },
    })
    export default class FormEditarResposta extends Vue {  
        @Prop( ) readonly resposta!: any  
        public texto: any  = null;  
        public add_tag_id: any  = null;  
        public isvisivel = false; 
        public colors: any = []; 
 
        created() {
            this.add_tag_id = 'modal-seguranca-pergunta-editar-respostas-' +  this.resposta.id;
            this.texto = this.resposta.texto;
			this.colors = manzaTinyEditorColorsMap; 
        }

        acessou(){
            this.texto = this.resposta.texto; 
			this.isvisivel = true;
        }
 
        salvar() {
            if(this.texto){
                const data: any = {}; 
                data["texto"] = this.texto; 

                window.alertProcessando();
                respostaService.editarResposta(this.resposta.id , data)
                    .then(response => {
                        window.toastSucesso(response);
                        // this.texto = ""; 
                        this.$emit('respostaAlterada', response); 
                        ( $('#' + this.add_tag_id) as any ) .modal('toggle');
                        window.alertProcessandoHide(); 
                    })
                    .catch(error => {
                        console.log(error);
                        window.alertProcessandoHide();
                    }); 
            }
            
        }
 

    }

 
        

