




























 

	import FormModuloDisciplinasAssuntos from './_AssuntoFormAdicionar.vue' 

	import { moduloAdminService  , disciplinaAdminService    }  from '@/servicegerente';
 
	import $ from "jquery"  

    import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
            'formAddAssuntoToModulo':  FormModuloDisciplinasAssuntos,
        },
    })
    export default class ModuloDisciplinasAssuntos extends Vue {

        public disciplina: any= null;  
        public modulo: any= null;  
        public assuntos: any= null; 
        public reloadDatatable: any= false; 
		   
		public url_retorno: any=  '/modulo/'  + this.$route.params.id + '/disciplina';  
		  
		public config: any= {  
            order: [[ 1, "asc" ]],
			ajax: { 
				url: moduloAdminService.getUrl() + '/' + this.$route.params.id + '/disciplina/' + this.$route.params.disciplinaId + '/assunto/datatable'
			},
			columns: [
			{ data: 'nome', name: 'assunto.nome' },
			{ data: 'descricao', name: 'assunto.descricao' }, 
			{ data: 'perguntas_validas_count', name: 'perguntas_validas_count' }, 
			{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  
		 
        created() {
			window.alertProcessando(); 
			disciplinaAdminService.getDisciplina( this.$route.params.disciplinaId )  
			.then(response => { 
				this.disciplina = response;
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar o Disciplina', error.data);
			}); 

			moduloAdminService.getModulo( this.$route.params.id )  
			.then(response => {
				 window.alertProcessandoHide();
				this.modulo = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Modulo', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
        }
 
		assuntoAdicionado(event: any) {
			this.assuntos = event;
			this.reloadDatatable = !this.reloadDatatable; 
		}

		buscarAssunto() {
			window.alertProcessando();
			moduloAdminService.buscarAssunto( this.$route.params.id , this.$route.params.disciplinaId )   
			.then(response => {
				this.assuntos = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os Assuntos para adiocionar", error.data);
				window.alertProcessandoHide();
			});   
		}

		  

        @Watch('modulo')
		perfilAlterado(newVal: any) {
			this.buscarAssunto();
		}



        public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        moduloAdminService.removerAssunto( this.$route.params.id , this.$route.params.disciplinaId,  id )
                        .then(response => {
							window.alertProcessandoHide();  
							this.assuntos = response;
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Disciplina removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover a Disciplina' , ''); 
                        });  
                    }
                ); 
            }); 
        }


		

        AdicionarTodosAssuntos() { 
			window.alertProcessando(); 
			moduloAdminService.adicionarTodosAssunto( this.$route.params.id ,  this.$route.params.disciplinaId , this.$route.params.pivotId )
			.then(response => {
				window.toastSucesso("assuntos adicionado com successo.");
				this.assuntos = response;
				this.reloadDatatable = !this.reloadDatatable;
				window.alertProcessandoHide(); 
			})
			.catch(error => { 
				window.toastErro(error.message);
				window.alertProcessandoHide();  
				console.log(error);
			});    
        } 


        removerTodosAssuntos() { 
			window.alertConfimacao('Confirma a Exclusão ','' , 
				() => {  
					window.alertProcessando(); 
					moduloAdminService.removerTodosAssuntos( this.$route.params.id ,  this.$route.params.disciplinaId , this.$route.params.pivotId )
					.then(response => {
						window.toastSucesso("assuntos removidos com successo.");
						this.assuntos = response;
						this.reloadDatatable = !this.reloadDatatable;
						window.alertProcessandoHide(); 
					})
					.catch(error => { 
						window.toastErro(error.message);
						window.alertProcessandoHide();  
						console.log(error);
					});  
				}
			);  
        } 


	}

  

