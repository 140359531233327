




























	
	import { assuntoService, assuntoAdminService }  from '@/servicegerente';
	
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        public model: any = null;
         
		created() {  
			window.alertProcessando();
			assuntoService.getAssunto( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide(); 
				window.toastErro('Não foi possivel achar Assunto');
			}); 
		}


		     
        public liberarQuestoes( ) {  
            window.alertConfimacao('Confirma a liberarQuestoes ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoService.responderApi( this.$route.params.id , {} )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('liberarQuestoes realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel liberarQuestoes ' , error.data); 
                    });  
                }
            ); 
        }

		     
        public subirQuestoes( ) {  
            window.alertConfimacao('Confirma a subirQuestoes ','' , 
                () => {  
                    window.alertProcessando(); 
                    assuntoAdminService.subirQuestoes(   this.$route.params.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('subirQuestoes realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel subirQuestoes ' , error.data); 
                    });  
                }
            ); 
        }
             


	}
	 
