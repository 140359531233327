



















	
	import { userService  }  from '@/servicegerente';

	import { Component, Vue } from 'vue-property-decorator';

	@Component({
        components: { 
        },
    })
    export default class UsuarioHistoricoPlano extends Vue {

		private config = { 
            order: [[ 2, "desc" ]],
			ajax: { 
				url: userService.getUrl() + '/' + this.$route.params.id + '/plano/log/datatable'
			},
			columns: [ 
				// { data: 'autor.nome', name: 'autor.nome'  },
				{ data: 'acao', name: 'usuario_perfil_log.acao'  },
				{ data: 'plano_id', name: 'plano_id'  }, 
				{ data: 'created_at', name: 'created_at'  }, 
			],
        };

		private  url_retorno = '/usuario/' + this.$route.params.id + '/plano' ; 

	}

 
	
