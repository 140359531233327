







	import select2 from 'select2';
	import $ from "jquery"  
	import {Emit, Prop, Watch, Component, Vue } from 'vue-property-decorator'; 

	@Component({
		components: { 
        },
	 })
    export default class MultiSelect2 extends Vue {
 
		@Prop( ) readonly options!: any 
		@Prop( ) readonly propriedade!: any 
		public elselect2: any = null;
		  
		@Watch('value')
		valueAtualizado(value: any) {
			if ([...value].sort().join(",") !== [...$(this.$el).val()].sort().join(","))
			 $(this.$el).val(value).trigger('change')
		}
 
		@Watch('options')
		optionsAtualizad0(options: any) {
			$(this.$el).empty().select2({ data: options }) 
		}
 
		mounted(){
			for (const prop in this.propriedade) { 
            	this.$refs.input.setAttribute(  prop  , this.propriedade[prop]  ); 
			} 
			this.elselect2 = $(this.$el) 
			.select2({ 
				data: this.options , 
				closeOnSelect: false  
			})   
			.trigger('change') 
			.on('change',   (event) => {   
				this.changeValor( ) 
			})
		}

		@Emit('input')
		changeValor( ) { 
			this.$emit('value-alterado-multi-select', this.elselect2.val()); 
		}
		
		destroyed() { 
			$(this.$el).off().select2('destroy')
		}

	}
	 

