import { render, staticRenderFns } from "./Visualizacao.vue?vue&type=template&id=ca1975f4&scoped=true"
import script from "./Visualizacao.vue?vue&type=script&lang=ts"
export * from "./Visualizacao.vue?vue&type=script&lang=ts"
import style0 from "./Visualizacao.vue?vue&type=style&index=0&id=ca1975f4&prod&scoped=true&media=print&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca1975f4",
  null
  
)

export default component.exports