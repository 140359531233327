





























































/* eslint-disable @typescript-eslint/camelcase */ 


import $ from "jquery"  
import { resumoAdminService  }  from '@/servicegerente';
import { Component, Vue } from 'vue-property-decorator';
  
@Component({
    components: { },
})
export default class PerguntaHistorico extends Vue { 

    private  reloadDatatable = false ; 
    private  modal_adicionar_id: any = 'modal-config-resumo-acesso-dados' ; 
    private  dado: any = '' ; 

    private config = {
        order: [[ 0, "desc" ]],
        ajax: { 
            url: resumoAdminService.getUrl() + '/historico/datatable'
        },
        columns: [
            { data: 'id', name: 'id'  }, 
            { data: 'operacao', name: 'operacao'  },  
            { data: 'usuario_id', name: 'usuario_id'  },  
            { data: 'host', name: 'host'  },  
            { data: 'ip_v4', name: 'ip_v4'  },  
            { data: 'created_at', name: 'created_at'  },  
            { data: 'id_model', name: 'id_model'  },  
            { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
        ],
    };



    public tableatualizada( dados: any ) {  
         
        $('[btn-visualizar-dados]').click(  (btn) => {  
            const dados =  $(btn.currentTarget).data('dados'); 
            this.dado = dados;  
            $('#' + this.modal_adicionar_id).modal('show'); 
            
        });

    }

}



