













































































































    import {   tagService  , manzaTinyEditorColorsMap  } from '@/servicegerente'; 
	import $ from "jquery"   
    import {Watch, Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
    import Editor from '@tinymce/tinymce-vue';
      
    @Component({
        components: { 'editor': Editor },
    })
    export default class Principal extends Vue { 

        @usuario.Getter
		public isAdmin!:  (  ) => boolean

        @gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public assunto!: any
 
        @gerente.State
		public conceito!: any
 
        public limparConceito( ) {  
            this.resetConceito(); 
        }
 
        public colors: any = []; 
        public isvisivel = false; 

        public tags: any = null;
        public tagsConteudo: any = null;

        @gerente.Action
        public resetConceito!: (   ) => void
 
        @gerente.Action
        public alterarConceito!: ( conceito: any  ) => void

        
        acessou(){ 
			this.isvisivel = true;
        }
         
        public alterarConceitoLocal( conceito: any  ) { 
            this.alterarConceito(conceito);
        }


        @Watch('tags')
		tagsAtualizada(newVal: any) {
            this.tagsConteudo = '';
            this.tags.forEach(element => {
                this.tagsConteudo +=  element.descricao;
            });
        }
        
        public editar( id: any) {    
            window.open('#/v2/administracao/conceito/edit/' + id);  
        }
        
        public viewPerguntas( id: any) {   
            window.open('#/v2/administracao/conceito/' +   id + '/perguntas');  
        }

        public atualizaUrl( ) {   
            tagService.getTodosModuloDisciplinaAssunto( this.modulo.id , this.disciplina.id , this.assunto.id  )
			.then(response => {
				this.tags = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide(); 
				window.toastErro('Não foi possivel achar Tag');
			});  
        }


        created() {
            if(!this.modulo   ){
				this.$router.push('/v2/administracao'); 
			}
            else if( !this.disciplina   ){
				this.$router.push('/v2/administracao/disciplina'); 
			}
            else if(   !this.assunto ){
				this.$router.push('/v2/administracao/assunto'); 
			}
            else {
				this.atualizaUrl( );
				this.colors = manzaTinyEditorColorsMap; 
            } 
        }

 
 
    }
 
