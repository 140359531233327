


















/* eslint-disable @typescript-eslint/camelcase */ 

	import { assuntoAdminService  }  from '@/servicegerente';


	import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class AssuntoModulo extends Vue {

		private  assunto: any = null ; 
		private  urlRetorno: any = '/assunto' ; 

		private config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url: assuntoAdminService.getUrl() + '/' + this.$route.params.id + '/modulo/datatable'
			},
			columns: [
				{ data: 'nome', name: 'modulo.nome' },
				{ data: 'descricao', name: 'modulo.descricao' }, 
				{ data: 'id_responsavel', name: 'id_responsavel' }, 
			],
		};

		created() { 
			window.alertProcessando();
			assuntoAdminService.getAssunto( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.assunto = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Assunto', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.urlRetorno);
			});  
 
		}


	}
 

