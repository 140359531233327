



















import Header from '@/components/layout/Header.vue'
import BarraLateralAdmin from '@/components/layout/BarraLateralAdmin.vue'
import Config from '@/components/layout/Config.vue'
import Footer from '@/components/layout/Footer.vue'

import { Component, Vue   } from 'vue-property-decorator';

import { namespace } from 'vuex-class' 
const ambiente = namespace('ambiente')

@Component({
    components: {
        Header,
        BarraLateralAdmin,
        Config,
        Footer,
    },
})

export default class App extends Vue { 
    @ambiente.State
        public tema!: any 

}


