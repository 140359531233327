/* eslint-disable @typescript-eslint/camelcase */  
import { authHeader } from '..'; 
import { administradorModel } from './index.model';  
import axios  from 'axios';  
import  store   from '@/store/index' 


const url = administradorModel.getUrl() + '/comentario' ;


function logout() {  
	store.dispatch('usuario/logout'); 
}


function getUrl(  ) { 
	return  url; 
}



function getComentario( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
  


function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {
			if ( error.response.status === 401) {
				logout();				
			} 
			reject(error.response);
		}) 
	}); 
}


function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


export const comentarioAdminService = { 
	getUrl, 
	getComentario, 
	excluir,
	desativar,
	ativar,
	update, 
};