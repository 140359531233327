































































	/* eslint-disable @typescript-eslint/camelcase */  
    import { moduloService , bateriaService  } from '@/servicegerente';  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	   
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
 
	@Component({
		components: {  },
	 })
    export default class GuiaCadastro extends Vue {
 
		@gerente.State
		public modulo!: any
 
        private modulos: any = null;
        
		private nome: any= null;  
		private modulo_id: any= null; 
		private nivel: any= null;  
		private ativo: any= false;
         
		private urlRetorno: any= '/v2/administracao/bateria'; 
		private errors: any = { };
	 
		created() {  
			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else{ 
				this.modulo_id = this.modulo.id ; 
				moduloService.getAll() 
				.then(response => {
					this.modulos = response ;
				})
				.catch(error => {
					window.toastErro('Não foi possivel achar as modulos');
				}); 
			} 
		}
 
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,  
				modulo_id :this.modulo_id,  
				nivel :this.nivel,     
				ativo :this.ativo, 

			};   
			bateriaService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
   
    } 
