
































































































































































































































































 
	/* eslint-disable @typescript-eslint/camelcase */ 
  	/* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
 

	import {  referenciaService  , manzaTinyEditorColorsMap }  from '@/servicegerente'; 
	import {   moduloService,  perguntaService   }  from '@/servicegerente'; 
	import Editor from '@tinymce/tinymce-vue' 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
    import ImagemFormAdicionar from './_AdicionarImagem.vue'; 



	@Component({
		components: { 
			'editor': Editor,
			'imagem-form-adicionar': ImagemFormAdicionar
        },
	 })
    export default class PlanoCadastro extends Vue {


		@gerente.State
		public modulo!: any


		@gerente.State
		public disciplina!: any


		@gerente.State
		public assunto!: any

        public colors: any = []; 


		public assuntos: any = null;
		public disciplinas: any = null;
		public referencias: any = null;
		public disciplina_id: any = null;
 

		public verdadeiro_falso: any= false; 
		public texto: any= null; 
		public resumo: any= null; 
		public assunto_id: any= null; 
		public referencia_id: any= 8; 
		public referencia_questao_nr: any= null;  
		public score_dificuldade: any= '1';  
		public status: any= 'Criada'; 


		public respostas: any= ''; 
 

		public urlRetorno: any= '/v2/administracao/pergunta';

		public errors: any = { };

		public tamanhoTexto: any= 210; 
		public keyTexto: any= 1; 



		@Watch('disciplina_id')
		disciplinaAlterada(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = null ;
			this.assunto_id = null ; 
		}


		created() {  
			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}

			if(this.disciplina){
				this.disciplina_id = this.disciplina.id 
			}
			 
			if(this.assunto){
				this.assunto_id = this.assunto.id 
			}
			
			this.colors = manzaTinyEditorColorsMap; 

			moduloService.buscarTodasDisciplina( this.modulo.id ) 
			.then(response => {
				this.disciplinas = response ;
				if(this.disciplina_id){
					this.buscarAssunto(this.disciplina_id);
				}
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			}); 

			referenciaService.getAll() 
			.then(response => {
				this.referencias = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as referencias');
			}); 
		}



		salvar() {
			const _temp = this.respostas.split('\n').filter( resp => resp && resp.replaceAll(' ' , '') != '' );
			
			if( _temp.length > 5 ){
				window.toastErro('A pergunta contém mais de 5 respostas');
				console.log( _temp );
				return;
			}
			
			window.alertProcessando();
			const dados = {
				verdadeiro_falso :this.verdadeiro_falso, 
				texto :this.texto, 
				resumo :this.resumo, 
				assunto_id :this.assunto_id, 
				referencia_id :this.referencia_id, 
				referencia_questao_nr :this.referencia_questao_nr, 
				score_dificuldade :this.score_dificuldade, 
				 
				status :this.status, 
				respostas :this.respostas, 
			};  
			 
			perguntaService.cadastrar(   dados )
			.then( ( response: any ) => {
				window.toastSucesso(response.message);  
				window.alertProcessandoHide();
				this.$router.push( '/v2/administracao/pergunta/show/' + response.model.id ) 
			})
			.catch(error => {  
				window.alertProcessandoHide();
				// this.errors = error.data.error; 
				console.log(error);
			});  
		} 

		
		setPerguntaIncorreta( ) { 
			this.texto = '<p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">assinale a alternativa <strong>INCORRETA</strong>:</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "";
			this.tamanhoTexto = 210;
			this.keyTexto++;
		}
		
		
		setPerguntaVF( ) { 
			this.texto = '<p style="text-align: justify;"><strong><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Analise as afirmativas abaixo e d&ecirc; valores de Verdadeiro (V) ou Falso (F).</span></strong></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "V - V - V\nF - F - F\nV - F - V\nF - V - F\nV - F - F";
			this.tamanhoTexto = 290;
			this.keyTexto++;
		}
		
		setPerguntaVF4( ) { 
			this.texto = '<p style="text-align: justify;"><span style="font-size: 14pt;"><strong><span style="font-family: georgia, palatino, serif;">Analise as afirmativas abaixo e d&ecirc; valores de Verdadeiro (V) ou Falso (F).</span></strong></span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "V - V - V - V\nF - F - F - F\nV - F - V - F\nF - V - F - V\nV - V - F - F";
			this.tamanhoTexto = 330;
			this.keyTexto++;
		}
		
		setPerguntaQuantidade( ) { 
			this.texto = '<p style="text-align: justify;"><span style="font-size: 14pt;"><strong><span style="font-family: georgia, palatino, serif;">Analise as afirmativas a seguir:</span></strong></span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">I.&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">II.&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">III. </span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">IV. </span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Quantas afirmativas est&atilde;o <strong>CORRETAS</strong>?</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "0.\n1.\n2.\n3.\n4.";
			this.tamanhoTexto = 360;
			this.keyTexto++;
		}

	 
		
		setPerguntaRelacionar4( ) { 
			this.texto = '<p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Relacione os itens da coluna 1 &agrave;s defini&ccedil;&otilde;es apresentadas na coluna 2:</span></p><p style="text-align: justify;"><strong><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Coluna 1</span></strong></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">I.&nbsp;&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">II.&nbsp;&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">III.&nbsp; &nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">IV.&nbsp; &nbsp;</span></p><p style="text-align: justify;"><strong><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Coluna 2</span></strong></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;&nbsp;</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "I - II - III - IV \nI - III - IV - II \nII - IV - I - III \nIV - II - III - I \nIV - III - II - I";
			this.tamanhoTexto = 550;
			this.keyTexto++;
		}

	 
		
		setPerguntaRelacionar( ) { 
			this.texto = '<p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Relacione os itens da coluna 1 &agrave;s defini&ccedil;&otilde;es apresentadas na coluna 2:</span></p><p style="text-align: justify;"><strong><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Coluna 1</span></strong></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">I.&nbsp;&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">II.&nbsp;&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">III.&nbsp; &nbsp;</span></p><p style="text-align: justify;"><strong><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Coluna 2</span></strong></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">(&emsp;)&nbsp;&nbsp;</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "I - II - III \nI - III - II \nII - I - III \nII - III - I \nIII - II - I";
			this.tamanhoTexto = 490;
			this.keyTexto++;
		}

		
		
		setPerguntaPartes( ) { 
			this.texto = '<p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Analise as informa&ccedil;&otilde;es a seguir:</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">I.&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">II.&nbsp;</span></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Marque a alternativa <strong>CORRETA</strong>:</span></p>';
			this.verdadeiro_falso = false;
			this.respostas = "As duas afirmativas são verdadeiras.\nA afirmativa I é verdadeira, e a II é falsa.\nA afirmativa II é verdadeira, e a I é falsa.\nAs duas afirmativas são falsas.";
			this.tamanhoTexto = 315;
			this.keyTexto++;
		}

		
		
		setPerguntaCertoErrado( ) { 
			 this.texto = '<p style="text-align: justify;"><strong><span style="font-family: georgia, palatino, serif; font-size: 14pt;">Analise a afirmativa abaixo:</span></strong></p><p style="text-align: justify;"><span style="font-family: georgia, palatino, serif; font-size: 14pt;"><em>&nbsp;</em></span></p>';
			 this.verdadeiro_falso = true;
			 this.respostas = "";
			 this.tamanhoTexto = 250;
			this.keyTexto++;
		}

	 

		buscarAssunto( id: any) { 
			if(this.disciplinas){
				const filtrada = this.disciplinas.filter( ( disciplina: any) => { 
					if (disciplina.id == id) {
						return true;
					}
				})[0]; 
				if(filtrada){
					this.assuntos = filtrada.assuntos ;
				} 
			}
			
		}

	 


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		




	}


 

