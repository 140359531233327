











































































































 
	 
	/* eslint-disable @typescript-eslint/camelcase */ 
	/* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
 

	import {  audioAulaService , moduloService   }  from '@/servicegerente'; 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  

	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 


	@Component({
		components: { 
		 
        },
	 })
    export default class AudioCadastro extends Vue {


		@gerente.State
		public modulo!: any


		@gerente.State
		public disciplina!: any


		@gerente.State
		public assunto!: any



        private modulos: any = null;
        private disciplinas: any = null; 
        private assuntos: any = null; 
         
		private titulo: any = null; 
		private tipo: any = 2; 
		private disciplina_restrito: any= "1";
		private restrito: any= "1";  
		private modulo_id: any= null; 
		private assunto_id: any= null; 
		private disciplina_id: any= null; 
		private pdf: any= null; 

	  

		private urlRetorno: any= '/v2/administracao/audio';

		private errors: any = { };

        created() { 

			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}

			if(this.disciplina){
				this.disciplina_id = this.disciplina.id 
			}
			 
			if(this.assunto){
				this.assunto_id = this.assunto.id 
			}


			this.modulo_id = this.modulo.id ;
			  
			moduloService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			});

			moduloService.buscarTodasDisciplina( this.modulo.id ) 
			.then(response => {
				this.disciplinas = response ;
				if(this.disciplina_id){
					this.buscarAssunto(this.disciplina_id);
				}
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			}); 
 
		 
		}

		@Watch('disciplina_id')
		disciplinaAtualizada(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = [];
			this.assunto_id = null ; 
		}



		buscarAssunto( id: any) { 
			if(this.disciplinas){
				const filtrada = this.disciplinas.filter( ( disciplina: any) => { 
					if (disciplina.id == id) {
						return true;
					}
				})[0]; 
				if(filtrada){
					this.assuntos = filtrada.assuntos ;
				} 
			} 
		}
 

		 


		salvar() {
            window.alertProcessando(); 
            const dados = new FormData(); 
			dados.append('file_pdf',this.pdf ); 
			dados.set('titulo', this.titulo); 
			dados.set('tipo', this.tipo); 
			dados.set('modulo_id', this.modulo_id);
			dados.set('assunto_id', this.assunto_id);
			dados.set('disciplina_id', this.disciplina_id);
			dados.set('disciplina_restrito', this.disciplina_restrito);
			dados.set('restrito', this.restrito);
 
			audioAulaService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response.message);  
				window.alertProcessandoHide();
			})
			.catch(error => {  
				window.alertProcessandoHide();
				window.toastErro(error.data.message);
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


		handleFileUpload(){
			this.pdf = this.$refs.pdf.files[0];
		}
 
	}
	
   
