import { render, staticRenderFns } from "./_PerfilFormAdicionar.vue?vue&type=template&id=0ea8de2b&scoped=true"
import script from "./_PerfilFormAdicionar.vue?vue&type=script&lang=ts"
export * from "./_PerfilFormAdicionar.vue?vue&type=script&lang=ts"
import style0 from "./_PerfilFormAdicionar.vue?vue&type=style&index=0&id=0ea8de2b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea8de2b",
  null
  
)

export default component.exports