










































    /* eslint-disable @typescript-eslint/camelcase */  
    import {  bateriaQuestaoService , moduloService } from "@/servicegerente"; 
    import $ from "jquery"    
    import { Prop , Watch, Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class FormEditarResposta extends Vue { 
        
        @Prop( ) readonly bateriaquestao!: any 
        @Prop( ) readonly modulo_id!: any  
        @Prop( ) readonly modal!: any  

		private disciplinas: any = null;

		private nivel: any = null;
		private pergunta_id: any = null;
		private disciplina_id: any = null; 
  
        private errors: any = { };
        private texto: any  = null; 
          
		created() {  
			moduloService.buscarTodasDisciplina( this.modulo_id ) 
			.then(response => {
				this.disciplinas = response ; 
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			});  
		}
        
        @Watch('bateriaquestao')
		modeloAtualizada(newVal: string) { 
            if(this.bateriaquestao != null ){ 
                this.nivel = this.bateriaquestao.nivel;
                this.pergunta_id = this.bateriaquestao.pergunta_id;
                this.disciplina_id = this.bateriaquestao.disciplina_id;
                this.bateria_id = this.bateriaquestao.bateria_id;
            } 
		}
         
        salvar() {
            if(this.disciplina_id && this.nivel){
               const dados = {
                    bateria_id: this.bateria_id, 
                    disciplina_id: this.disciplina_id, 
                    nivel: this.nivel, 
                    pergunta_id: this.pergunta_id, 
                };  
                window.alertProcessando();
                bateriaQuestaoService.update(this.bateriaquestao.id , dados)
                    .then(response => {
                        window.toastSucesso(response); 
                        this.$emit('bateriaQuestaoAlterada', response); 
                        ( $('#' + this.modal) as any ).modal('toggle');
                        window.alertProcessandoHide(); 
                    })
                    .catch(error => {
                        console.log(error);
                        window.alertProcessandoHide();
                    }); 
            } 
        } 
    } 
