import { render, staticRenderFns } from "./_ModuloFormAdicionar.vue?vue&type=template&id=4127a7ab&scoped=true"
import script from "./_ModuloFormAdicionar.vue?vue&type=script&lang=ts"
export * from "./_ModuloFormAdicionar.vue?vue&type=script&lang=ts"
import style0 from "./_ModuloFormAdicionar.vue?vue&type=style&index=0&id=4127a7ab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4127a7ab",
  null
  
)

export default component.exports