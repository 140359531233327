








































  
	/* eslint-disable @typescript-eslint/camelcase */ 
 
	import { tipoReferenciaService }  from '@/servicegerente';

	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class TipoReferenciaCadastro extends Vue {

		private nome: any = null;
		private descricao: any= null;
		 
		private urlRetorno: any= '/tipoReferencia';

		private errors: any = { };


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao,  
			};  
			 
			 
			tipoReferenciaService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


	}

 

