









































































































































import { Component, Vue } from 'vue-property-decorator';  

import { namespace } from 'vuex-class' 
const usuario = namespace('usuario')


@Component({
    components: { },
})
export default class Principal extends Vue { 

    @usuario.State
    public status!: any

    get loggingIn(): boolean {
        if (this.status) {
            return  this.status.loggedIn ;
        }
        return false;
    } 

}
