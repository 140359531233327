















































	/* eslint-disable @typescript-eslint/camelcase */  
	import { comentarioService  }  from '@/servicegerente';
 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class ComentarioEdit extends Vue {
		
		private model: any = null;
 
		private texto: any = null;
		private status: any= null;

		private urlRetorno: any= '/v2/administracao/comentario';

		private errors: any = { };

		created() {  
			 
			window.alertProcessando();
			comentarioService.getComentario( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Comentario', error.data);
				window.alertProcessandoHide();
			}); 
		}



		@Watch('model')
		tabelaAtualizada(newVal: any) { 
			this.texto = this.model.texto;
			this.status = this.model.status;  
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				status :this.status, 
			};   
			comentarioService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	}

 

