




















	
	import { bancaService  }  from '@/servicegerente';  
	import $ from "jquery"  
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
    import axios from 'axios';
      
    @Component({
        components: { },
    })
    export default class AssuntoIndex extends Vue {
    
        private datatableKey = 0; 
		private  reloadDatatable = false ; 
  
		private config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url:  bancaService.getUrl() + '/datatable', 
			},
			columns: [
				{ data: 'id', name: 'id' , class: 'text-center' },
				{ data: 'nome', name: 'nome' },  
				{ data: 'sigla', name: 'sigla' },  
				{ data: 'oab', name: 'oab', class: 'text-center' }, 
				{ data: 'descricao', name: 'descricao', class: 'text-center' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};
 
		public tableatualizada( ) {  
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                // window.open('#/assunto/show/' + id ) ; 
            });  
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/banca/edit/' + id);  
            });
		} 
	} 

