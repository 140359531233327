


























/* eslint-disable @typescript-eslint/camelcase */ 
import {  referenciaService } from '@/servicegerente';

import $ from "jquery"  

import { Component, Vue } from 'vue-property-decorator';
  
@Component({
    components: { },
})
export default class Principal extends Vue { 
    
    public  reloadDatatable = false ; 

    public config = {
        order: [ [0, "asc"]  ],
        ajax: {
            url: referenciaService.getUrl() + '/datatable'
        },
        columns: [ 
            {  data: 'id',  name: 'id'   },
            {  data: 'banca',  name: 'banca'   },
            { data: 'ano',  name: 'ano' },
            {  data: 'nome',  name: 'nome' },
            {  data: 'descricao',  name: 'descricao' },
            {  data: 'status',  name: 'status' },
            {  data: 'action',  name: 'action',  orderable: false, searchable: false, class: 'text-center' }
        ],
    };


    public tableatualizada( ) {  
         
        $('[btn-editar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            // this.$router.push('/referencia/edit/' + id);
            window.open('#/referencia/edit/' + id);
        });

        $('[btn-visualizar-perguntas]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            // this.$router.push('/referencia/' + id + '/perguntas');
            window.open('#/referencia/' + id + '/perguntas'); 
        });

        // $('[btn-visualizar]').click(  (btn) => {  
        //     const id =   $(btn.currentTarget).data('id'); 
        //     this.$router.push('/admin/plano/show/' + id);
        // });
 
        $('[btn-desativar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Desativação ','' , 
                 () => {  
                    window.alertProcessando(); 
                    referenciaService.desativar(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('desativação realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel desativar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-ativar]').click(  (btn) => {   
            const id =   $(btn.currentTarget).data('id'); 
            window.alertConfimacao('Confirma a Ativação ','' , 
                 () => {  
                    window.alertProcessando(); 
                    referenciaService.ativar(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('ativação realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel ativar ' , error.data.message); 
                    });  
                }
            ); 
        });

        $('[btn-excluir]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma a Exclusão ','' , 
                 () => {  
                    window.alertProcessando(); 
                    referenciaService.excluir(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        this.reloadDatatable = !this.reloadDatatable; 
                        window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                    });  
                }
            ); 
        });

    }




}




