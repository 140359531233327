




























    /* eslint-disable @typescript-eslint/camelcase */  
    import {  ementaTagService , tagService } from "@/servicegerente"; 
    import $ from "jquery"    
    import { Prop , Watch, Component, Vue } from 'vue-property-decorator';
    import { authHeader } from '@/servicegerente';  
      
    @Component({
        components: { },
    })
    export default class FormEditarEmentaTag extends Vue { 
        
        @Prop( ) readonly ementatag!: any 
        @Prop( ) readonly assunto_id!: any  
        @Prop( ) readonly modal!: any  

		
		public sequencia: any = null;
		public tag_id: any = null;
		   
        
        @Watch('ementatag')
		modeloAtualizada(newVal: string) { 
            if(this.ementatag != null ){ 
                this.sequencia = this.ementatag.sequencia;
                this.tag_id = this.ementatag.tag_id; 
            } 
		}
         
        salvar() {
            if(this.tag_id && this.sequencia){
               const dados = { 
                    // tag_id: this.tag_id, 
                    sequencia: this.sequencia, 
                };  
                window.alertProcessando();
                ementaTagService.update(this.ementatag.id , dados)
                    .then(response => {
                        window.toastSucesso(response); 
                        this.$emit('ementaTagAlterada', response); 
                        ( $('#' + this.modal) as any ).modal('toggle');
                        window.alertProcessandoHide(); 
                    })
                    .catch(error => {
                        console.log(error);
                        window.alertProcessandoHide();
                    }); 
            } 
        } 

        
        public processa(params: any){
            return {
                term: params.term,  
                assunto: this.assunto_id ,  
            };
        }

        public ajaxOptions = {
            url: tagService.getUrl() + '/buscar/ajax'  ,  
            dataType: 'json',
            type: "GET",
            delay: 1000,
            headers: authHeader(), 
            data: this.processa, 
            processResults: function(data: any ) {
                return {
                    results: $.map(data, function (item) {
                    return {
                        text: item.nome    , 
                        id: item.id
                    }
                }) 
                }	 
            },
            cache: true
        } 
    } 
