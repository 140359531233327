














































































 
	import { assuntoService }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        private model: any = null;
        private perguntas: any = null;
        private perguntasgerais: any = null;
        private filtro: any = null;
  
		created() {  
			this.buscarAssunto(); 
		}
 
		buscarAssunto() {   
			assuntoService.getAssunto( this.$route.params.id )
			.then(response => {
				this.model = response ; 
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar Assunto');
			});  
		}
		 

		
		limpar() {    
			this.perguntas = null ; 
			this.perguntasgerais = null ;  
		}
		 

		buscarPerguntas(   ) {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadas( this.$route.params.id )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			});  
		}
		  
		buscarPerguntasGeral() {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasGeral( this.$route.params.id )
			.then(response => {
				this.perguntasgerais = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
 

		
		atualizarJob(   ) {  
			this.buscarPerguntasDuplicadasJob();
			this.buscarPerguntasGeral(); 
		}
		
		atualizarJobDuvidosa(   ) {  
			this.buscarPerguntasDuplicadasJobDuvidosa();
			this.buscarPerguntasGeral(); 
		}
		  
		buscarPerguntasDuplicadasJob() {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasJob( this.$route.params.id )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
 
		  
		buscarPerguntasDuplicadasJobDuvidosa() {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasJobDuvidosa( this.$route.params.id )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
 




		buscarPerguntasRestrita() {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasRestrita( this.$route.params.id )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
		
		buscarPerguntasRestritaGeral() {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasRestritaGeral( this.$route.params.id )
			.then(response => {
				this.perguntasgerais = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
		
		
		buscarPerguntasFiltro() {  
			if( this.filtro == null ||  this.filtro ==  '' ){
                return;
            } 
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasFiltro( this.$route.params.id , { filtro: this.filtro  }  )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
		
		buscarPerguntasFiltroGeral() {  
			if( this.filtro == null ||  this.filtro ==  '' ){
                return;
            } 
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasFiltroGeral( this.$route.params.id , { filtro: this.filtro  }  )
			.then(response => {
				this.perguntasgerais = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
		 

		
		atualizarRestrita(   ) {  
			this.buscarPerguntasRestrita();
			this.buscarPerguntasRestritaGeral(); 
		}
		 
		atualizarFiltro(   ) {  
			this.buscarPerguntasFiltro();
			this.buscarPerguntasFiltroGeral(); 
		}
		 
		 
		atualizarCorreta(   ) {  
			this.buscarPerguntasCorreta();
			this.buscarPerguntasCorretaGeral(); 
		}
		 
		 
		buscarPerguntasCorreta() {  
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasCorreta( this.$route.params.id    )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
		
		buscarPerguntasCorretaGeral() {   
			window.alertProcessando();
			assuntoService.buscarPossiveisPerguntasDuplicadasCorretaGeral( this.$route.params.id   )
			.then(response => {
				this.perguntasgerais = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			}); 
		}
		 


		atualizar(   ) {  
			this.buscarPerguntas();
			this.buscarPerguntasGeral(); 
		}
		 
		duplicadas( dados: string ) {  
			dados = dados.replace( '[','' );
			dados = dados.replace( ']','' );  
			const lista = dados.split(',');  
			let botoes = '';  
			lista.forEach(element => {
				element = element.replace( ' ','' );
			});  
			lista.forEach(element => {
				botoes = botoes + '<a href="#/v2/administracao/pergunta/show/' + element  +'" target="_blank" style="padding-left:15px;"> ' + element  +' </a>'; 
			});  
			return botoes; 
		}
		 

	}
	 
