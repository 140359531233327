/* eslint-disable @typescript-eslint/camelcase */ 
import { authHeader } from '@/servicegerente'; 

import { gerenteModel } from './index.model'; 
 
import axios  from 'axios'; 
import moment  from 'moment'; 


import * as CryptoJS from 'crypto-js';

const secret_key =  gerenteModel.getKeyCripto() ;
 

const url = gerenteModel.getUrl() + '/assunto' ;
 
import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}


function getUrl(  ) { 
	return  url; 
}



function getAll(  ) { 

	return  new Promise((resolve, reject) => {   
		const assunto = localStorage.getItem('global_model_assuntos_todas');   
		if(assunto){  
			const data_criacao = localStorage.getItem('data_global_model_assuntos_todas' );   
			if( moment()  < moment( data_criacao ).add(5, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(assunto, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		} 

		axios.get( url + '/all'  , {headers: authHeader() }  )
		.then(response => {  
			const encripted_assuntos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_assuntos_todas'   , encripted_assuntos  );    
			localStorage.setItem('data_global_model_assuntos_todas'  , moment().format('YYYY-MM-DD')  );   
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
 
	});  
 
}


 


function getAssunto( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

 
function getPerguntasPorAssunto( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + '/perguntas'  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





 
function buscarTag( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  + "/tag/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function adicionarTag( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/tag" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function importarTag( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/importar/tag" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function responderApi( id: any  , dados: any ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/responder/api", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function tornarPerguntaCriadaAnteriorAoAno( id: any  , dados: any ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/tornarcriada/api", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function limparSubjectAssunto( id: any  , dados: any ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/limpar/subject", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function adicionarAoAssunto( id: any  , dados: any ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/adicionar/aoassunto", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function buscarPerguntasComRespostas( id: any , dados: any  ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/localizar/perguntas", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function buscarPerguntasComRespostasCerta( id: any , dados: any  ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/localizar/perguntas/certa", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function buscarPerguntasTexto( id: any , dados: any  ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/localizar/perguntas/texto", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function buscarPossiveisPerguntasDuplicadas( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function buscarPossiveisPerguntasDuplicadasRestrita( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/restrita",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function buscarPossiveisPerguntasDuplicadasRestritaGeral( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/restrita/geral",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function buscarPossiveisPerguntasDuplicadasFiltro( id: any , dados: any  ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/perguntas/duplicadas/filtro", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function buscarPossiveisPerguntasDuplicadasFiltroGeral( id: any , dados: any  ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/perguntas/duplicadas/filtro/geral", dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function buscarPossiveisPerguntasDuplicadasJob( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/job",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function buscarPossiveisPerguntasDuplicadasJobDuvidosa( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/job/duvidosa",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function buscarPossiveisPerguntasDuplicadasGeral( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/geral",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function removerTag( id: any , tag: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/tag/' + tag ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout();				
			} 
			reject(error.response);
		}) 
	}); 
}



function migrarPerguntaPrincipalToSecundario( id: any    ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/migrar/tosecundario", {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function migrarPerguntaSecundarioToPrincipal( id: any    ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/migrar/toprincipal", {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function questionariarPerguntaSemTag( id: any    ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/questionariar", {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function duplicadas( id: any    ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/duplicadas", {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function adicionarPerguntasDosConceitosAoAssunto( id: any ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/adicionar/perguntobyconceito", {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function restritarOAB( id: any    ) { 
	return  new Promise((resolve, reject) => {  
        axios.post(url + '/' + id + "/restritar/oab", {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}






function buscarPossiveisPerguntasDuplicadasCorreta( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/correta",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function buscarPossiveisPerguntasDuplicadasCorretaGeral( id: any   ) { 
	return  new Promise((resolve, reject) => {  
        axios.get(url + '/' + id + "/perguntas/duplicadas/correta/geral",  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


export const assuntoService = {
	getAssunto ,
	getPerguntasPorAssunto,
	getUrl,
	getAll , 
	buscarTag,
	update,
	cadastrar,
	ativar,
	desativar,
	excluir,
	adicionarTag,
	removerTag,
    importarTag,
    responderApi,
	tornarPerguntaCriadaAnteriorAoAno,
    limparSubjectAssunto,
	adicionarAoAssunto,
	buscarPossiveisPerguntasDuplicadasJob,
	buscarPossiveisPerguntasDuplicadasJobDuvidosa,
	buscarPossiveisPerguntasDuplicadas,
	buscarPossiveisPerguntasDuplicadasGeral,
	buscarPossiveisPerguntasDuplicadasFiltro,
	buscarPossiveisPerguntasDuplicadasFiltroGeral,
	buscarPossiveisPerguntasDuplicadasRestrita,
	buscarPossiveisPerguntasDuplicadasRestritaGeral,
	buscarPossiveisPerguntasDuplicadasCorreta,
	buscarPossiveisPerguntasDuplicadasCorretaGeral,
	buscarPerguntasComRespostas,
	buscarPerguntasComRespostasCerta,
	buscarPerguntasTexto,
	questionariarPerguntaSemTag,
	migrarPerguntaPrincipalToSecundario,
	migrarPerguntaSecundarioToPrincipal,
	duplicadas,
	adicionarPerguntasDosConceitosAoAssunto,
	restritarOAB,
};