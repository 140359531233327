import { RouteConfig } from 'vue-router' 

 
 

import ModulosIndex from '@/views/v2/administracao/modulos/Modulos.vue';




import PerguntaIndex from '@/views/v2/administracao/pergunta/Index.vue';
import PerguntaCadastro from '@/views/v2/administracao/pergunta/Cadastro.vue';
import PerguntaEdit from '@/views/v2/administracao/pergunta/Edit.vue';
import PerguntaShow from '@/views/v2/administracao/pergunta/Show.vue';
import PerguntaHistorico from '@/views/v2/administracao/pergunta/Historico.vue';




import ResumoIndex from '@/views/v2/administracao/resumo/Index.vue';
import ResumoCadastro from '@/views/v2/administracao/resumo/Cadastro.vue';
import ResumoEdit from '@/views/v2/administracao/resumo/Edit.vue';
import ResumoShow from '@/views/v2/administracao/resumo/Show.vue';
import ResumoHistorico from '@/views/v2/administracao/resumo/Historico.vue';




import AudiosIndex from '@/views/v2/administracao/audios/Index.vue';
import AudiosEdit from '@/views/v2/administracao/audios/Edit.vue';
import AudiosCreate from '@/views/v2/administracao/audios/Cadastro.vue';
import AudioHistorico from '@/views/v2/administracao/audios/Historico.vue';



import ComentarioIndex from '@/views/v2/administracao/comentario/Index.vue';
import ComentarioEdit from '@/views/v2/administracao/comentario/Edit.vue';


import ConfiguracaoIndex from '@/views/v2/administracao/configuracao/Index.vue';
import RelatorioHistorico from '@/views/v2/administracao/configuracao/Historico.vue';
import ConfiguracaoCadastroApi from '@/views/v2/administracao/configuracao/CadastroApi.vue';
import ConfiguracaoCadastroApiGran from '@/views/v2/administracao/configuracao/CadastroApiGran.vue';
import CadastroPortal from '@/views/v2/administracao/configuracao/CadastroPortal.vue';
import Apple from '@/views/v2/administracao/configuracao/Apple.vue';
import Google from '@/views/v2/administracao/configuracao/Google.vue';
import NotificacaoUsuarioComPlano from '@/views/v2/administracao/configuracao/NotificacaoUsuarioComPlano.vue';



import TentativaIndex from '@/views/v2/administracao/tentativas/Index.vue';



import ConceitoTodos from '@/views/v2/administracao/conceito/Todos.vue';
import ConceitoIndex from '@/views/v2/administracao/conceito/Index.vue';
import ConceitoEdit from '@/views/v2/administracao/conceito/Edit.vue';
import ConceitoCreate from '@/views/v2/administracao/conceito/Cadastro.vue';
import ConceitoPergunta from '@/views/v2/administracao/conceito/Pergunta.vue';
import ConceitoShow from '@/views/v2/administracao/conceito/Show.vue';
import ConceitoHistorico from '@/views/v2/administracao/conceito/Historico.vue';



import AssuntoCadastro from '@/views/v2/administracao/assunto/Cadastro.vue';
import AssuntoShow from '@/views/v2/administracao/assunto/Show.vue';
import AssuntoIndex from '@/views/v2/administracao/assunto/Index.vue';
import AssuntoHistorico from '@/views/v2/administracao/assunto/Historico.vue';
import PerguntasDuplicadas from '@/views/v2/administracao/assunto/PerguntasDuplicadas.vue';
import LocalizarPerguntaResposta from '@/views/v2/administracao/assunto/LocalizarPerguntaResposta.vue';

import AssuntocardIndex from '@/views/v2/administracao/assuntoCard/Index.vue';
  

import UsuarioIndex from '@/views/v2/administracao/usuario/Index.vue';
import UsuarioShow from '@/views/v2/administracao/usuario/Show.vue';


import SimuladoIndex from '@/views/v2/administracao/simulado/Index.vue';
import SimuladoClassificacao from '@/views/v2/administracao/simulado/Classificacao.vue';


import DisciplinaIndex from '@/views/v2/administracao/disciplina/Index.vue';
// import DisciplinaModulo from '@/views/v2/administracao/disciplina/Modulo.vue';
// import DisciplinaCadastro from '@/views/v2/administracao/disciplina/Cadastro.vue';
// import DisciplinaEdit from '@/views/v2/administracao/disciplina/Edit.vue';
// import DisciplinaAssuntos from '@/views/v2/administracao/disciplina/Assuntos.vue';
   


import BancaIndex from '@/views/v2/administracao/banca/Index.vue';
import BancaEdit from '@/views/v2/administracao/banca/Edit.vue';




import NoticiaIndex from '@/views/v2/administracao/noticia/Index.vue';
import NoticiaShow from '@/views/v2/administracao/noticia/Show.vue'; 
import NoticiaEdit from '@/views/v2/administracao/noticia/Edit.vue';
import NoticiaCadastro from '@/views/v2/administracao/noticia/Cadastro.vue';
import NoticiaHistorico from '@/views/v2/administracao/noticia/Historico.vue';



import BateriaIndex from '@/views/v2/administracao/bateria/Index.vue';
import BateriaShow from '@/views/v2/administracao/bateria/Show.vue'; 
import BateriaEdit from '@/views/v2/administracao/bateria/Edit.vue';
import BateriaCadastro from '@/views/v2/administracao/bateria/Cadastro.vue';



import EmentaIndex from '@/views/v2/administracao/ementa/Index.vue';
import EmentaShow from '@/views/v2/administracao/ementa/Show.vue'; 
import EmentaShowTags from '@/views/v2/administracao/ementa/Todos.vue'; 
import EmentaEdit from '@/views/v2/administracao/ementa/Edit.vue';
import EmentaCadastro from '@/views/v2/administracao/ementa/Cadastro.vue';



import CardIndex from '@/views/v2/administracao/card/Index.vue';
import CardShow from '@/views/v2/administracao/card/Show.vue';
import CardEdit from '@/views/v2/administracao/card/Edit.vue';
import CardCadastro from '@/views/v2/administracao/card/Cadastro.vue';
import CardHistorico from '@/views/v2/administracao/card/Historico.vue';



import BarraLateralAdmin from '@/components/layout/BarraLateralAdmin.vue'
import Header from '@/components/layout/Header.vue'  
import Footer from '@/components/layout/Footer.vue'

const routeName = '/v2/administracao'

const routesGerente: Array<RouteConfig> = [
     
    
    
    {
		path: routeName , 
		components: {
			default: ModulosIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin']}
	},


    {
		path: routeName + '/estatistica/tentativas' , 
		components: {
			default: TentativaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},



	
	
	/*
	|--------------------------------------------------------------------------
	| Simulado
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/simulado', 
		components: {
			default: SimuladoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Coordenador' , 'Admin'] }
	},
    
	{
		path: routeName + '/simulado/classificacao', 
		components: {
			default: SimuladoClassificacao,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Coordenador' , 'Admin'] }
	},







	/*
	|--------------------------------------------------------------------------
	| DISCIPLINA
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/disciplina', 
		components: {
			default: DisciplinaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},


	
	/*
	|--------------------------------------------------------------------------
	| Usuario
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/usuario', 
		components: {
			default: UsuarioIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Coordenador' , 'Admin'] }
	},

	
	{
		path: routeName + '/usuario/show/:id', 
		components: {
			default: UsuarioShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Coordenador' , 'Admin'] }
	},







	/*
	|--------------------------------------------------------------------------
	| CONFIGURACAO
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/configuracao', 
		components: {
			default: ConfiguracaoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},

	{
		path: routeName + '/configuracao/relatorio/historico', 
		components: {
			default: RelatorioHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},


	{
		path: routeName + '/configuracao/relatorio/cadastroapi', 
		components: {
			default: ConfiguracaoCadastroApi,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},


	{
		path: routeName + '/configuracao/relatorio/cadastroapigran', 
		components: {
			default: ConfiguracaoCadastroApiGran,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},

	{
		path: routeName + '/configuracao/relatorio/cadastroportal', 
		components: {
			default: CadastroPortal,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},

	
	{
		path: routeName + '/configuracao/apple', 
		components: {
			default: Apple,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},
	
	{
		path: routeName + '/configuracao/google', 
		components: {
			default: Google,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},


	{
		path: routeName + '/configuracao/relatorio/notificacao', 
		components: {
			default: NotificacaoUsuarioComPlano,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},









    /*
	{
		path: '/disciplina/create', 
		components: {
			default: DisciplinaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},    
	{
		path: '/disciplina/edit/:id',
		// component:DisciplinaEdit , 
		components: {
			default: DisciplinaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	{
		path: '/disciplina/:id/assuntos',
		// component: DisciplinaAssuntos , 
		components: {
			default: DisciplinaAssuntos,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},   
	{
		path: '/disciplina/:id/modulos',
		// component: DisciplinaModulo , 
		components: {
			default: DisciplinaModulo,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  

*/





    /*
	|--------------------------------------------------------------------------
	| PERGUNTA
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/pergunta', 
		components: {
			default: PerguntaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/pergunta/create', 
		components: {
			default: PerguntaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/pergunta/edit/:id', 
		components: {
			default: PerguntaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/pergunta/show/:id', 
		components: {
			default: PerguntaShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/pergunta/historico', 
		components: {
			default: PerguntaHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 
	 
 

    /*
	|--------------------------------------------------------------------------
	| Resumo 
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/resumo', 
		components: {
			default: ResumoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},


	{
		path: routeName + '/resumo/create', 
		components: {
			default: ResumoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/resumo/edit/:id', 
		components: {
			default: ResumoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},
	
	{
		path: routeName + '/resumo/show/:id', 
		components: {
			default: ResumoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/resumo/historico', 
		components: {
			default: ResumoHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},



    /*
	|--------------------------------------------------------------------------
	| Audios
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/audio', 
		components: {
			default: AudiosIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	
	{
		path: routeName + '/audio/create', 
		components: {
			default: AudiosCreate,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},



	{
		path: routeName + '/audio/edit/:id', 
		components: {
			default: AudiosEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	
	{
		path: routeName + '/audio/historico', 
		components: {
			default: AudioHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 









    /*
	|--------------------------------------------------------------------------
	| Comentario
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/comentario', 
		components: {
			default: ComentarioIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/comentario/edit/:id', 
		components: {
			default: ComentarioEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},







	


	
    /*
	|--------------------------------------------------------------------------
	| Conceito
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/conceito', 
		components: {
			default: ConceitoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/conceito/create', 
		components: {
			default: ConceitoCreate,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/conceito/:id/perguntas', 
		components: {
			default: ConceitoPergunta,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},



	{
		path: routeName + '/conceito/edit/:id', 
		components: {
			default: ConceitoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	 


	{
		path: routeName + '/conceito/show/:id', 
		components: {
			default: ConceitoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},
 
	{
		path: routeName + '/conceito/historico', 
		components: {
			default: ConceitoHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},
 
	{
		path: routeName + '/conceito/todos', 
		components: {
			default: ConceitoTodos,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [ 'Admin'] }
	},

	 


	
    /*
	|--------------------------------------------------------------------------
	| Assunto
	|--------------------------------------------------------------------------
    */
    
	{
		path: routeName + '/assunto', 
		components: {
			default: AssuntoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

    
	{
		path: routeName + '/assunto/card', 
		components: {
			default: AssuntocardIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},


	{
		path: routeName + '/assunto/historico', 
		components: {
			default: AssuntoHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	}, 


	// {
	// 	path: routeName + '/assunto/create', 
	// 	components: {
	// 		default: AssuntoCreate,
	// 		sidebar: BarraLateralAdmin,
	// 		header: Header,
	// 		footer: Footer,
	// 	},
	// 	meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	// },

	// {
	// 	path: routeName + '/assunto/:id/perguntas', 
	// 	components: {
	// 		default: AssuntoPergunta,
	// 		sidebar: BarraLateralAdmin,
	// 		header: Header,
	// 		footer: Footer,
	// 	},
	// 	meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	// },

	{
		path: routeName + '/assunto/:id/perguntas/duplicadas', 
		components: {
			default: PerguntasDuplicadas,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/assunto/:id/localizar/perguntas', 
		components: {
			default: LocalizarPerguntaResposta,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},



	// {
	// 	path: routeName + '/assunto/edit/:id', 
	// 	components: {
	// 		default: AssuntoEdit,
	// 		sidebar: BarraLateralAdmin,
	// 		header: Header,
	// 		footer: Footer,
	// 	},
	// 	meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	// },

	 


	{
		path: routeName + '/assunto/show/:id', 
		components: {
			default: AssuntoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Gerente' , 'Admin'] }
	},

	{
		path: routeName + '/assunto/create', 
		components: {
			default: AssuntoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: [  'Admin'] }
	},

	 


	
	 
	/*
	|--------------------------------------------------------------------------
	| Noticia
	|--------------------------------------------------------------------------
	*/
	{
		path: routeName + '/noticia', 
		components: {
			default: NoticiaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},
	 
	{
		path: routeName + '/noticia/edit/:id', 
		components: {
			default: NoticiaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	}, 
	{
		path: routeName + '/noticia/show/:id', 
		components: {
			default: NoticiaShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},  
	{
		path: routeName + '/noticia/create', 
		components: {
			default: NoticiaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},   
	 

	{
		path: routeName + '/noticia/historico', 
		components: {
			default: NoticiaHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},
 
	
	
	 
	/*
	|--------------------------------------------------------------------------
	| bateria
	|--------------------------------------------------------------------------
	*/
	{
		path: routeName + '/bateria', 
		components: {
			default: BateriaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},
	 
	{
		path: routeName + '/bateria/edit/:id', 
		components: {
			default: BateriaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	}, 
	{
		path: routeName + '/bateria/show/:id', 
		components: {
			default: BateriaShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},  
	{
		path: routeName + '/bateria/create', 
		components: {
			default: BateriaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},   
	 
	
	
	/*
	|--------------------------------------------------------------------------
	| Banca 
	|--------------------------------------------------------------------------
	*/
	{
		path: routeName + '/banca', 
		components: {
			default: BancaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},
	 
	{
		path: routeName + '/banca/edit/:id', 
		components: {
			default: BancaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},
	 
	


	/*
	|--------------------------------------------------------------------------
	| Card 
	|--------------------------------------------------------------------------
	*/
	{
		path: routeName + '/card', 
		components: {
			default: CardIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},
	 
	{
		path: routeName + '/card/edit/:id', 
		components: {
			default: CardEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	}, 
	{
		path: routeName + '/card/show/:id', 
		components: {
			default: CardShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},  
	{
		path: routeName + '/card/create', 
		components: {
			default: CardCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},   
	 
	{
		path: routeName + '/card/create/anterior/:anterior', 
		components: {
			default: CardCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},  
	{
		path: routeName + '/card/create/anterior/:anterior/nivel/:nivel', 
		components: {
			default: CardCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Gerente' , 'Admin'] }
	},  

	{
		path: routeName + '/card/historico', 
		components: {
			default: CardHistorico,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true , requiresPerfil: ['Admin']}
	},




	
	/*
	|--------------------------------------------------------------------------
	| ementa
	|--------------------------------------------------------------------------
	*/
	{
		path: routeName + '/ementa', 
		components: {
			default: EmentaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},
	 
	{
		path: routeName + '/ementa/edit/:id', 
		components: {
			default: EmentaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [ 'Admin'] }
	}, 
	{
		path: routeName + '/ementa/show/:id', 
		components: {
			default: EmentaShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},  
	{
		path: routeName + '/ementa/show/:id/tags', 
		components: {
			default: EmentaShowTags,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},  
	{
		path: routeName + '/ementa/create', 
		components: {
			default: EmentaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: [  'Admin'] }
	},   
	 
 
]

  
 
export default routesGerente
