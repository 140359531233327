/* eslint-disable @typescript-eslint/camelcase */ 
import { authHeader } from '@/servicegerente'; 

import { gerenteModel } from './index.model'; 
 
import axios  from 'axios';  
 
const url = gerenteModel.getUrl() + '/usuario' ;  
 
import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}
 

function getUrl(  ) { 
	return  url; 
}
 
 

 
 
  

function adicionarPlano( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/plano" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function adicionarModulo( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/modulo" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

 



function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function removerPlano( id: any , plano: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/plano/' + plano ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function removerModulo( id: any , modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/modulo/' + modulo ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function getUsuario( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
 
function buscarPerfis( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/perfil/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

 
function buscarModulos( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/modulo/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

 
function buscarPlanos( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/plano/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 


function bonificarPRO( id: any, data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/' + id  + '/bonificar/pro' , data  , {headers: authHeader() } )
		.then(response => {   
			resolve( response.data );  
		})
		.catch(error => {  
			reject(error.response.data);
		}) 
	}); 
}

 

export const usuarioService = {
	 
	getUrl, 
	getUsuario,
	buscarPerfis,

	buscarModulos,
	removerModulo,
	adicionarModulo,
 
	buscarPlanos, 
	excluir,
	update,
 
	adicionarPlano,
 
	removerPlano,

	bonificarPRO,
 

};

