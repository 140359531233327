


















































































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import { guiaOnlineService, manzaTinyEditorColorsMap  } from '@/servicegerente';

    import Editor from '@tinymce/tinymce-vue';


    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class GuiaCadastro extends Vue {

        
		public nome: any= null; 
        public descricao: any= null; 
		public urlRetorno: any= '/guia_online'; 
		public errors: any = { };
        public colors: any = []; 
 
		created() { 
			this.colors = manzaTinyEditorColorsMap; 
        }

		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome, 
				descricao :this.descricao,  
			};   
			guiaOnlineService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }
    
 
