














































































      
    import {  authHeader, referenciaService, tipoReferenciaService } from '@/servicegerente';


    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class PermissaoCadastro extends Vue {

        private tipoReferencias: any = null;
         
		private nome: any = null;
		private descricao: any= null;
		private fonte: any= null;
		private banca: any= null;
		private ano: any= null;
		private gabarito: any= null;
		private tipo_referencia_id: any= null; 

 
		private urlRetorno: any= '/referencia';

		private errors: any = { };

        created() { 
            tipoReferenciaService.getAll()
                .then(response => {
                    this.tipoReferencias = response;
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar as tipo Referencias');
                }); 
        }

		salvar() {
            window.alertProcessando();
            
            const dados = new FormData();
            if (this.fonte) {
                dados.append('fonte_file', this.fonte);
            }
            if (this.gabarito) {
                dados.append('gabarito_file', this.gabarito);
            }

            dados.append('gabarito_file', this.gabarito);
            dados.set('nome', this.nome);
            dados.set('ano', this.ano);
            dados.set('banca', this.banca);
            dados.set('descricao', this.descricao);
            dados.set('tipo_referencia_id', this.tipo_referencia_id);
 

			referenciaService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 



        handleFileUpload() {
            this.fonte = this.$refs.fonte.files[0];
        }

        handleGabaritoFileUpload() {
            this.gabarito = this.$refs.gabarito.files[0];
        }


    }
    
 

