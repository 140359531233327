/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..'; 
import { administradorModel } from './index.model';  
import moment  from 'moment'; 
import axios  from 'axios'; 
import * as CryptoJS from 'crypto-js';
import  store   from '../../store/index' 

const secret_key =  administradorModel.getKeyCripto() ;
 

const url = administradorModel.getUrl() + '/disciplina' ; 
const url2 = administradorModel.getUrl()   ; 



function getUrl(  ) { 
	return  url; 
}


function logout() {  
	store.dispatch('usuario/logout'); 
}

function getAll(  ) {  
	return  new Promise((resolve, reject) => {   
		const disciplinas = localStorage.getItem('global_model_disciplinas_todas');   
		if(disciplinas){  
			const data_criacao = localStorage.getItem('data_global_model_disciplinas_todas' );   
			if( moment()  < moment( data_criacao ).add(5, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(disciplinas, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		} 
		axios.get( url + '/all'  , {headers: authHeader() }  )
		.then(response => {  
			const encripted_disciplinas = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_disciplinas_todas'   , encripted_disciplinas  );    
			localStorage.setItem('data_global_model_disciplinas_todas'  , moment().format('YYYY-MM-DD')  );   
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
}




function getAllModulo(    ) {  
	return  new Promise((resolve, reject) => {  
		const disciplinas = localStorage.getItem('global_model_disciplinas_todos_modulo_2');  
		if(disciplinas){  
			const data_criacao = localStorage.getItem('data_global_model_disciplinas_todos_modulo_2' );   
			if( moment()  < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(disciplinas, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		} 
		axios.get( url2 + '/modulo/2/disciplinas'  , {headers: authHeader() }  )
			.then(response => {    
				const encripted_disciplinas = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
				localStorage.setItem('global_model_disciplinas_todos_modulo_2'   , encripted_disciplinas  );    
				localStorage.setItem('data_global_model_disciplinas_todos_modulo_2'  , moment().format('YYYY-MM-DD')  ); 
				resolve( response.data);  
			})
			.catch(error => {  
				console.log('passou aqui');
				if ( error.response.status === 401) {
					logout();				
				}
				reject(error.response);
			})   
	}); 

}

 



function getDisciplina( id: any , api = false) { 
	return  new Promise((resolve, reject) => {   
		const disciplinas = localStorage.getItem('global_model_disciplina_id_' + id );   
		if(disciplinas && !api ){  
			const data_criacao = localStorage.getItem('data_global_model_disciplina_id_' + id  );   
			if( moment()  < moment( data_criacao ).add( 2 , 'days') ){
				const bytes  = CryptoJS.AES.decrypt(disciplinas, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}  
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {   
			const encripted_disciplinas = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem( 'global_model_disciplina_id_' + id , encripted_disciplinas  );    
			localStorage.setItem('data_global_model_disciplina_id_' + id  , moment().format('YYYY-MM-DD')  );   
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
}
 

 
function buscarAssunto( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/assunto/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout();				
			} 
			reject(error.response);
		}) 
	}); 
}


 
function buscarAssuntoExclusivo( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/assunto/exclusivo/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function buscarTodosAssuntoDaDisciplina( id: any ) { 
	
	return  new Promise((resolve, reject) => {   
		const assuntos = localStorage.getItem('global_model_disciplina_id_' + id + '_assuntos' );   
		if(assuntos){  
			const data_criacao = localStorage.getItem('data_global_model_disciplina_id_' + id  + '_assuntos' );   
			if( moment()  < moment( data_criacao ).add( 2 , 'days') ){
				const bytes  = CryptoJS.AES.decrypt(assuntos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}  
		axios.get( url + '/' + id + "/assuntos" , {headers: authHeader() }  )
		.then(response => {  
			const encripted_assuntos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem( 'global_model_disciplina_id_' + id  + '_assuntos', encripted_assuntos  );    
			localStorage.setItem('data_global_model_disciplina_id_' + id   + '_assuntos', moment().format('YYYY-MM-DD')  );  
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
 
}




function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function adicionarAssunto( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/assunto" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function removerAssunto( id: any , perfil: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/assunto/' + perfil ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

export const disciplinaAdminService = {
	getAll , 
	getUrl,
	getDisciplina,
	getAllModulo,
	buscarAssunto,
	buscarAssuntoExclusivo,
	buscarTodosAssuntoDaDisciplina,
	excluir,
	desativar,
	ativar,
	cadastrar,
	update,
	adicionarAssunto,
	removerAssunto,

};
