




























































































































/* eslint-disable @typescript-eslint/camelcase */ 
 
	import {   moduloService , resumoService , disciplinaService, manzaTinyEditorColorsMap  }  from '@/servicegerente';

	import Editor from '@tinymce/tinymce-vue'
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente')
    const usuario = namespace('usuario')

	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class EditResumo extends Vue {
 
		@gerente.State
		public modulo!: any
 
        @usuario.Getter
		public isAdmin!:  (  ) => boolean
 
        public model: any = null;
        public modulos: any = null;
        public disciplinas: any = null;
		public assuntos: any = null;
         
        public colors: any = []; 
 
		public titulo: any = null;  
		public restrito: any= null; 
		public conteudo: any= null; 
		public modulo_id: any= null; 
		public disciplina_id: any= null;  
		public assunto_id: any= null;  
		  
  
		public urlRetorno: any= '/v2/administracao/resumo';

		public errors: any = { };

       	created() { 
			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			} 
			
			this.colors = manzaTinyEditorColorsMap; 

			window.alertProcessando(); 
			resumoService.getResumo(this.$route.params.id , true) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a Tipo Referência', error.data);
				window.alertProcessandoHide();
				 
			}); 
			moduloService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as tipo Referencias');
			}); 
			moduloService.buscarTodasDisciplina( this.modulo.id ) 
			.then(response => {
				this.disciplinas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			});   
		}
  
		@Watch('disciplina_id')
		mudouDisciplina(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			} 
		}
 
		buscarAssunto( id: any) { 
			const filtrada = this.disciplinas.filter( ( disciplina: any) => { 
				if (disciplina.id == id) {
					return true;
				}
			})[0]; 
			if(filtrada){
				this.assuntos = filtrada.assuntos ;
			} 
		}
 
		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.titulo = this.model.titulo;
			this.conteudo = this.model.conteudo;  
			this.modulo_id = this.model.modulo_id;     
			this.restrito = this.model.restrito;  
			this.disciplina_id = this.model.disciplina_id;  
			this.assunto_id = this.model.assunto_id;  
		}
 
		salvar() {
			window.alertProcessando();
			let dados = null;   
			if(this.isAdmin){
				dados = {
					titulo :this.titulo, 
					conteudo :this.conteudo,   
					restrito :this.restrito,  
					assunto_id :this.assunto_id,  
				};    
			}
			else{
				dados = {
					titulo :this.titulo, 
					conteudo :this.conteudo,   
					restrito :this.restrito,  
				};
			}
			 
			resumoService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
	 
		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}
 
		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		 
	}
 
