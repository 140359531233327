































































    /* eslint-disable @typescript-eslint/camelcase */ 
 
    import { assuntoAdminService, disciplinaAdminService , moduloAdminService} from '@/servicegerente';
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 

	@Component({
		components: {  },
	 })
    export default class AssuntoCadastro extends Vue {

        public disciplinas: any = null;
         
		
        @gerente.State
		public modulo!: any

		@gerente.State
		public disciplina!: any

		public nome: any = null;
		public sigla: any= null;
		public disciplina_id: any= null;
		public descricao: any= null; 
		public sequencia: any= null; 
  
		public urlRetorno: any= '/v2/administracao/assunto';

		public errors: any = { };


		async salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				sigla :this.sigla, 
				disciplina_id :this.disciplina_id, 
				descricao :this.descricao,  
				sequencia :this.sequencia,  
			};  
			  
			assuntoAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.AdicionarPermissao( response.model.id ) ; 
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
		
		
        async AdicionarPermissao( assunto: any )  { 
			window.alertProcessando();
			const dados = {
				assunto : assunto, 
				prioridade : 5, 
				pivot_disciplina_id : this.disciplina.pivot, 
				disciplina_id :  this.disciplina.id, 
			};    
			moduloAdminService.adicionarAssunto( this.modulo.id ,  dados )
			.then(response => {
				window.toastSucesso("disciplina adicionado com successo.");
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => { 
				this.$router.push(  this.urlRetorno )
			});  
        } 

        created() { 
			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			} 
			if(!this.disciplina){
				this.$router.push('/v2/administracao/disciplina'); 
			}
			else{
				this.disciplina_id = this.disciplina.id 
			}
            disciplinaAdminService.getAll()
                .then(response => {
                    this.disciplinas = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar as disciplinas');
                }); 
        }


    }
    

 

