






















































 
    /* eslint-disable @typescript-eslint/camelcase */ 
    

    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { perguntaService , tagService }  from '@/servicegerente'; 
	import $ from "jquery" ;
    import { authHeader } from '@/servicegerente';  

	@Component({
		components: { 
        },
	 })
    export default class FormPerfilPermissao extends Vue {

        @Prop( ) readonly tags!: any
        @Prop( ) readonly assunto!: any

        public tag: any = null; 
        public tag_ajax: any = null; 

        public add_tag_id = 'modal-seguranca-pergunta-adicionar-tag'; 

        public ajaxOptions = {
            url: tagService.getUrl() + '/buscar/ajax'  ,  
            dataType: 'json',
            type: "GET",
            delay: 1000,
            headers: authHeader(), 
            data: function(params) {
                return {
                    term: params.term,  
                };
            },
            processResults: function(data ) {
                return {
                    results: $.map(data, function (item) {
                    return {
                        text: item.nome    , 
                        id: item.id
                    }
                }) 
                }	 
            },
            cache: true
        }

        AdicionarTag() {
            if (this.tag) { 
                window.alertProcessando();
                const dados = {
                    tag : this.tag,  
                };    
                perguntaService.adicionarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('tagAdicionada', response);
                    // ( $('#' + this.add_tag_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

        
        AdicionarTagAjax() {
            if (this.tag_ajax) { 
                window.alertProcessando();
                const dados = {
                    tag : this.tag_ajax,  
                };    
                perguntaService.adicionarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('tagAdicionada', response);
                    // ( $('#' + this.add_tag_id + 'geral') as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }

 
