





























	import { disciplinaAdminService  }  from '@/servicegerente';
 
	import $ from "jquery"  

    import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class DisciplinaIndex extends Vue {

		private  reloadDatatable = false ; 

		private config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url: disciplinaAdminService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'nome', name: 'nome' },
				{ data: 'sigla', name: 'sigla' }, 
				{ data: 'status', name: 'status' }, 
				{ data: 'nivel', name: 'nivel' }, 
				{ data: 'meta', name: 'meta' }, 
				{ data: 'is_principal', name: 'is_principal' }, 
				{ data: 'concluido', name: 'concluido' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};



		public tableatualizada( ) {   
            
            $('[btn-visualizar-modulos]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/disciplina/' + id + '/modulos');
            });

            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                // this.$router.push('/disciplina/edit/' + id  );
                window.open('#/disciplina/edit/' + id);
            });

			$('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        disciplinaAdminService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        disciplinaAdminService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
			});
			
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        disciplinaAdminService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

		}
		 
	}

 
