var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('custom-card',{attrs:{"title":"Enviar Mensagem para todos os usuario","voltar":_vm.urlRetorno},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"card-footer text-right"},[_vm._t("footer"),_c('button',{staticClass:" btn btn-danger ",on:{"click":_vm.salvar}},[_vm._v(" Enviar "),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp')}}),_c('i',{staticClass:" fa fa-check "})]),_c('button',{staticClass:" btn btn-success ",on:{"click":_vm.testeEnviarMensagem}},[_vm._v(" Testar "),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp')}}),_c('i',{staticClass:" fa fa-check "})])],2)]},proxy:true}],null,true)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-8"},[_c('crudFormElemento',[_c('label',{attrs:{"for":"assunto"}},[_vm._v("Assunto:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assunto),expression:"assunto"}],staticClass:"form-control",attrs:{"required":"","type":"text","id":"assunto","name":"assunto"},domProps:{"value":(_vm.assunto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.assunto=$event.target.value}}})])],1),_c('div',{staticClass:"col-12 col-sm-4"},[_c('crudFormElemento',[_c('label',{attrs:{"for":"assunto"}},[_vm._v("skip:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.skip),expression:"skip"}],staticClass:"form-control",attrs:{"required":"","type":"number","id":"skip","name":"skip"},domProps:{"value":(_vm.skip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.skip=$event.target.value}}})])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('crudFormElemento',[_c('label',{attrs:{"for":"mensagem"}},[_vm._v("Mensagem:")]),_c('editor',{attrs:{"api-key":"wfmp2fmb67ocbs0x86o0v1whb3stwck3lhdpx52xd9988ebe","init":{
                                                height: 350,
                                                menubar: true,
                                                relative_urls: false,
                                                remove_script_host: false,
                                                fontsize_formats: '7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 22pt 24pt 36pt 48pt',
                                                plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                'undo redo | bold italic | bullist numlist | charmap   preview  removeformat '
                                            }},model:{value:(_vm.mensagem),callback:function ($$v) {_vm.mensagem=$$v},expression:"mensagem"}})],1)],1)])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }