
















































    /* eslint-disable @typescript-eslint/camelcase */ 
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { bateriaQuestaoService , moduloService }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: {  },
	 })
    export default class FormPerfilPermissao extends Vue {

        @Prop( ) readonly modulo_id!: any  

		private disciplinas: any = null;

		private nivel: any = null;
		private pergunta_id: any = null;
		private disciplina_id: any = null; 
 
        private add_permissao_id = 'modal-bateria-adicionar-questao'; 
        private errors: any = { };
        
		created() {  
			moduloService.buscarTodasDisciplina( this.modulo_id ) 
			.then(response => {
				this.disciplinas = response ; 
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			});  
		}
 
        AdicionarQuestao() {
            if (this.disciplina_id && this.nivel) { 
                window.alertProcessando();
                const dados = {
                    bateria_id :this.$route.params.id, 
                    disciplina_id :this.disciplina_id, 
                    nivel :this.nivel, 
                    pergunta_id :this.pergunta_id, 
                };    
                bateriaQuestaoService.cadastrar( dados )
                .then(response => {
                    window.toastSucesso("permissao adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('bateriaQuestaoAdicionada', response);
                    this.nivel++;
                    this.pergunta_id = null;
                    // ( $('#' + this.add_permissao_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500);
                
            }
        } 

    } 

   
 
