

























/* eslint-disable @typescript-eslint/camelcase */  
import {  respostaComentarioService } from "@/servicegerente"; 
import $ from "jquery"   
import {Prop , Component, Vue } from 'vue-property-decorator';
  
@Component({
    components: { },
})
export default class FormCriarRespostaComentario extends Vue { 

    public resposta: any  = null; 
    @Prop( ) readonly respostaId!: any 
    public add_tag_id = 'modal-seguranca-pergunta-criar-respostas-comentario'; 
    
    created() {
        this.add_tag_id = 'modal-seguranca-pergunta-criar-respostas-comentario-' +  this.respostaId; 
    }

    salvar() {
        if(this.resposta){
            const data: any = {}; 
            data["texto"] = this.resposta;
            data["pergunta_id"] = this.$route.params.id; 
            data["resposta_id"] = this.respostaId; 
            window.alertProcessando();
            respostaComentarioService
                .cadastrar(data)
                .then(response => {
                    window.toastSucesso(response);
                    this.resposta = ""; 
                    this.$emit('respostaCriadaComentario', response); 
                    ( $('#' + this.add_tag_id) as any ) .modal('toggle');
                    window.alertProcessandoHide(); 
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                }); 
        } 
    } 
} 
