




































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { noticiaService }  from '@/servicegerente';


	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class GuiaShow extends Vue { 

        private url_retorno: any = '/v2/administracao/noticia' ;
        private model: any = null ;

        

		created() { 
			window.alertProcessando();
			noticiaService.getModel( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				 
				window.toastErro('Não foi possivel achar Configuração');
			}); 
		}


	}
 

