






































































import { namespace } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator';
import $ from "jquery" 

const usuario = namespace('usuario')

@Component({
    components: {
        
    },
})
export default class Header extends Vue {
    
  @usuario.State
  public user!: string

  @usuario.State
  public perfis!: any


  @usuario.State
  public status!: any


  get admin(): boolean {
      if (this.perfis) {
          return (
              this.perfis.filter( ( perfil: any) => {
                  if (perfil == "Admin") {
                      return true;
                  }
              }).length > 0
          );
      }
      return false;
  }

  get gerente(): boolean {
      if (this.perfis) {
          return (
              this.perfis.filter( ( perfil: any) => {
                  if (perfil == "Gerente") {
                      return true;
                  }
              }).length > 0
          );
      }
      return false;
  }

  get loggingIn(): boolean {
      if (this.status) {
          return  this.status.loggedIn ;
      }
      return false;
  }

  @usuario.Action
  public logout!: ( ) => void


    
  public async clear() {
    try {
      $('body').addClass('loading');
      const user = localStorage.getItem('user');  
      localStorage.clear();  
      if(user){
        localStorage.setItem('user', user  );  
      }
    }
    finally {
      await this.sleep(600);
      $('body').removeClass('loading');
    } 
  }


    
  public sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }



}





