













/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-use-before-define */

	import { Watch, Prop,Component, Vue } from 'vue-property-decorator';

	import { authHeader } from '../../../servicegerente'; 
	import axios  from 'axios';  
    import $ from "jquery" 


	@Component({
        components: {
             
        },
	}) 
	export default class DataTable extends Vue {


		@Prop({required: true}) readonly config!: any
		
		@Prop({required: false})  reload!: boolean

		@Prop({required: false , default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)})  id!: any

  
		private datatable: any = null;
		// public id: any = null;

		@Watch('reload')
		tabelaAtualizada(newVal: string) {
			this.datatable.ajax.reload(); 
		}
		
		// created(){
		// 	this.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); 
		// }
 
		mounted() {
			this.datatable = this.montarDatatable(   );   
		}

 
		montarDatatable(   lengthMenu = [ [    12, 15, 20,  40, 50, 70, 100, 150, -1],   [   12, 15,  20,   40, 50, 70, 100, 150, "Todos"]  ]) { 
				// const vm = this;
				const seletorTabela = '#' + this.id ;  
				const configPadrao = {
					responsive: true,
					processing: true,
					serverSide: true,
					pagingType: "simple_numbers",
					lengthMenu: lengthMenu,
					language: { 
							"sEmptyTable": "Nenhum registro encontrado",
							"sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
							"sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
							"sInfoFiltered": "(Filtrados de _MAX_ registros)",
							"sInfoPostFix": "",
							"sInfoThousands": ".",
							"sLengthMenu": "_MENU_ resultados por página",
							"sLoadingRecords": "Carregando...",
							"sProcessing": "Processando...",
							"sZeroRecords": "Nenhum registro encontrado",
							"sSearch": "Pesquisar",
							"oPaginate": {
								"sNext": "Próximo",
								"sPrevious": "Anterior",
								"sFirst": "Primeiro",
								"sLast": "Último"
							},
							"oAria": {
								"sSortAscending": ": Ordenar colunas de forma ascendente",
								"sSortDescending": ": Ordenar colunas de forma descendente"
							
							}
					},
					ajax: { 
						type: 'post',	
						data: { } , 
						headers: authHeader() ,
						complete:  (e: any, tipo: any) => {
							if( tipo  === 'error'){ 
								console.log(e.responseJSON);
								window.toastErro('Erro: ' + e.responseJSON.message , '' );
							} 
							if(e.status === 403){ 
								this.$emit('error_acesso_proibido' , '' );
							} 
							if(e.status === 401){ 
								const {
										dispatch
									} = this.$store;
									dispatch('usuario/logout'); 
							} 
						}
					}, 

					
					
			        initComplete:function(){
						this.api().columns('.dataPesquisavel').every(  (column: any) => {
							 
							const input = document.createElement("input");
							$(input).addClass( "form-control" );   
							$(input).datepicker({todayHighlight: !0, autoclose: !0, format: 'dd/mm/yyyy', language: 'pt-BR'});
							$(input).appendTo($(column.footer()).empty())
								.on('change', function () {
									const val = $.fn.dataTable.util.escapeRegex($(this).val());
									column.search(val)
									.draw();
							});
						});
							
						// const $searchInput = $(seletorTabela  +'_filter input'); 
						// $searchInput.unbind(); 
						// $searchInput.bind('keyup', (e) =>{
						// 	if (e.keyCode == 13) {
						// 		dataTable.search(this.value).draw();
						// 	}
						// });
					}
			        
			    }; 
			    
			     	
				const config = window.jQuery.extend(true, {}, configPadrao , this.config ); 	
				
			    $(seletorTabela + ' thead th[pesquisavel]').each(function() {
			    	const title = $(seletorTabela + ' thead th').eq($(this).index()).text();
			    	$(this).html('<input type="text" pesquisavel placeholder="' + title + '" style="width:100%;" />');
				}); 

				$.fn.dataTable.ext.errMode = 'none';

				const dataTable = $(seletorTabela).DataTable(config); 
				
				 
				if(this.config.clica_linha){
					$(seletorTabela).on('click', 'tr',   ()=> {
						const data = dataTable.row( this ).data();
						this.clicouNaLinha( data.id  ); 
					} ); 
				}
				if(this.config.emit_clica_linha){ 
					$(seletorTabela).on('click', 'td',   () =>  {
						const data = dataTable.row( this ).data() ; 
						const coluna = dataTable.column( this ).index()  ;  
						this.$emit('clicou_coluna_' + coluna , data );
					} ); 
				}
				dataTable.on( 'draw',   () => {
					this.$emit('tableatualizada', this.datatable.ajax.params() );
					this.$emit('tableatualizada' + this.id , this.datatable.ajax.params() );
					// this.$emit('tableatualizada', {} );
				});
				dataTable.columns().eq(0).each(function(colIdx: any) { 
					$('input', dataTable.column(colIdx).header()).on('keypress change click', function(e) {
						if (e.type === 'change' || e.which === 13) {
						dataTable
						.column(colIdx)
						.search(this.value)
						.draw(); 
						e.stopPropagation();
						}
					}); 
					$('input', dataTable.column(colIdx).header()).on('click', function(e) {
						e.stopPropagation();
					});
				}); 
				return dataTable;
		}



		clicouNaLinha(  id: any   ) { 
			this.$router.push( this.config.clica_linha.url + id );
		}

	 


	}

