
















































































































































































































 
	 
	/* eslint-disable @typescript-eslint/camelcase */ 
	/* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
 

	import {  cardService , moduloService , manzaTinyEditorColorsMap  }  from '@/servicegerente'; 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	import Editor from '@tinymce/tinymce-vue' 

	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 


	@Component({
		components: { 
		 	'editor': Editor
        },
	 })
    export default class AudioCadastro extends Vue {


		@gerente.State
		public modulo!: any
 
		@gerente.State
		public disciplina!: any
 
		@gerente.State
		public assunto!: any

        public colors: any = []; 

		public assuntos: any = null;
        public anterior: any = null;
        public modulos: any = null;
        public disciplinas: any = null;  
         

		public titulo: any = null; 
		public conteudo: any = null; 
		public nivel: any = null; 
		public tag_id: any = null; 
		public pergunta_id: any = null; 
		public tipo: any = null; 
		public videoaula: any = null; 
		public audio: any = null; 
		public assunto_id: any= null; 
		

		public proximo_secundario_id: any = null; 
		public fonte_id: any = null; 
		public proximo_id: any = null; 
		public anterior_id: any = null; 



 
		public modulo_id: any= null;  
		public disciplina_id: any= null; 
		 

		public urlRetorno: any= '/v2/administracao/card';

		public errors: any = { };



		

		@Watch('disciplina_id')
		disciplinaAlterada(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = null ;
			this.assunto_id = null ; 
		}
		

		@Watch('tipo')
		tipoAlterada(newVal: any) {
			if(this.tipo == 3){
				this.titulo = "pergunta";
			} 
		}


        created() {  
			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			} 
 
			if(this.disciplina){
				this.disciplina_id = this.disciplina.id 
			} 
 
			if(this.assunto){
				this.assunto_id = this.assunto.id 
			}

			this.modulo_id = this.modulo.id ;

			if( this.$route.params.anterior  ){
				this.anterior_id = this.$route.params.anterior;
				this.buscarAnterior();
			}

			if( this.$route.params.nivel  ){
				this.nivel = this.$route.params.nivel; 
			}

			this.colors = manzaTinyEditorColorsMap;
 
			moduloService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			});

			moduloService.buscarTodasDisciplina( this.modulo.id ) 
			.then(response => {
				this.disciplinas = response ; 
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			}); 
  
		}
		
		
		buscarAssunto( id: any) { 
			if(this.disciplinas){
				const filtrada = this.disciplinas.filter( ( disciplina: any) => { 
					if (disciplina.id == id) {
						return true;
					}
				})[0]; 
				if(filtrada){
					this.assuntos = filtrada.assuntos ;
				} 
			}
		}

		

        buscarAnterior() { 
			 
			cardService.getCard( this.anterior_id )
			.then(response => { 
				this.anterior = response ;
				this.disciplina_id = response.disciplina_id ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				 
				window.toastErro('Não foi possivel achar Card');
			});   
        }
 
		salvar() {
            window.alertProcessando();  
			this.salvarNormal(); 
		} 
 
		salvarNormal() { 
			const dados = {
				modulo_id: this.modulo_id,
				titulo: this.titulo,
				nivel: this.nivel,
				audio: this.audio,
				tipo: this.tipo,
				disciplina_id: this.disciplina_id,
				proximo_secundario_id: this.proximo_secundario_id,
				proximo_id: this.proximo_id,
				anterior_id: this.anterior_id,
				fonte_id: this.fonte_id,
				videoaula: this.videoaula,
				pergunta_id: this.pergunta_id,
				tag_id: this.tag_id,
				conteudo: this.conteudo,
				assunto_id :this.assunto_id
			}    
			cardService.cadastrar (  dados )
			.then(response => {
				window.toastSucesso(response.message);  
				window.alertProcessandoHide();
				this.$router.push(  '/v2/administracao/card/show/' + response.model.id  )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				window.toastErro(error.data.message);
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	}
	
   
