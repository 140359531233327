

























































 
 
	import { disciplinaAdminService }  from '@/servicegerente';


	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class DisciplinaCadastro extends Vue {

		private nome: any = null;
		private sigla: any= null;
		private meta: any= null;
		private descricao: any= null;

		private urlRetorno: any= '/disciplina';

		private errors: any = { };


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao, 
				sigla :this.sigla, 
				meta :this.meta,  
			};  
			 
			 
			disciplinaAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

	}
 
