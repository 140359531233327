































	
	import { assuntoService, moduloService , moduloAdminService }  from '@/servicegerente';  
	import $ from "jquery"  
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
    import axios from 'axios';
    import FormModuloDisciplinasAssuntos from './_AssuntoFormAdicionar.vue'  

    @Component({
        components: {
            'formAddAssuntoToModulo':  FormModuloDisciplinasAssuntos,
        },
    })
    export default class AssuntoIndex extends Vue {

        @usuario.Getter
		public isAdmin!:  (  ) => boolean
        
        public datatableKey = 0; 
		public  reloadDatatable = false ; 

        @gerente.State
		public modulo! 
 
        @gerente.State
		public disciplina!

        public assuntos: any= null;  
  
		buscarAssunto() {
            if(this.disciplina){
                window.alertProcessando();
                moduloAdminService.buscarAssunto( this.modulo.id , this.disciplina.id )   
                .then(response => {
                    this.assuntos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os Assuntos para adiocionar", error.data);
                    window.alertProcessandoHide();
                });   
            } 
		}
 
		assuntoAdicionado(event: any) {
			this.assuntos = event;
			this.reloadDatatable = !this.reloadDatatable; 
		}
 
        public atualizaUrl( ) {   
            this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/assuntos/card/datatable' 
        }
 
        created() {
            if(!this.modulo  ){
				this.$router.push('/v2/administracao'); 
			}
            else if( !this.disciplina ){
				this.$router.push('/v2/administracao/disciplina'); 
			}
            else {
                 this.atualizaUrl( );
            } 
            this.buscarAssunto();
        }
 
		public config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url:  assuntoService.getUrl() + '/datatable', 
			},
			columns: [
				{ data: 'id', name: 'assunto.id' , class: 'text-center' },
				{ data: 'nome', name: 'assunto.nome' },   
				{ data: 'cards_count', name: 'cards_count', class: 'text-center' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};
 
		public tableatualizada( ) {  
             
            $('[btn-excluir]').click(  (btn) => {  
                if( this.disciplina){
                    const id =   $(btn.currentTarget).data('id');  
                    window.alertConfimacao('Confirma a Exclusão ','' , 
                        () => {  
                            window.alertProcessando(); 
                            moduloAdminService.removerAssuntoCard( this.modulo.id , this.disciplina.id ,  id )
                            .then(response => {
                                window.alertProcessandoHide();  
                                this.assuntos = response;
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.toastSucesso('Assunto removido com sucesso!!' ); 
                            })
                            .catch(error => { 
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.alertProcessandoHide();
                                window.toastErro('Não foi possivel remover a Assunto' , ''); 
                            });  
                        }
                    ); 
			    } 
            }); 
		}
		 
	}



 

