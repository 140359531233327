import { render, staticRenderFns } from "./_TagFormAdicionar.vue?vue&type=template&id=edb4eb32&scoped=true"
import script from "./_TagFormAdicionar.vue?vue&type=script&lang=ts"
export * from "./_TagFormAdicionar.vue?vue&type=script&lang=ts"
import style0 from "./_TagFormAdicionar.vue?vue&type=style&index=0&id=edb4eb32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edb4eb32",
  null
  
)

export default component.exports