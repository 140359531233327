

require('./assets/js/jquery');

require('./assets/js/vendor');

require('./assets/js/css');

require('./assets/js/app'); 



// require('admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js'); 
require('admin-lte'); 


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

 

// Modal
import Modal from './components/_core/adminlte/Modal.vue'
Vue.component('custom-modal', Modal );


//Accordion
import Accordion from './components/_core/adminlte/Accordion.vue'
Vue.component('custom-accordion', Accordion);



// Carousel
import Carousel from './components/_core/adminlte/Carousel.vue'
Vue.component('custom-carousel', Carousel);



// Progress Bars
import ProgressBars from './components/_core/adminlte/ProgressBars.vue'
Vue.component( 'custom-progress-bars', ProgressBars );




//  TabNavabar 
import TabNavabar  from './components/_core/adminlte/TabNavabar.vue'
Vue.component( 'tab-custom-nav-tabs', TabNavabar );


//  ContentNavabar
import ContentNavabar   from './components/_core/adminlte/ContentNavabar.vue'
Vue.component( 'content-custom-nav-tabs', ContentNavabar );


// Nav Tabs 
import NavTabs  from './components/_core/adminlte/NavTabs.vue'
Vue.component( 'custom-nav-tabs', NavTabs );



// SmallBox 
import SmallBox  from './components/_core/adminlte/SmallBox.vue'
Vue.component( 'small-box', SmallBox );





// Alert 
import Alert  from './components/_core/adminlte/Alert.vue'
Vue.component( 'alert-button', Alert );





// SubMenu 
import SubMenu  from './components/_core/adminlte/SubMenu.vue'
Vue.component( 'sub-menu', SubMenu );



// Menu 
import Menu  from './components/_core/adminlte/Menu.vue'
Vue.component( 'item-menu', Menu );



//CARD
import Card from './components/_core/adminlte/Card.vue'
Vue.component('custom-card', Card );



Vue.component('datatable', require('./components/_core/datatable/datatable.vue').default);
// Vue.component('simplestable', require('./components/_core/datatable/simplestable.vue').default);







Vue.component('router-link-custom', require('./components/_core/botoes/router-link.vue').default);
Vue.component('button-custom', require('./components/_core/botoes/button.vue').default);

Vue.component('card-custom', require('./components/_core/card/card.vue').default);


Vue.component('form-custom', require('./components/_core/formulario/formulario.vue').default);
Vue.component('campo-custom', require('./components/_core/formulario/campo.vue').default);
Vue.component('input-simples', require('./components/_core/formulario/input.vue').default);
Vue.component('checbox-simples', require('./components/_core/formulario/CheckBox.vue').default);


Vue.component('btn-salvar', require('./components/_core/crud/botaoSalvar.vue').default); 




Vue.component('crudCard', require('./components/_core/crud/card.vue').default); 
Vue.component('crudHeader', require('./components/_core/crud/header.vue').default); 
Vue.component('crudBotaoExcluir', require('./components/_core/crud/botaoExcluir.vue').default); 
Vue.component('crudBotaoVoltar', require('./components/_core/crud/botaoVoltar.vue').default); 
Vue.component('crudBotaoSalvar', require('./components/_core/crud/botaoSalvar.vue').default); 
Vue.component('crudFormElemento', require('./components/_core/crud/ElementoForm.vue').default); 
Vue.component('Formulario', require('./components/_core/crud/Formulario.vue').default);  
Vue.component('select2', require('./components/_core/crud/SelectComponente.vue').default);  
Vue.component('selectAjax', require('./components/_core/crud/selectAjax.vue').default);

Vue.component('multiselect2', require('./components/_core/crud/MultiSelect.vue').default);  
Vue.component('multiselect2Ajax', require('./components/_core/crud/MultiSelect2Ajax.vue').default);  




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
