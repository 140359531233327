


























    /* eslint-disable @typescript-eslint/camelcase */  
    import { perguntaService } from '@/servicegerente'; 
    import TagFormAdicionar from './_TagFormAdicionar.vue'; 
    import $ from "jquery"; 
    import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente')


    @Component({
        components: { 
            'tag-form-adicionar': TagFormAdicionar
        },
    })
    export default class Principal extends Vue { 

        @usuario.Getter
		public isAdmin!:  (  ) => boolean
 
        @gerente.State
		public conceito!: any
 
        @gerente.State
		public conceitos!: any

        @Prop( ) readonly pergunta!: any

        public tags: any= null;  
        
        public reloadDatatable: any= false; 

        created() {
            this.buscarTag(); 
        }
             
        public config: any= {  
            order: [  [1, "asc"] ],
            lengthMenu : [ [ -1],   [  "Todos"]  ],
            dom: '<"top"r>t<"bottom"><"clear">',
            ajax: {
                url: perguntaService.getUrl() + '/' + this.$route.params.id + '/tag/datatable'
            },
            columns: [
                {  data: 'nome', name: 'tag.nome'  },
                { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center' }
            ],
        };  
        
        
        tagAdicionada(event: any) {
            this.tags = event; 
            this.$emit('tagAalterada', event);
            this.reloadDatatable = !this.reloadDatatable;
        }

        reload(event: any) {
            this.buscarTag();
            this.reloadDatatable = !this.reloadDatatable; 
        }

        
        AdicionarTags( item: any ) {
            if (item) { 
                window.alertProcessando();
                const dados = {
                    tag : item.id,  
                };    
                perguntaService.adicionarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.reloadDatatable = !this.reloadDatatable; 
                    this.tags = response;
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 


        AdicionarTag() {
            if (this.conceito) { 
                window.alertProcessando();
                const dados = {
                    tag : this.conceito.id,  
                };    
                perguntaService.adicionarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.reloadDatatable = !this.reloadDatatable; 
                    this.tags = response;
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 


        buscarTag() {
            window.alertProcessando();
            perguntaService.buscarTag(this.$route.params.id )
                .then(response => {
                    this.tags = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os Tags para adiocionar", error.data);
                    window.alertProcessandoHide();
                });
        }


        @Watch('pergunta')
		changePergunta(newVal: any) {
			 this.buscarTag();
        }
        

         public tableatualizada( ) {  
            console.log("tableatualizadaTag");

			$('#tabletagpergunta [btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perguntaService.removerTag( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
                            this.tags = response;
                            this.$emit('tagAalterada', event);
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Permissao removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover a Permissao' , ''); 
                        });  
                    }
                ); 
            }); 
        } 

    } 

