import { authHeader } from '..'; 
import { segurancaModel } from './index.model'; 
  
import axios  from 'axios';  
 
const url = segurancaModel.getUrl() + '/historico' ;  

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}



function getHistorico( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

 
 
export const historicoService = {
	getHistorico,
	getUrl, 
};