



















	
	import { respostaAdminService  }  from '@/servicegerente';
    import $ from "jquery"  

	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class RespostaIndex extends Vue { 

        private  reloadDatatable = false ; 

        private config = { 
			order: [[ 0, "desc" ]],
			ajax: { 
				url: respostaAdminService.getUrl() + '/datatable'
			},
			columns: [
			{ data: 'id', name: 'id'  },
			{ data: 'texto', name: 'texto' }, 
			{ data: 'pergunta_id', name: 'pergunta_id' }, 
			{ data: 'count', name: 'count'  }, 
			{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};
 


		public tableatualizada( ) {  
             
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/resposta/edit/' + id);
            });
            
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        respostaAdminService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        respostaAdminService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        respostaAdminService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

		}
		

	}
	
 

