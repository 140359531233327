



























































    /* eslint-disable @typescript-eslint/camelcase */  
    import {   ementaService } from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
 
    @Component({
        components: { },
    })
    export default class IndexGuia extends Vue { 
 
        @gerente.State
		public assunto!: any
   
 
        @gerente.State
		public modulo!: any
   
        @gerente.State
		public ementa!: any
  
        @gerente.State
		public disciplina!: any
 
        @gerente.Action
        public alterarEmenta!: ( ementa: any  ) => void
 
 
        @gerente.Action
        public resetEmenta!: (  ) => void
 
        public  reloadDatatable = false ; 
        
        public config = {  
            order: [  [1, "asc"] ],
            ajax: {
                url: ementaService.getUrl() + '/datatable',
            },
            columns: [ 
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'nome',  name: 'nome'  },   
                { data: 'ementatags_count',  name: 'ementatags_count'  },  
                { data: 'ativo',  name: 'ativo'  },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ], 
        };
  
        created() { 
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
                return;
			} 
            if(this.assunto){
				this.config.ajax.url =  ementaService.getUrl() + '/assunto/' + this.assunto.id + '/datatable'
			} 
            else if(this.disciplina){
				this.config.ajax.url =  ementaService.getUrl() + '/modulo/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/datatable'
			} 
            else{
                this.config.ajax.url =  ementaService.getUrl() + '/modulo/' + this.modulo.id + '/datatable'
            } 
        }
 
        public tableatualizada( ) {   
            
            
            $('[btn-select]').click(  (btn) => {   
                this.alterarEmenta($(btn.currentTarget).data( ));
                window.toastSucesso('Ementa selecionada com sucesso!!' );  
            });


            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/ementa/edit/' + id);  
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/administracao/ementa/show/' + id);
            });  
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        ementaService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

        }
    }
 
