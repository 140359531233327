



























 

	import FormUsuarioPerfil from './_PerfilFormAdicionar.vue'
	 
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
    import $ from "jquery" 

	import { userService  }  from '@/servicegerente';

	@Component({
		components: { 
			'perfil-form-adicionar': FormUsuarioPerfil,
        },
	 })
    export default class UsuarioPerfil extends Vue {

		private usuario: any= null; 
		private perfis: any= null; 
		private reloadDatatable: any= false; 
		 
		private url_retorno: any= '/usuario'; 
		private url_historico: any= '/usuario/' + this.$route.params.id + '/perfil/historico'; 
		 

		private config: any= { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: userService.getUrl() + '/' + this.$route.params.id + '/perfil/datatable'
			},
			columns: [
				{ data: 'perfil_id', name: 'perfils_users.perfil_id'  },
				{ data: 'nome', name: 'perfils.nome' },
				{ data: 'descricao', name: 'perfils.descricao' }, 
				{ data: 'id_responsavel', name: 'perfils.pivot.id_responsavel' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  
 
 
		 

		created() { 
			window.alertProcessando();
			userService.getUsuario( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.usuario = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Usuário','');
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}


		@Watch('usuario')
		usuarioAlterado(newVal: any) {
			this.buscarPerfis();
		}
 

		perfilAdicionado(event: any) {
			this.perfis = event; 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		buscarPerfis() {
			window.alertProcessando();
			userService.buscarPerfis( this.$route.params.id )   
			.then(response => {
				this.perfis = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os Perfis para adiocionar", '');
				window.alertProcessandoHide();
			});   
		}


		public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        userService.removerPerfil( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
							this.perfis = response;
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Perfil removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover o perfil' , ''); 
                        });  
                    }
                ); 
            });

        }


	}

	 

