







































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { tagAdminService }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        private url_retorno: any = '/tag' ;
        private model: any = null ;

        private config = { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: tagAdminService.getUrl() + '/' + this.$route.params.id + '/assuntos/datatable'
			},
			columns: [
			{ data: 'nome', name: 'assunto.nome' },
			{ data: 'descricao', name: 'assunto.descricao' }, 
			],
		};


		created() { 
			window.alertProcessando();
			tagAdminService.getTag( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				 
				window.toastErro('Não foi possivel achar Tag');
			}); 
		}


	}
 

