






















































/* eslint-disable @typescript-eslint/no-explicit-any */ 
/* eslint-disable @typescript-eslint/no-unused-vars */ 
      
	import { audioAulaService , moduloService }  from '@/servicegerente';  
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 
    const usuario = namespace('usuario')
    
    @Component({
        components: { },
    })
    export default class AudiosIndex extends Vue { 
         
        @usuario.Getter
		public isAdmin!:  (  ) => boolean
 
        @gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public assunto!: any
 
        @gerente.Action
        public resetDisciplina!: (   ) => void

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
 
        @gerente.Action
        public resetAssunto!: (   ) => void

        public limparAssunto( ) {  
            this.resetAssunto();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
  
        public atualizaUrl( ) {  
            if(!this.disciplina){
				 this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/audios/datatable'
			}
			else if(!this.assunto){
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/audios/datatable'
			}
            else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id +'/assunto/' + this.assunto.id + '/audios/datatable'
            } 
        }
 
        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                 this.atualizaUrl( );
            }  
        }

        public datatableKey = 0;

        public  reloadDatatable = false ; 

        public config = {
            order: [[ 1, "asc" ]],
			ajax: { 
				url: moduloService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'titulo', name: 'titulo' },  
				{ data: 'assunto.nome', name: 'assunto.nome' },  
				{ data: 'restrito', name: 'restrito' }, 
				{ data: 'acessos', name: 'acessos' }, 
				{ data: 'tipo', name: 'tipo' }, 
				{ data: 'cards_count', name: 'cards_count', class: 'text-center' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
        };


        public tableatualizada( ) {   
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/audio/edit/' + id ); 
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.open('#/v2/administracao/audio/show/' + id); 
            }); 
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        audioAulaService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            }); 
        } 
	}
	 

