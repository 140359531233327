





































































































































	/* eslint-disable @typescript-eslint/camelcase */  
    import { moduloService , noticiaService  , manzaTinyEditorColorsMap } from '@/servicegerente'; 
    import Editor from '@tinymce/tinymce-vue';  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	   
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
 
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class GuiaCadastro extends Vue {

		
		@gerente.State
		public modulo!: any

        public colors: any = [];  
        public modulos: any = null;
        
		public nome: any= null; 
        public descricao: any= null;
        public resumo: any= null;
		public modulo_id: any= null; 
		public imagem: any= '/img/livros.png'; 
		public has_descricao: any= false; 
         
		public urlRetorno: any= '/v2/administracao/noticia';

		public errors: any = { };
	 
		created() { 

			if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else{

				this.colors = manzaTinyEditorColorsMap; 

				this.modulo_id = this.modulo.id ;

				moduloService.getAll() 
				.then(response => {
					this.modulos = response ;
				})
				.catch(error => {
					window.toastErro('Não foi possivel achar as modulos');
				}); 
			}
			
		}

 

		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome, 
				descricao :this.descricao,  
				resumo :this.resumo,  
				modulo_id :this.modulo_id,  
				imagem :this.imagem,  
				has_descricao :this.has_descricao,   
			};  
			 
			 
			noticiaService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }
    
 
