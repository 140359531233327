import { VuexModule, Module,  Mutation, Action } from 'vuex-module-decorators'
import { userService } from '@/servicegerente';  

import  router  from '@/router';  

const KJUR = require('jsrsasign');  


const user = localStorage.getItem('user');  


@Module({
    namespaced: true,
    // name: 'usuario'
})

class User extends VuexModule {
    
    
    
    public token: string = user ?  JSON.parse( user )  : null;
    public user: string = user ? KJUR.jws.JWS.parse( JSON.parse( user ) ).payloadObj.user : null;
    public status:   any  =  user ? {   loggedIn: true } : {} ;
    public mensagem =  '';
    public perfis:  any  = user ? KJUR.jws.JWS.parse(JSON.parse( user )).payloadObj.perfis : null;
    public endereco: string =  user ? KJUR.jws.JWS.parse(JSON.parse( user )).payloadObj.endereco : null;
    

    get isAdmin(): boolean { 
        if (this.perfis) {
            return (
                this.perfis.filter( ( perfil: any) => {
                    if (perfil == "Admin") {
                        return true;
                    }
                }).length > 0
            );
        }
        return false;
    }
    
    
    @Mutation
    public loginSuccess(  token: any   ) { 
        const user = KJUR.jws.JWS.parse( token ).payloadObj 
        this.status = { loggedIn: true };
        this.user = user.user;
        this.token = token;
        this.mensagem = '';
        this.perfis =  user.perfis; 
        this.endereco =  user.endereco; 
    }
    
    @Mutation
    public loginFailure( error: any) {
        this.status = {loggedIn: false};
        this.user = '';
        this.token = '';
        this.mensagem = error;
        this.perfis = '';
        this.endereco = '';
    }
    
    
    
    
    @Mutation 
    public logoutMutation( ) { 
        this.status = { loggedIn: false };
        this.user = '';
        this.token = ''; 
        this.mensagem = '';
        this.perfis = ''; 
        this.endereco =  ''; 
    }



    // @Action
    // public loginDireto(token: any) {  
    //     localStorage.removeItem('user');
    //     localStorage.setItem('user', JSON.stringify(token));
    //     this.context.commit('loginSuccess',   token  )   
    // }


    @Action
    public login(  dados: any   ): Promise<any>{   
        return new Promise((resolve, reject) => {
        userService.login(dados.username, dados.password)
            .then( user => { 
                this.context.commit('loginSuccess',   user  ) 
                resolve(null);
            })
            .catch(error => {  
                this.context.commit('loginFailure',error ) 
                reject(error);  
            });  
        }) 
    }


    @Action
    public logout( ): void {
        userService.logout(); 
        this.context.commit('logoutMutation' ) ; 
        router.push('/')
        // router.push('/login')
    }



}
export default User