














































   
 
	import { userService }  from '@/servicegerente';

	import { Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class Apple extends Vue {

		public assinatura: any = null; 
		public subscriptions: any = null; 
		public dados: any = null; 
		 
		public urlRetorno: any= '/v2/administracao/configuracao';
  
        verifyGoogle() { 
            window.alertProcessando();  
            userService.verifyGoogle( this.assinatura )
            .then(response => {
                window.alertProcessandoHide(); 
                this.dados = response;
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
                window.toastErro('Não foi possivel adiciona ' , error.data.message); 
            });   
        } 


        verifyGoogleV1() { 
            window.alertProcessando();  
            userService.verifyGoogleV1( this.subscriptions , this.assinatura )
            .then(response => {
                window.alertProcessandoHide(); 
                this.dados = response;
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
                window.toastErro('Não foi possivel adiciona ' , error.data.message); 
            });   
        } 
 
	}
 
