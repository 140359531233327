
























































































 
    /* eslint-disable @typescript-eslint/camelcase */ 
	 
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { perguntaService , disciplinaService , moduloService , assuntoService}  from '@/servicegerente'; 
	import $ from "jquery" ;
    import { authHeader } from '@/servicegerente';  
    import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente')  

	@Component({
		components: {   },
	 })
    export default class FormPerfilPermissao extends Vue { 
        
        @Prop( ) readonly modulo!: any 
 
        @gerente.State
		public assunto!: any
 
        public assuntos: any = null; 

        public assunto_id: any = null; 

        public disciplinas: any = null; 
		public disciplina: any = null;

        public modulos: any = null; 
		public modulo_id: any = null;

        public add_assunto_id = 'modal-seguranca-pergunta-adicionar-assunto'; 


        public assunto_ajax: any = null; 

        @Watch('disciplina')
		alterouDisciplina(newVal: any) {
            this.assuntos = []; 
            this.assunto_id = null ; 
			if(this.disciplina){
				this.buscarAssunto(this.disciplina); 
			}  
		}


        
        @Watch('modulo_id')
		alterouModulo(newVal: any) {  
            this.assuntos = [] ; 
			this.assunto_id = null ; 
			this.disciplinas = [] ; 
			this.disciplina = null ;  
            if(this.modulo_id){
				moduloService.buscarTodasDisciplina( this.modulo_id ) 
                .then(response => {
                    this.disciplinas = response ;
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar as disciplinas');
                });   
			} 
		}
 
        created() {    
            this.modulo_id = this.modulo.id;
            this.buscarModulos( );  
		}
 
        buscarAssunto(id: any) { 
            window.alertProcessando();
            perguntaService.buscarAssuntoDisciplina(this.$route.params.id , id , this.modulo_id)
                .then(response => {
                    this.assuntos = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os assuntos para adiocionar", error.data);
                    window.alertProcessandoHide();
                });
        }
 
        buscarModulos( ) {  
            moduloService.getAll( )
                .then(response => {
                    this.modulos = response; 
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os cursos", error.data); 
                });
        }
 
        AdicionarAssunto() {
            if (this.assunto_id) { 
                window.alertProcessando();
                const dados = {
                    assunto : this.assunto_id  
                };    
                perguntaService.adicionarAssunto( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("assunto adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response);  
                    // ( $('#' + this.add_assunto_id) as any ) .modal('toggle');  
                    // this.disciplina = null ;
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 
 
 
        AdicionarAssuntoSelecionado() {
            if (this.assunto) { 
                window.alertProcessando();
                const dados = {
                    assunto : this.assunto.id  
                };    
                perguntaService.adicionarAssunto( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("assunto adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response);   
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 
 
        AdicionarAssuntoAjax() {
            if (this.assunto_ajax) { 
                window.alertProcessando();
                const dados = {
                    assunto : this.assunto_ajax 
                };    
                perguntaService.adicionarAssunto( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("assunto adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response); 
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 
        
        public ajaxOptions = {
            url: assuntoService.getUrl() + '/buscar/ajax'  ,  
            dataType: 'json',
            type: "GET",
            delay: 1000,
            headers: authHeader(), 
            data: function(params: any) {
                return {
                    term: params.term,  
                };
            },
            processResults: function(data: any ) {
                return {
                    results: $.map(data, function (item) {
                    return {
                        text: item.nome    , 
                        id: item.id
                    }
                }) 
                }	 
            },
            cache: true
        } 

    }
 
