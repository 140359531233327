










































































	
	import { assuntoService }  from '@/servicegerente';
	
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class LocalizarPerguntaResposta extends Vue { 

        private filtro: any = null;
        private assunto: any = null;
        private perguntas: any = null; 
 

		created() {  
			this.buscarAssunto(); 
		}

		
		limpar() {    
			this.perguntas = null ;  
		}
		 

		
		buscarAssunto(   ) {   
			assuntoService.getAssunto( this.$route.params.id )
			.then(response => {
				this.assunto = response ; 
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar Assunto');
			});  
		}
		
		
		buscarPerguntas(   ) {   
            if( this.filtro == null ||  this.filtro ==  '' ){
                return;
            } 
			window.alertProcessando();
			assuntoService.buscarPerguntasComRespostas( this.$route.params.id , { filtro: this.filtro  } )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			});  
		}
		 



         
		buscarPerguntasComRespostasCerta(   ) {   
            if( this.filtro == null ||  this.filtro ==  '' ){
                return;
            } 
			window.alertProcessando();
			assuntoService.buscarPerguntasComRespostasCerta( this.$route.params.id , { filtro: this.filtro  } )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			});  
		}
		 
		
		 

         
		buscarPerguntasTexto(   ) {   
            if( this.filtro == null ||  this.filtro ==  '' ){
                return;
            } 
			window.alertProcessando();
			assuntoService.buscarPerguntasTexto( this.$route.params.id , { filtro: this.filtro  } )
			.then(response => {
				this.perguntas = response ;
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.toastErro('Não foi possivel achar as perguntas');
				window.alertProcessandoHide();
			});  
		}
		 
		
		 
		 

	}
	 
