



























































    /* eslint-disable @typescript-eslint/camelcase */ 
 
    import Editor from "@tinymce/tinymce-vue";

    import { respostaAdminService } from '@/servicegerente';


    import {Watch, Component, Vue } from 'vue-property-decorator';
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoEdit extends Vue {
		
		private model: any = null;


		private texto: any = null;
		private correta: any= 'false'; 

		private urlRetorno: any= '/resposta';
 
        private errors: any = { };


        created() {   
			window.alertProcessando();
			respostaAdminService.getResposta( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Plano', error.data);
				window.alertProcessandoHide();
			}); 
		}
 
		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.texto = this.model.texto; 
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				texto :this.texto,
				correta :this.correta,  
			};  
			 
			 
			respostaAdminService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
        } 
         
    }
 
