


























 
    
    import { moduloAdminService } from '@/servicegerente'; 

    import $ from "jquery"  

    import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: { 
        },
    })
    export default class ModuloIndex extends Vue {

		private  reloadDatatable = false ; 

		private config = { 
            order: [  [1, "asc"] ],
            ajax: {
                url: moduloAdminService.getUrl() + '/datatable'
            },
            columns: [
                { data: 'id', name: 'id' },
                { data: 'nome', name: 'nome'  },
                { data: 'descricao',  name: 'descricao'  },
                { data: 'status',  name: 'status' },
                { data: 'restrito',   name: 'restrito' },
                { data: 'url',   name: 'url' },
                { data: 'urlresposta',   name: 'urlresposta' },
                {  data: 'action',  name: 'action', orderable: false, searchable: false, class: 'text-center' }
            ],
		};



		public tableatualizada( ) {   
            
            $('[btn-visualizar-disciplina]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/modulo/' + id + '/disciplina');
            });

            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/modulo/edit/' + id  );
            });

			$('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        moduloAdminService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        moduloAdminService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
			});
			
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        moduloAdminService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

		}
		 
	}


 

