



























    /* eslint-disable @typescript-eslint/camelcase */ 
    import {Prop, Component, Vue } from 'vue-property-decorator'; 
	import { ementaAssuntoService ,  assuntoService}  from '@/servicegerente'; 
	import $ from "jquery" ;
    import { authHeader } from '@/servicegerente';  

	@Component({
		components: {  },
	 })
    export default class FormAdicionarAssunto extends Vue {
  
		 
		public assunto_id: any = null;
		  
        public add_permissao_id = 'modal-emeta-adicionar-assunto'; 
        
        public AdicionarAssunto() {
            if (this.assunto_id) { 
                window.alertProcessando();
                const dados = {
                    ementa_id :this.$route.params.id,  
                    assunto_id :this.assunto_id, 
                };    
                ementaAssuntoService.cadastrar( dados )
                .then(response => {
                    window.toastSucesso("permissao adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('ementaAssuntoAdicionada', response); 
                    this.assunto_id = null; 
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500); 
            }
        }  
 
        public processa(params: any){
            return {
                term: params.term,   
            };
        }

        public ajaxOptions = {
            url: assuntoService.getUrl() + '/buscar/ajax'  ,  
            dataType: 'json',
            type: "GET",
            delay: 1000,
            headers: authHeader(), 
            data: this.processa, 
            processResults: function(data: any ) {
                return {
                    results: $.map(data, function (item) {
                        try {
                            const disc = item.disciplina.nome;
                            return {
                                text: item.nome  + " (" + disc + ")"  , 
                                id: item.id
                            }
                        } catch (error) {
                            return {
                                text: item.nome  , 
                                id: item.id
                            }
                        } 
                        
                }) 
                }	 
            },
            cache: true
        } 
 
    } 
 
