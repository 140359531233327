

















 
 

	import { planoService   }  from '@/servicegerente';
 
	import $ from "jquery"  

    import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class PlanoUsuarios extends Vue {

      

        private plano: any= null;  
        private reloadDatatable: any= false;  
		private url_retorno: any=  '/admin/plano'  ;  
		 

		private config: any= {  
            order: [[ 2, "desc" ]],
			ajax: { 
				url: planoService.getUrl() + '/' + this.$route.params.id +  '/usuarios/datatable'
			},
			columns: [
			{ data: 'user_id', name: 'user_id' },
			{ data: 'nome', name: 'nome' },
			{ data: 'data_fim', name: 'usuario_plano.data_fim' },   
			],
		};  
		

			 

			
 

        created() {
			window.alertProcessando();  
			planoService.getModel( this.$route.params.id )  
			.then(response => {
				 window.alertProcessandoHide();
				this.plano = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o plano', error.data);
				window.alertProcessandoHide(); 
			});   
        }

	  


	}

  

