/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '@/servicegerente'; 

import { gerenteModel } from './index.model'; 
import axios  from 'axios'; 
 
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js';

const secret_key =  gerenteModel.getKeyCripto() ;



const url = gerenteModel.getUrl() + '/resumo' ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}

























function getResumo( id: any , api = false ) {  
	return  new Promise((resolve, reject) => {   
		const resumo = localStorage.getItem('global_model_resumo_id_' + id );   
		if(resumo && !api){  
			const data_criacao = localStorage.getItem('data_global_model_resumo_id_' + id  );   
			if( moment()  < moment( data_criacao ).add( 2 , 'days') ){
				const bytes  = CryptoJS.AES.decrypt(resumo, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			const encripted_resumo = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem( 'global_model_resumo_id_' + id , encripted_resumo  );    
			localStorage.setItem('data_global_model_resumo_id_' + id  , moment().format('YYYY-MM-DD')  );  
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});   
}
 


function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


	function cadastrar(   dados: any ) { 
	
		const head: any = authHeader(); 
		head["Content-Type"] = "multipart/form-data"; 
		
		return  new Promise((resolve, reject) => {  
			axios.post( url  , dados , { headers: head }  )
			.then(response => {    
				resolve( response.data);  
			})
			.catch(error => {  
				if ( error.response.status === 401) {
					logout();				
				}
				reject(error.response);
			}) 
		}); 
	}


 


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


export const resumoService = { 
	getUrl,
	getResumo,

	cadastrar,
	update,
	desativar,
	// excluir,
	// ativar,
};