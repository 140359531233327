/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '@/servicegerente';  
import { gerenteModel } from './index.model'; 
import axios  from 'axios';   
const url = gerenteModel.getUrl() + '/pergunta' ;  
import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}



function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		// axios.delete( url + '/' + id , {headers: authHeader() }  )
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




//TODO: CACHE
function getPergunta( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function alterarResposta( id: any,  data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/alterar/resposta/' + id ,  data , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}










 
function buscarTag( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  + "/tag/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function removerTag( id: any , tag: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/tag/' + tag ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function adicionarTag( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/tag" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}












function removerAssunto( id: any , assunto: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/assunto/' + assunto ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


// function buscarAssunto( id: any ) { 
// 	return  new Promise((resolve, reject) => {
// 		axios.get( url + '/' + id +  "/assunto/adicionar" , {headers: authHeader() }  )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			if ( error.response.status === 401) {
// 				logout();				
// 			}
// 			reject(error.response);
// 		}) 
// 	}); 
// }



function buscarAssuntoDisciplina( id: any , disciplina: any , modulo: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + '/disciplina/' + disciplina + '/modulo/' + modulo +  "/assunto/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function adicionarAssunto( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/assunto" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}










// function cadastrarApi(   dados: any ) { 
// 	return  new Promise((resolve, reject) => {  
// 		axios.post( url + '/cadastrar/api/lote'  , dados , {headers: authHeader() }  )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			if ( error.response.status === 401) {
// 				logout();				
// 			}
// 			reject(error.response);
// 		}) 
// 	}); 
// }


// function cadastrarApiGran(   dados: any ) { 
// 	return  new Promise((resolve, reject) => {  
// 		axios.post( url + '/cadastrar/api/lote/gran'  , dados , {headers: authHeader() }  )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			if ( error.response.status === 401) {
// 				logout();				
// 			}
// 			reject(error.response);
// 		}) 
// 	}); 
// }


































  
 


  
 

function formatarResposta( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/formatar/resposta/' + id ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  



 

function limparCache( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/'  + id  + '/limpar/cache' ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  



function restritar( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/'  + id  + '/restritar' ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  


function limparSubject( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/'  + id  + '/limparSubject' ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  


function questionairar( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/'  + id  + '/questionairar' ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  


function finalizar( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/'  + id  + '/finalizar' ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  


function finalizarlight( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/'  + id  + '/finalizar/restrito' ,  {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  




 
function buscarReferencia( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  + "/referencia/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}






// function ativar( id: any ) { 
// 	return  new Promise((resolve, reject) => {  
// 		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
// 		.then(response => {    
// 			resolve( response.data);  
// 		})
// 		.catch(error => {  
// 			if ( error.response.status === 401) {
// 				logout();				
// 			}
// 			reject(error.response);
// 		}) 
// 	}); 
// }






















 
 









export const perguntaService = {
	getPergunta,
	getUrl,
	alterarResposta,
	restritar,
	limparSubject,
	questionairar,
	buscarTag,
	removerTag,
	adicionarTag,
	
	finalizar,
	// buscarReferencia,

	update,
	cadastrar,
	desativar,
	// ativar,
	// excluir,

    // cadastrarApi,
    // cadastrarApiGran,
	limparCache,

	// buscarAssunto,
	removerAssunto,
	buscarAssuntoDisciplina,
	adicionarAssunto,

	formatarResposta,
	finalizarlight,
};