








































































































    import {   ementaService , manzaTinyEditorColorsMap } from '@/servicegerente'; 
	import $ from "jquery"   
    import {Watch, Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
    import Editor from '@tinymce/tinymce-vue';
      
    @Component({
        components: { 'editor': Editor },
    })
    export default class Principal extends Vue { 

        @usuario.Getter
		public isAdmin!:  (  ) => boolean

        @gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public assunto!: any

        public tags: any = null;
        public tagsConteudo: any = null;
        public model: any = null ;

        public colors: any = []; 
        
        @Watch('tags')
		tagsAtualizada(newVal: any) {
            this.tagsConteudo = '';
            this.tags.forEach( element => {
                this.tagsConteudo +=  element.tag.descricao;
            });
        }
        
        public editar( id: any) {    
            window.open('#/v2/administracao/conceito/edit/' + id);  
        }
        
        public viewPerguntas( id: any) {   
            window.open('#/v2/administracao/conceito/' +   id + '/perguntas');  
        }

        public atualizaUrl( ) {   
            ementaService.getTodosTags( this.$route.params.id )
			.then(response => {
				this.tags = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide(); 
				window.toastErro('Não foi possivel achar Tag');
			});  
        }


        created() {
            if(!this.modulo   ){
				this.$router.push('/v2/administracao'); 
                return;
			}
            else if( !this.disciplina   ){
				this.$router.push('/v2/administracao/disciplina'); 
                return;
			}
            else if(   !this.assunto ){
				this.$router.push('/v2/administracao/assunto'); 
                return;
			} 
			this.colors = manzaTinyEditorColorsMap; 

            window.alertProcessando();
            ementaService.getModel( this.$route.params.id )
            .then(response => {
                this.model = response ;
                this.atualizaUrl( );
                window.alertProcessandoHide();
            })
            .catch(error => {
                window.alertProcessandoHide(); 
                window.toastErro('Não foi possivel achar Configuração');
            }); 
        }

 
 
    }
 
