





























	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { assuntoAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormAssuntoTag extends Vue {

        @Prop( ) readonly assuntos!: any

        private importAssuntoId: any = null;

        private add_tag_id = 'modal-seguranca-assunto-importar-tag'; 


        importarTag() {
            if (this.importAssuntoId) { 
                window.alertProcessando();
                const dados = {
                    importAssuntoId :this.importAssuntoId, 
                };    
                assuntoAdminService.importarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('tagAdicionado', response);
                    ( $('#' + this.add_tag_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

	}

	 
