/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '@/servicegerente'; 

import { gerenteModel } from './index.model'; 
import axios  from 'axios'; 
 
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js';

const secret_key =  gerenteModel.getKeyCripto() ;
 

const url = gerenteModel.getUrl() + '/comentario' ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}





function getComentario( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
 


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


export const comentarioService = { 
	getUrl, 
	update, 
	getComentario, 
	excluir, 
};