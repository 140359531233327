
































































































    /* eslint-disable @typescript-eslint/camelcase */ 

    import {  perguntaAdminService  } from '@/servicegerente';

    import Tag from './Tag.vue'
    import Assunto from './Assunto.vue'
    import FormCriarResposta from './_FormCriarResposta.vue'
    import FormEditarResposta from './_FormEditarResposta.vue'
     
    import $ from "jquery"  

     
    import {Watch, Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')


    @Component({
        components: { 
            'perguntaAssunto': Assunto,
            'perguntaTag': Tag,
            'form-criar-resposta': FormCriarResposta,
            'form-edita-resposta': FormEditarResposta,
         },
    })
    export default class Principal extends Vue { 
 
        @usuario.State
		public perfis!: any 

        public model: any  = null;
        public liberado  = false; 
        public url_retorno: any  = "/pergunta";
     
        @Watch('$route')
		tabelaAtualizada(newVal: any) {
			this.buscarModel(); 
        }
         
        get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}
 
        created() {
            this.buscarModel(); 
        }
 
        buscarModel() {
            this.liberado = false;
            window.alertProcessando();
            perguntaAdminService
                .getPergunta(this.$route.params.id)
                .then(response => {
                    this.liberado = true;
                    this.model = response;
                    window.alertProcessandoHide();
                    
                })
                .catch(error => {
                    window.alertProcessandoHide(); 
                    window.toastErro("Não foi possivel achar a Pergunta");
                }); 
        }
 
        proxima() {
            let indice = 1;
            indice = indice + parseInt(this.$route.params.id);
            this.$router.push("/pergunta/show/" + indice);
        }
 
        formatarResposta( ) { 
            window.alertConfimacao('Confirma a formatação das Respostas ', '',
                () => {
                    
                    window.alertProcessando();
                    perguntaAdminService
                        .formatarResposta(this.$route.params.id )
                        .then(response => {
                            this.buscarModel(); 
                            window.alertProcessandoHide();
                        })
                        .catch(error => {
                            console.log(error);
                            window.alertProcessandoHide();
                        });
                }
            ); 
        }
 
        alterarResposta(resposta: any) { 
            window.alertConfimacao('Confirma a Alteração da Resposta ', '',
                () => {
                    const data = {
                        resposta_id : resposta  
                    }; 
                    window.alertProcessando();
                    perguntaAdminService
                        .alterarResposta(this.$route.params.id, data)
                        .then(response => {
                            this.model = response;
                            window.alertProcessandoHide();
                        })
                        .catch(error => {
                            console.log(error);
                            window.alertProcessandoHide();
                        });
                }
            ); 
        }
 
        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}
 
		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		 
    }
 
