











































    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Card extends Vue {

        @Prop( ) readonly color!: any
        @Prop( ) readonly solid!: any
        @Prop( ) readonly title!: any
        @Prop( ) readonly collapse!: any
        @Prop( ) readonly removable!: any
        @Prop( ) readonly maximize!: any
        @Prop( ) readonly voltar!: any
        @Prop( ) readonly back!: any
		
		 

        private min: any= null;
        private exp: any= null;


        public minimizar() {
            this.min = !this.min;
        }


        public expandir() {
            this.exp = !this.exp;
        }

        
        public goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push(this.back)
        }
 
    }

