var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('button',{staticClass:"btn  btn-sm btn-warning",attrs:{"title":"Editar","type":"button","data-toggle":"modal","data-target":'#' + _vm.add_tag_id},on:{"click":function($event){return _vm.acessou()}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('custom-modal',{attrs:{"id":_vm.add_tag_id,"titulo":"Editar Resposta"}},[_c('section',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('label',{attrs:{"for":"resposta"}},[_vm._v("Texto da Resposta:")]),(_vm.isvisivel)?_c('crudFormElemento',[_c('editor',{attrs:{"api-key":"sqeyk0ssgpiu82ndnkptql0868iw94s5rxg3p8l6q1aid9ls","init":{
                                height: 200,
                                menubar: false,
                                fontsize_formats: '8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 36pt 48pt',
                                plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                                ],
                                color_cols: 10,
								color_map: _vm.colors,
                                toolbar:
                                'undo redo | formatselect fontselect fontsizeselect | bold italic underline strikethrough backcolor forecolor  | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | charmap fullscreen  preview  removeformat | help'
                            }},model:{value:(_vm.texto),callback:function ($$v) {_vm.texto=$$v},expression:"texto"}})],1):_vm._e()],1),_c('div',{staticClass:"col-12 col-sm-3"},[_c('button',{staticClass:" btn btn-success ",on:{"click":_vm.salvar}},[_vm._v(" Salvar "),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp')}}),_c('i',{staticClass:" fa fa-check "})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }