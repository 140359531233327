




























 
    /* eslint-disable @typescript-eslint/camelcase */ 
    

    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { perguntaAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormPerfilPermissao extends Vue {

        @Prop( ) readonly tags!: any
        @Prop( ) readonly assunto!: any

        private tag: any = null; 

        private add_tag_id = 'modal-seguranca-pergunta-adicionar-tag'; 


        AdicionarTag() {
            if (this.tag) {

                window.alertProcessando();
                const dados = {
                    tag : this.tag,  
                };   

                perguntaAdminService.adicionarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('tagAdicionada', response);
                    ( $('#' + this.add_tag_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }

 
