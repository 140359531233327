






















































































 
	 
	/* eslint-disable @typescript-eslint/camelcase */ 

	 
	import {  audioAulaAdminService, moduloAdminService , disciplinaAdminService, assuntoAdminService }  from '@/servicegerente';

	 
  
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
		 
        },
	 })
    export default class AudioCadastro extends Vue {

        private modulos: any = null;
        private disciplinas: any = null; 
        private assuntos: any = null; 
         
		private titulo: any = null;
		// private tipo: any= null;
		private disciplina_restrito: any= "1";
		private restrito: any= null; 
		// private conteudo: any= null; 
		private modulo_id: any= null; 
		private assunto_id: any= null; 
		private disciplina_id: any= null; 
		private pdf: any= null; 

	  

		private urlRetorno: any= '/audio_aula';

		private errors: any = { };

        created() { 
			moduloAdminService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			});

			disciplinaAdminService.getAll() 
			.then(response => {
				this.disciplinas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			}); 
		}

		@Watch('disciplina_id')
		disciplinaAtualizada(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = [];
			// this.buscarTodosAssunto( );
		}

		buscarAssunto(id: any) {
			window.alertProcessando();
			disciplinaAdminService.buscarTodosAssuntoDaDisciplina(id) 
			.then(response => {
				this.assuntos = response ;
				window.alertProcessandoHide();

			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os assunto');
				window.alertProcessandoHide();
			}); 
		}

		// buscarTodosAssunto( ) {
		// 	assuntoAdminService.getAll() 
		// 		.then(response => {
		// 			this.assuntos = response ;
		// 		})
		// 		.catch(error => {
		// 			window.toastErro('Não foi possivel achar os assunto');

		// 		});
		// }




		salvar() {
            window.alertProcessando();
            
            const dados = new FormData();
             
			// if( this.tipo == 'PDF'){
			// 	dados.append('file_pdf',this.pdf );
			// }
			// if( this.tipo == 'TEXTO'){
			// 	dados.set('conteudo', this.conteudo );
			// }
			dados.append('file_pdf',this.pdf );
			
			dados.set('titulo', this.titulo);
			// dados.set('tipo', this.tipo);
			dados.set('modulo_id', this.modulo_id);
			dados.set('assunto_id', this.assunto_id);
			dados.set('disciplina_id', this.disciplina_id);
			dados.set('disciplina_restrito', this.disciplina_restrito);
			dados.set('restrito', this.restrito);
 
			audioAulaAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				// this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


		handleFileUpload(){
			this.pdf = this.$refs.pdf.files[0];
		}
 
	}
	
   
