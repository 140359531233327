




































    import { userService } from '@/servicegerente';

    import GraficoTentativa from './_tentativas.vue';
    import GraficoRendimento from './_rendimento.vue';
    import GraficoErros from './_erros.vue' ;


    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: {
            GraficoTentativa,
            GraficoRendimento,  
            GraficoErros,
         },
    })
    export default class GraficoAdminIndex extends Vue { 
 
        private datacollectionGeral: any = null; 
         
        created() { 
            window.alertProcessando();  
            userService.buscarTentativasGeralDiaria(   2 )   
			.then(response => {
				window.alertProcessandoHide();
				this.datacollectionGeral = response;
			})
			.catch(() => { 
				window.alertProcessandoHide(); 
            });  
        }


    }
 
	 

