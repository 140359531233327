





















    import { perfilService  } from '@/servicegerente';


    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
        },
	 })
    export default class PerfilHistoricoPermissao extends Vue {

		private urlRetorno: any = '/perfil' + '/' + this.$route.params.id + '/permissao' ;
		private perfil: any = null ;


        private config = { 
            order: [ [4, "desc"]  ],
            ajax: {
                url: perfilService.getUrl() + '/' + this.$route.params.id + '/permissao/log/datatable'
            },
            columns: [
                { data: 'autor.nome', name: 'autor.nome' },
                { data: 'acao',  name: 'acao' },
                { data: 'permissao_nome', name: 'permissao_nome' },
                { data: 'created_at',  name: 'created_at'  },
                { data: 'ip_v4', name: 'ip_v4' },
                { data: 'host', name: 'host'  },
            ],
        };


        created() {
            window.alertProcessando();
            perfilService.getPerfil(this.$route.params.id)
                .then(response => {
                    this.perfil = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.alertProcessandoHide();
                    window.toastErro('Não foi possivel achar a Perfil', error.data);
                });
 
        }

    }


 

