import { authHeader } from '..'; 
import { segurancaModel } from './index.model'; 
 

import axios  from 'axios';  



const url = segurancaModel.getUrl() + '/usuario' ; 
const urlRelatorio = segurancaModel.getUrlRelatorio() + '/usuario' ; 
const urlSite = segurancaModel.getSiteApi()   ;  
const urlLogin = segurancaModel.getUrl() + '/auth' ;  
 

import  store   from '@/store/index' 

function logout2() {  
	store.dispatch('usuario/logout'); 
}

function getUrlRelatorio(  ) { 
	return  urlRelatorio; 
}

function getUrl(  ) { 
	return  url; 
}
 

function notificar( dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( urlSite + '/api/v1/notificacao/user'  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


function notificarModulo( dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( urlSite + '/api/v1/notificacao/modulo'  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}




function buscarTentativasGeralDiaria(  modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio +   "/modulo/" + modulo + "/tentativas/geral/diaria/25" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}





 
function buscarTentativasMes( id: any, modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio + '/' + id + "/modulo/" + modulo + "/tentativas/mes/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 



function resetarSenha( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + '/resetarSenha/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}



function adicionarPlano( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/plano" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


function adicionarModulo( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/modulo" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}




function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

 



function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function adicionarPerfil( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/perfil" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}



function removerPerfil( id: any , perfil: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/perfil/' + perfil ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function removerPlano( id: any , plano: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/plano/' + plano ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}




function removerModulo( id: any , modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/modulo/' + modulo ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}



function buscarTentativasGeralMes(  modulo: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio +   "/modulo/" + modulo + "/tentativas/mes/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


 
function buscarTentativasDisciplinaMes( id: any , modulo: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio + '/' + id + "/modulo/" + modulo + "/tentativas/disciplina/mes/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

 
function buscarTentativasGeralDisciplinaMes( modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio +   "/modulo/" + modulo + "/tentativas/disciplina/mes/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


function getUsuario( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 
 
function buscarPerfis( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/perfil/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

 
function buscarModulos( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/modulo/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

 
function buscarPlanos( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/plano/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function logout() { 
	localStorage.removeItem('user');
	localStorage.clear( ); 
}


function login(id: any, password: any) {

	const data: any = {};  
	data['id'] = id ;  
	data['password'] = password ;  
	return  new Promise((resolve, reject) => {
		axios.post( urlLogin + '/login' , data )
		.then(response => {   
			localStorage.setItem('user', JSON.stringify(response.data.access_token));
			resolve( response.data.access_token);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response.data.error);
		})

	});


}


function cadastrar(data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( urlLogin + '/cadastro' , data )
		.then(response => {   
			resolve( response.data );  
		})
		.catch(error => {  
			reject(error.response.data);
		}) 
	}); 
}




function buscarTentativasUsuarioDiaSemana( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio  + '/' + id +   "/dia/semana" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


function buscarTentativasUsuarioDiaHora( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio  + '/' + id +  "/dia/hora" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


function buscarTentativasUsuarioDisciplina( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( urlRelatorio  + '/' + id +   "/disciplina" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout2();				
			} 
			reject(error.response);
		}) 
	}); 
}



function bonificarPRO( id: any, data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/' + id  + '/bonificar/pro' , data  , {headers: authHeader() } )
		.then(response => {   
			resolve( response.data );  
		})
		.catch(error => {  
			reject(error.response.data);
		}) 
	}); 
}

function adicionarBonificaçãoCadastralAoUsuario( id: any  ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/' + id  + '/adicionar/plano/bonificacao' , {}  , {headers: authHeader() } )
		.then(response => {   
			resolve( response.data );  
		})
		.catch(error => {  
			reject(error.response.data);
		}) 
	}); 
}


function buscarRelatorioSemanal( id: any   ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + '/' + id  + "/relatorio/semanal/dados" ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}




function verifyAssinaturaApple( assinatura: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/apple?assinatura=" + assinatura , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function getTransactionHistory( assinatura: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/apple/getTransactionHistory?assinatura=" + assinatura , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function getTransactions( assinatura: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/apple/getTransactions?assinatura=" + assinatura , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function getLookup( assinatura: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/apple/getLookup?assinatura=" + assinatura , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function getRefundLookup( assinatura: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/apple/getRefundLookup?assinatura=" + assinatura , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function verifyGoogle( token: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/google?token=" + token , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function verifyGoogleV1( subscriptions: any,  token: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + "/assinatura/google/v1?token=" + token + "&subscriptions=" + subscriptions  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}


// "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmd1aWFvbmxpbmVjaHMuY29tLmJyXC9hcGlcL3YxXC9zZWd1cmFuY2FcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjAyNTMwNzE3LCJleHAiOjE2MDM0MzA3MTcsIm5iZiI6MTYwMjUzMDcxNywianRpIjoiUmxTd3NVaWlQczBNWDNjTSIsInN1YiI6IjAwMDAwMDAwMDAxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsInVzZXIiOnsiaWQiOiIwMDAwMDAwMDAwMSIsInNpdHVhY2FvX2Fwcm92YWNhbyI6IkN1cnNhbmRvIiwiZGF0YV9maW1fcHJvIjpudWxsLCJub21lIjoiQWRtaW5pc3RyYWRvciIsImVtYWlsIjoibWFuem9saTIxMjJAZ21haWwuY29tIiwiYXBlbGlkbyI6IkFkbWluaXN0cmFkb3IifSwicGVyZmlzIjpbIkFkbWluIl19.cjnwKayHSjq7ZPuCVtgKJ3v9-THuv6ty37jdjogyLgI"



export const userService = {
	cadastrar,
	login,
	logout,
	getUrl,
	getUrlRelatorio,
	getUsuario,
	buscarPerfis,
	buscarRelatorioSemanal,
	buscarModulos,
	removerModulo,
	adicionarModulo,
	verifyAssinaturaApple,
	getTransactionHistory,
	buscarPlanos, 
	buscarTentativasMes,
	buscarTentativasGeralMes,
	buscarTentativasDisciplinaMes,
	buscarTentativasGeralDisciplinaMes,
	getTransactions,
	getLookup,
	getRefundLookup,

	


	buscarTentativasGeralDiaria,
	resetarSenha,
	desativar,
	ativar,
	excluir,
	update,

	verifyGoogle,
	verifyGoogleV1,
	adicionarPlano,

	adicionarPerfil,
	removerPerfil,
	removerPlano,

	bonificarPRO,

	adicionarBonificaçãoCadastralAoUsuario,

	buscarTentativasUsuarioDiaSemana,
	buscarTentativasUsuarioDiaHora,
	buscarTentativasUsuarioDisciplina,

	notificar,
	notificarModulo,

	
};

