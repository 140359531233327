

































































    /* eslint-disable @typescript-eslint/camelcase */ 
 
    import { assuntoAdminService   } from '@/servicegerente';
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class AssuntoEdit extends Vue {

		public model: any = null;
          
		public nome: any = null;
		public sigla: any= null;
		public descricao: any= null;
		public disciplina_id: any= null; 
		public sequencia: any= null; 
		public is_principal: any= null; 
		public urlRetorno: any= '/assunto';

		public errors: any = { };


        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.sigla = this.model.sigla;  
			this.disciplina_id = this.model.disciplina_id;   
			this.sequencia = this.model.sequencia;   
			this.descricao = this.model.descricao;   
			this.is_principal = this.model.is_principal;   

		}



        created() {   
			window.alertProcessando();
			assuntoAdminService.getAssunto( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o Plano', error.data);
				window.alertProcessandoHide();
			}); 
		}

		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				sigla :this.sigla, 
				disciplina_id :this.disciplina_id,  
				sequencia :this.sequencia,  
				descricao :this.descricao,  
				is_principal :this.is_principal,   
			};  
			 
			 
			assuntoAdminService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
    }
 
