

























/* eslint-disable @typescript-eslint/camelcase */ 

    import {   textoService } from '@/servicegerente';

	import $ from "jquery"  
 
    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class IndexTexto extends Vue { 

        private  reloadDatatable = false ; 
        private  token = '' ; 

        private config = { 
            responsive: {
                details: { 
                    display: $.fn.dataTable.Responsive.display.modal( {
                        header: function ( row: any ) {  
                            return 'Descrição' ;
                        }, 
                    } ),

                    renderer: function ( api: any, rowIdx: any, columns: any ) {
                        const data = $.map( columns, function ( col, i ) {
                            return col.hidden ?
                                '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+ '<td class="escuro-modal-texto">'+col.data+'</td>'+
                                '</tr>' + '<tr><td><br><hr></td></tr>'+ '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+ '<td>'+col.data+'</td>'+ '</tr>' : '';
                        } ).join('');
                            
                        return data ?
                            $('<table/>').append( data ) :
                            false;
                    } 
                }
            },
            ativacao: {
                url: textoService.getUrl(),
                item: 'texto',
            },
            exclusao: {
                url: textoService.getUrl(),
                evento: 'textoRemovida',
                item: 'texto',
            },
            order: [  [2, "asc"] ],
            ajax: {
                url: textoService.getUrl() + '/datatable',
            },
            columns: [
                { "class": "details-control text-success", "orderable": false, "data": null, "defaultContent": ""  },  
                { data: 'id', name: 'texto.id',  class: 'text-center' },
                { data: 'nome',  name: 'texto.nome'  }, 
                { data: 'descricao',  name: 'texto.descricao',  class: 'none' },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ],

        };
 


         created() {
            const temp_token = localStorage.getItem('user');
            if(temp_token){
                this.token =  JSON.parse(temp_token)   ;
            }
              
        }



        public tableatualizada( ) {  
             
             
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/texto/edit/' + id); 
                // this.$router.push('/texto/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/texto/show/' + id);
            });
            
            $('[btn-pdf]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open( textoService.getUrl() + '/' + id + '/pdf?token=' + this.token);
            });

            $('[btn-pdf-dom]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open( textoService.getUrl() + '/' + id + '/pdfdom?token=' + this.token);
            });

            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        textoService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        textoService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        textoService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

        }
    }
 
