






















































	/* eslint-disable @typescript-eslint/camelcase */  
    import {   moduloService, ementaService  } from '@/servicegerente';  
    import { Component, Vue } from 'vue-property-decorator'; 
	   
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
 
	@Component({
		components: {  },
	 })
    export default class GuiaCadastro extends Vue {
 
		@gerente.State
		public assunto!: any 
 
		@gerente.State
		public modulo!: any 

        public assuntos: any = null;

		public nome: any= null;  
		public assunto_id: any= null;  
		public ativo: any= false;
         
		public urlRetorno: any= '/v2/administracao/ementa'; 
		public errors: any = { };
	 
		created() {  
			if(!this.assunto){
				this.$router.push('/v2/administracao'); 
			}
			else{ 
				this.assunto_id = this.assunto.id ;  
			} 
			this.buscarAssunto();
		}
		
		
		buscarAssunto(  ) { 
			moduloService.buscarTodosAssunto( this.modulo.id) 
			.then(response => {
				this.assuntos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			}); 
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,  
				assunto_id :this.assunto_id,  
				ativo :this.ativo,  
			};   
			ementaService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
   
    } 
