




















 

	import { logLoginService  }  from '@/servicegerente';
 
	import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class LogLogin extends Vue {

		private config = { 
            order: [[ 3, "desc" ]],
			ajax: { 
				url: logLoginService.getUrl()  + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'usuario_id', name: 'usuario_id' },   
				{ data: 'usuario.nome', name: 'usuario.nome' },   
				{ data: 'navegador', name: 'navegador' },   
				{data: 'created_at', name: 'created_at' },
				{data: 'imei', name: 'imei' },
				{data: 'sucesso', name: 'sucesso' }
			],
        }; 
	} 

