










































































































































 
	/* eslint-disable @typescript-eslint/camelcase */ 
 

	import { referenciaService , perguntaAdminService , assuntoAdminService }  from '@/servicegerente';

	import Editor from '@tinymce/tinymce-vue'
 

	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoCadastro extends Vue {

		public assuntos: any = null; 
		public referencias: any = null;
		public model: any = null; 
		public texto: any= null; 
		public resumo: any= null; 
		public assunto_id: any= null; 
		public referencia_id: any= 8; 
		public referencia_questao_nr: any= null;  
		public status: any= null; 
		public videoaula: any= null;  
		public urlRetorno: any= '/pergunta/show/' + this.$route.params.id  ; 
		public errors: any = { };

		created() {
 
			window.alertProcessando();
			perguntaAdminService.getPergunta(this.$route.params.id) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a pergunta', error.data);
				window.alertProcessandoHide(); 
			});

			assuntoAdminService.getAll() 
			.then(response => {
				this.assuntos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os assunto');
			});

			referenciaService.getAll() 
			.then(response => {
				this.referencias = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os referencias');
			});
 
		}
 
		salvar() {
			window.alertProcessando();
			const dados = {
				texto :this.texto,
				resumo :this.resumo,
				assunto_id :this.assunto_id,
				referencia_id :this.referencia_id,
				referencia_questao_nr :this.referencia_questao_nr, 
				status :this.status,
				videoaula :this.videoaula, 
			};   
 
			perguntaAdminService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.texto = this.model.texto;
			this.resumo = this.model.resumo;
			this.assunto_id = this.model.assunto_id ;
			 
			this.status = this.model.status ;  
			this.videoaula = this.model.videoaula ;  
			this.referencia_id = this.model.referencia_id ; 
			this.referencia_questao_nr = this.model.referencia_questao_nr ; 
		}
 
		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		


	}
 

