


































  

    import $ from "jquery"  
    import { relatorioGeralService  }  from '@/servicegerente';
    import { Component, Vue } from 'vue-property-decorator';
      
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 


    @Component({
        components: { },
    })
    export default class RelatorioHistorico extends Vue { 


        @gerente.State
		public modulo!: any


        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}  
            this.config.ajax.url =  relatorioGeralService.getUrl() + '/modulo/' + this.modulo.id + '/historico/datatable'
        }
  



        private  reloadDatatable = false ; 
        private  modal_adicionar_id = 'modal-config-audio-acesso-dados' ; 
        private  dado = '' ; 

        private config = {
            order: [[ 0, "desc" ]],
            ajax: { 
                url: relatorioGeralService.getUrl() + '/historico/datatable'
            },
            columns: [
                { data: 'id', name: 'id'  }, 
                { data: 'operacao', name: 'operacao'  },  
                { data: 'usuario_id', name: 'usuario_id'  },  
                { data: 'host', name: 'host'  },  
                { data: 'ip_v4', name: 'ip_v4'  },  
                { data: 'created_at', name: 'created_at'  },  
                { data: 'id_model', name: 'id_model'  },  
                { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
            ],
        };
 
        public tableatualizada(   ) {   
            $('[btn-visualizar-dados]').click(  (btn) => {  
                const dados =  $(btn.currentTarget).data('dados'); 
                this.dado = dados;  
                $('#' + this.modal_adicionar_id).modal('show');  
            }); 
        }

    }
 

