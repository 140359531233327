






















































 
	/* eslint-disable @typescript-eslint/camelcase */  
	import { userService, manzaTinyEditorColorsMap } from '@/servicegerente'; 
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator';  
	import $ from "jquery" ; 
	import Editor from '@tinymce/tinymce-vue';

	@Component({
		components: {  
            'editor': Editor
        },
	})
	export default class FormUsuarioPlano extends Vue {
 
	 
		@Prop() readonly usuario!: any;

        public isvisivel = false; 
		public add_plano_id = 'modal-seguranca-usuario-bonificar-usuario';  
        public assunto: any= null; 
		public dias: any = 0;  
        public mensagem: any= null;
        public errors: any = { };
        public colors: any = []; 
        
        created() { 
			this.colors = manzaTinyEditorColorsMap; 
        }

        acessou(){ 
			this.isvisivel = true;
        }

        bonificar() { 
            window.alertProcessando(); 
            const dados = {
                assunto :this.assunto, 
                dias :this.dias, 
                mensagem :this.mensagem,  
            };   
            userService.bonificarPRO( this.usuario.id,   dados )
            .then(response => {
                window.alertProcessandoHide(); 
                window.toastSucesso("usuario bonificado.");  
                this.$emit('planoAlterado', response ) ; 
                ( $('#' + this.add_plano_id) as any ) .modal('toggle');  
            })
            .catch(error => {  
                window.alertProcessandoHide();
                this.errors = error.data.error; 
                console.log(error);
                window.toastErro('Não foi possivel adiciona ' , error.data.message); 
            });  
              
        } 
         
	}

