








































  
	/* eslint-disable @typescript-eslint/camelcase */ 
    import axios from 'axios'; 
	import {  Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class TipoReferenciaCadastro extends Vue {

		public url: any = "https://portal.pm.es.gov.br/api/pre-cadastro-users"; 
		public token: any = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMjMyMDc0MzczMSIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfUk9PVCxST0xFX1NVUE9SVEUsUk9MRV9VU0VSIiwiYWNjZXNzbG9nIjoyNjI2Mjc1LCJleHAiOjE3MDM3ODMxMzF9.EB-XdwMTTpHdOEqOL39rD4nYh5yO6MixwKmHQ3oI_9-sM1sZk8nNd-APxPcfRC9V1ZRxCkPfMLZ1faEZ66UwmQ"; 
		 
		 
		public urlRetorno: any= '/v2/administracao/configuracao';

		public errors: any = { };

        delay(ms: number) {
            return new Promise( resolve => setTimeout(resolve, ms) );
        }
		 
        public arrayDados: any = [
            //"id","nome","cpf","email","rg","numero_funcional","created_date","last_modified_date","responsavel_id","motivo","parecer"
   
            // '2769,"Carlos Luiz Themoteo Junior","14851107773","carlosthemoteo@hotmail.com",null,null,"2023-11-22 03:46:35.997","2023-11-30 13:24:00.591",null,"WWW.PM.ES.GOV.BR",null',
             

        ];


        async buscarCEP( ) {  
			if( !this.token || !this.url ){
				return;
			}
			const header = {headers:  { 'Authorization': 'Bearer ' +  this.token  } }; 
            for (let index = 0; index < this.arrayDados.length; index++) {
                const element = this.arrayDados[index]; 
                const splitted = element.split("," ); 
                const dados = {
                    "id": parseInt( splitted[0].replaceAll('"', "") ),
                    "nome": splitted[1].replaceAll('"', ""),
                    "cpf": splitted[2].replaceAll('"', ""),
                    "email": splitted[3].replaceAll('"', ""),
                    "rg": splitted[4].replaceAll('"', "") === "null" ? null : splitted[4].replaceAll('"', "") ,
                    "numeroFuncional": splitted[5].replaceAll('"', "") === "null" ? null : splitted[5].replaceAll('"', "") , 
                    "createdDate": splitted[6].replaceAll('"', "") === "null" ? null : splitted[6].replaceAll('"', "").replaceAll(' ', "T") + 'Z' ,
                    "lastModifiedDate": splitted[7].replaceAll('"', "") === "null" ? null : splitted[7].replaceAll('"', "").replaceAll(' ', "T") + 'Z'  ,
                    "responsavel": splitted[8].replaceAll('"', "") === "null" ? null : splitted[8].replaceAll('"', "") , 
                    "motivo": splitted[9].replaceAll('"', "") === "null" ? null : splitted[9].replaceAll('"', "")  ,
                    "parecer": splitted[10].replaceAll('"', "") === "null" ? null : splitted[10].replaceAll('"', "")  
                }; 
                console.log(dados); 
                axios.put( this.url , dados ,  header   )
                .then(response => {   
                    console.log(response);
                })
                .catch(error => { 
                    console.log(error);
                });   
                await this.delay(800);
            }
               
        }  
		  
	}
 
