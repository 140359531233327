






























    import {   tagAdminService } from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        @usuario.Getter
		public isAdmin!:  (  ) => boolean


        @usuario.State
		public perfis!: any


        private  reloadDatatable = false ; 

        private config = { 
            responsive: {
                details: { 
                    display: $.fn.dataTable.Responsive.display.modal( {
                        header: function ( row: any ) {  
                            return 'Descrição' ;
                        }, 
                    } ),

                    renderer: function ( api: any, rowIdx: any, columns: any ) {
                        const data = $.map( columns, function ( col, i ) {
                            return col.hidden ?
                                '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+ '<td class="escuro-modal-tag">'+col.data+'</td>'+
                                '</tr>' + '<tr><td><br><hr></td></tr>'+ '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+ '<td>'+col.data+'</td>'+ '</tr>' : '';
                        } ).join('');
                            
                        return data ?
                            $('<table/>').append( data ) :
                            false;
                    } 
                }
            },
            ativacao: {
                url: tagAdminService.getUrl(),
                item: 'Tag',
            },
            exclusao: {
                url: tagAdminService.getUrl(),
                evento: 'tagRemovida',
                item: 'Tag',
            },
            order: [  [2, "asc"] ],
            ajax: {
                url: tagAdminService.getUrl() + '/datatable',
            },
            columns: [
                { "class": "details-control text-success", "orderable": false, "data": null, "defaultContent": ""  , searchable: false },  
                { data: 'id', name: 'id',  class: 'text-center'  , searchable: false},
                { data: 'nome',  name: 'nome'   }, 
                { data: 'descricao',  name: 'descricao',  class: 'none' }, 
                { data: 'perguntas_count', name: 'perguntas_count', class: 'text-center' , searchable: false},
                { data: 'perguntas_chs_count', name: 'perguntas_chs_count', class: 'text-center' , searchable: false},
                { data: 'deleted_at', name: 'deleted_at', class: 'text-center' , searchable: false},
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ],

        };
 

        



        public tableatualizada( ) {  
             
             
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/tag/edit/' + id); 
                // this.$router.push('/tag/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/tag/show/' + id);
            });
            $('[btn-visualizar-assuntos]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/tag/' + id + '/assuntos');
            });
            $('[btn-visualizar-perguntas]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/tag/' + id + '/perguntas'); 
                // this.$router.push('/tag/' + id + '/perguntas');
            });
     
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        tagAdminService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        tagAdminService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        tagAdminService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

        }
    }
 
