















































































































	/* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
	  
	import { cardService }  from '@/servicegerente';
	import {Watch, Component, Prop, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class CardShow extends Vue { 

        @Prop({default: null  }  ) readonly card_id!: interger
        @Prop({default: 'card-navy'  }  ) readonly color!: string
        @Prop({default: 'Card'  }  ) readonly titulo!: string
 
        private model: any = null;

		private liberado  = false;
		
        @Watch('card_id')
		tabelaAtualizada(newVal: any) {
			this.buscarModel(); 
        }
         
		created() { 
			this.buscarModel();  
		}
 
        buscarModel() {
			this.liberado = false;
            this.model = null ;
            if( this.card_id ){
                window.alertProcessando();
                cardService.getCard( this.card_id  )
                .then(response => {
                    this.liberado = true;
                    this.model = response ;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.alertProcessandoHide(); 
                    window.toastErro('Não foi possivel achar Card');
                });  
            }
			 
        }


	}
	
 


