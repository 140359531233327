
























































































































	import { moduloService , controleService }  from '@/servicegerente';  
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 
      
    @Component({
        components: { },
    })
    export default class ResumosIndex extends Vue { 
        
        @gerente.State
		public modulo!: any


        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			 
        }
  

        
        

        // public iniciarSimulado( ) {  
        //     window.alertConfimacao('Confirma a criação do Simulado ','' , 
        //         () => {  
        //             window.alertProcessando(); 
        //             moduloService.InciarSimulado( this.modulo.id )
        //             .then(response => {
        //                 window.alertProcessandoHide();   
        //                 window.toastSucesso('criação realizada com sucesso!!' , response  ); 
        //             })
        //             .catch(error => { 
        //                 window.alertProcessandoHide();
        //                 window.toastErro('Não foi possivel criar simulado ' , error.data); 
        //             });  
        //         }
        //     ); 
        // }


              
        public finalizarSimulado( ) {  
            window.alertConfimacao('Confirma a finalização do Simulado ','' , 
                () => {  
                    window.alertProcessando(); 
                    moduloService.finalizarSimulado(  this.modulo.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('finalização realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel finalizar simulado ' , error.data); 
                    });  
                }
            ); 
        }
             
              
        public processarImport( ) {  
            window.alertConfimacao('Confirma a processarImport ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.processaImport(   )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('processarImport realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel processarImport ' , error.data); 
                    });  
                }
            ); 
        }
              
        public processarImportAnulada( ) {  
            window.alertConfimacao('Confirma a processarImportAnulada ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.processarImportAnulada(   )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('processarImportAnulada realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel processarImportAnulada ' , error.data); 
                    });  
                }
            ); 
        }
              
        public migrarS3( ) {  
            window.alertConfimacao('Confirma a migrarS3 ','' , 
                () => {  
                    window.alertProcessando(); 
                    controleService.migrarS3(   )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('migrarS3 realizada com sucesso!!' , response  ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel migrarS3 ' , error.data); 
                    });  
                }
            ); 
        }
             


 
	}
	 

