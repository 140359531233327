



















































/* eslint-disable @typescript-eslint/camelcase */ 
 
	import {audioAulaAdminService }  from '@/servicegerente';

	 


	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  
        },
	 })
    export default class AudioEdicao extends Vue {

        private model: any = null; 
         
		private titulo: any = null;  
		private restrito: any= null;  
	  
		private urlRetorno: any= '/audio_aula'; 
		private errors: any = { };

       	created() {
			window.alertProcessando();
			
			audioAulaAdminService.getAudioAula(this.$route.params.id , true) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a audio aula', error.data);
				window.alertProcessandoHide();
				 
			});

			 


		}


		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.titulo = this.model.titulo; 
			this.restrito = this.model.restrito;  
		}

		 
		salvar() {
			window.alertProcessando();
			const dados = {
				titulo :this.titulo,
				restrito :this.restrito,  
			};   
			 
			audioAulaAdminService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
	
 
		
	}

 


