



















































































































































 
	/* eslint-disable @typescript-eslint/camelcase */ 
  
	import {  referenciaService ,  perguntaAdminService , assuntoAdminService, disciplinaAdminService }  from  '@/servicegerente' ;

	import Editor from '@tinymce/tinymce-vue'

	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  

	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 



	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoCadastro extends Vue {



		@gerente.State
		public modulo!: any


		@gerente.State
		public disciplina!: any


		@gerente.State
		public assunto!: any




		private assuntos: any = null;
		private disciplinas: any = null;
		private referencias: any = null;
		private disciplina_id: any = null;
 

		private verdadeiro_falso: any= false; 
		private texto: any= null; 
		private resumo: any= null; 
		private assunto_id: any= null; 
		private referencia_id: any= 8; 
		private referencia_questao_nr: any= null; 
		 
		private status: any= 'Criada'; 
 

		private urlRetorno: any= '/pergunta';

		private errors: any = { };




		@Watch('disciplina_id')
		tabelaAtualizada(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = null ;
			this.assunto_id = null ;
			// this.buscarTodosAssunto( );
		}


		created() { 
			// this.buscarTodosAssunto( ) ; 

			if(this.disciplina){
				this.disciplina_id = this.disciplina.id 
			}
			 
			if(this.assunto){
				this.assunto_id = this.assunto.id 
			}



			disciplinaAdminService.getAll() 
			.then(response => {
				this.disciplinas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os disciplinas');
			}); 
			referenciaService.getAll() 
			.then(response => {
				this.referencias = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os referencias');
			}); 
		}



		salvar() {
			window.alertProcessando();
			const dados = {
				verdadeiro_falso :this.verdadeiro_falso, 
				texto :this.texto, 
				resumo :this.resumo, 
				assunto_id :this.assunto_id, 
				referencia_id :this.referencia_id, 
				referencia_questao_nr :this.referencia_questao_nr, 
				status :this.status, 
			};  
			 
			perguntaAdminService.cadastrar(   dados )
			.then( response => {
				window.toastSucesso(response.message);  
				window.alertProcessandoHide();
				this.$router.push(  '/pergunta/show/' + response.model.id )
				// this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 



		buscarAssunto(id: any) {
			window.alertProcessando();
			disciplinaAdminService.buscarTodosAssuntoDaDisciplina(id) 
			.then(response => {
				this.assuntos = response ;
				window.alertProcessandoHide();

			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os assunto');
				window.alertProcessandoHide();
			}); 
		}

		buscarTodosAssunto( ) {
			assuntoAdminService.getAll() 
				.then(response => {
					this.assuntos = response ;
				})
				.catch(error => {
					window.toastErro('Não foi possivel achar os assunto');

				});
		}


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		




	}


 

