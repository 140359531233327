





























	 
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { assuntoAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormAssuntoTag extends Vue {

        @Prop( ) readonly tags!: any

        public tag: any = null;

        public add_tag_id = 'modal-seguranca-assunto-adicionar-tag'; 


        AdicionarTag() {
            if (this.tag) { 
                window.alertProcessando();
                const dados = {
                    tag :this.tag, 
                };    
                assuntoAdminService.adicionarTag( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('tagAdicionado', response);
                    // ( $('#' + this.add_tag_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

	}
	 
