import { render, staticRenderFns } from "./_BateriaQuestaoEditar.vue?vue&type=template&id=de2893ca&scoped=true"
import script from "./_BateriaQuestaoEditar.vue?vue&type=script&lang=ts"
export * from "./_BateriaQuestaoEditar.vue?vue&type=script&lang=ts"
import style0 from "./_BateriaQuestaoEditar.vue?vue&type=style&index=0&id=de2893ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2893ca",
  null
  
)

export default component.exports