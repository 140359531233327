import { render, staticRenderFns } from "./_User.vue?vue&type=template&id=0144c8da&scoped=true"
import script from "./_User.vue?vue&type=script&lang=js"
export * from "./_User.vue?vue&type=script&lang=js"
import style0 from "./_User.vue?vue&type=style&index=0&id=0144c8da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0144c8da",
  null
  
)

export default component.exports