/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '@/servicegerente'; 
import { gerenteModel } from './index.model'; 
import axios  from 'axios'; 
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js';

const secret_key =  gerenteModel.getKeyCripto() ;
 
const url = gerenteModel.getUrl() + '/banca' ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}

function getModel( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function getAll(  ) { 
	return  new Promise((resolve, reject) => {   
		const bancas = localStorage.getItem('global_model_bancas_todas');   
		if(bancas){  
			const data_criacao = localStorage.getItem('data_global_model_bancas_todas' );   
			if( moment()  < moment( data_criacao ).add( 5 , 'days') ){
				const bytes  = CryptoJS.AES.decrypt(bancas, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		} 
		axios.get( url   , {headers: authHeader() }  )
		.then(response => {  
			const encripted_bancas = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_bancas_todas'   , encripted_bancas  );    
			localStorage.setItem('data_global_model_bancas_todas'  , moment().format('YYYY-MM-DD')  );   
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
 
}
 



export const bancaService = { 
	getUrl,
	getAll,  
	getModel ,  
	update,
};