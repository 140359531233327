
























































































































































































































    /* eslint-disable @typescript-eslint/camelcase */  
    import {  perguntaService , respostaComentarioService , cardService } from "@/servicegerente"; 
    import Tag from './Tag.vue'
    import Assunto from './Assunto.vue'
    import FormCriarResposta from './_FormCriarResposta.vue'
    import FormEditarRespostaComentario from './_FormEditarRespostaComentario.vue' 
    import FormCriarRespostaComentario from './_FormCriarRespostaComentario.vue'
    import FormEditarResposta from './_FormEditarResposta.vue' 
    import $ from "jquery"   
    import {Watch, Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente')    

    @Component({
        components: { 
            'perguntaAssunto': Assunto,
            'perguntaTag': Tag,
            'form-criar-resposta': FormCriarResposta,
            'form-edita-resposta': FormEditarResposta,
            'form-criar-resposta-comentario': FormCriarRespostaComentario,
            'form-edita-resposta-comentario': FormEditarRespostaComentario,
         },
    })
    export default class Principal extends Vue { 
 
        @usuario.State
		public perfis!: any
 
        @gerente.State
		public modulo!: any

        @gerente.State
		public card!: any
 
        public model: any  = null;
        public liberado  = false;
       
        public url_retorno: any  = "/v2/administracao/pergunta";
 
        public perguntaKey = 0;

        
        public adicionarCard(   ) {
            if ( this.model.id  && this.card ) { 
                window.alertProcessando();
                const dados = {
                    fonte_id :this.card.id,   
                    pergunta_id :  this.model.id , 
                };    
                cardService.storeCardPergunta( dados )
                .then(response => {
                    window.toastSucesso("card adicionado com successo.");
                    window.alertProcessandoHide();  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500); 
            }
        }  



        @Watch('$route')
		tabelaAtualizada(newVal: any) {
			this.buscarModel(); 
        }
         
        get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}
 
        created() { 
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			} 
            this.buscarModel(); 
        }


        repostaComentario( resposta: any ) { 
            if(this.model != null && this.model.resposta_comentario != null ){
                return this.model.resposta_comentario.filter((comentario: any)=>{  return comentario.resposta_id == resposta.id  })
            } 
        }
 
        buscarModel() {
            this.liberado = false;
            window.alertProcessando();
            perguntaService
                .getPergunta(this.$route.params.id)
                .then(response => {
                    this.liberado = true;
                    this.model = response;
                    window.alertProcessandoHide();
                    
                })
                .catch(error => {
                    window.alertProcessandoHide(); 
                    window.toastErro("Não foi possivel achar a Pergunta");
                }); 
        }
  
        proxima() {
            let indice = 1;
            indice = indice + parseInt(this.$route.params.id);
            this.$router.push("/v2/administracao/pergunta/show/" + indice);
        }


        alterarResposta( resposta: any ) { 
            window.alertConfimacao('Confirma a Alteração da Resposta ', '',
                () => {
                    const data = {
                        resposta_id : resposta  
                    }; 
                    window.alertProcessando();
                    perguntaService
                        .alterarResposta(this.$route.params.id, data)
                        .then(response => { 
                            window.toastSucesso('Alteração da Resposta realizada com sucesso!!' );   
                            this.model.resposta_certa_id = resposta;
                            // this.datatableKey += 1;
                            this.perguntaKey += 1;
                            window.alertProcessandoHide();
                        })
                        .catch(error => {
                            console.log(error);
                            window.toastErro(error.data.message ); 
                            window.alertProcessandoHide();
                        });
                }
            ); 
        }
 
        limparCache( ) { 
            window.alertConfimacao('Deseja mesmo limpar a cache desta questão ', '',
                () => { 
                    window.alertProcessando();
                    perguntaService
                        .limparCache(this.$route.params.id )
                        .then(response => { 
                            // this.buscarModel(); 
                            window.toastSucesso('cache limpada com sucesso!!' );  

                            window.alertProcessandoHide();
                        })
                        .catch(error => {
                            console.log(error);
                            window.alertProcessandoHide();
                        });
                }
            ); 
        }
 
        restritar( ) { 
            if(this.admin){
                this.restritar_api();
            }
            else{
                 window.alertConfimacao('Deseja mesmo Disponibilizar esta questão ', '',
                    () => { 
                        this.restritar_api();
                    }
                ); 
            } 
        }
        
        limparSubject( ) { 
            if(this.admin){
                this.limparSubject_api();
            }
            else{
                 window.alertConfimacao('Deseja mesmo Disponibilizar esta questão ', '',
                    () => { 
                        this.limparSubject_api();
                    }
                ); 
            } 
        }
         
        restritar_api( ) {  
            window.alertProcessando();
            perguntaService
                .restritar(this.$route.params.id )
                .then(response => { 
                    this.buscarModel(); 
                    window.toastSucesso('Disponibilização realizada com sucesso!!' );  
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                }); 
        }
         
        limparSubject_api( ) {  
            window.alertProcessando();
            perguntaService
                .limparSubject(this.$route.params.id )
                .then(response => { 
                    this.buscarModel(); 
                    window.toastSucesso('limparSubject realizada com sucesso!!' );  
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                }); 
        }


        questionairar( ) { 
            if(this.admin){
                this.questionairar_api();
            }
            else{
                window.alertConfimacao('Deseja mesmo Questionariar esta questão ', '',
                    () => { 
                        this.questionairar_api();
                    }
                ); 
            } 
        }

        
        questionairar_api( ) {  
            window.alertProcessando();
            perguntaService
                .questionairar(this.$route.params.id )
                .then(response => { 
                    this.buscarModel(); 
                    window.toastSucesso('Disponibilização realizada com sucesso!!' );  
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                }); 
        }


        
        finalizar( ) {  
            window.alertConfimacao('Confirma a Desativação ','' , 
                () => {  
                    window.alertProcessando(); 
                    perguntaService.finalizar(  this.model.id )
                    .then(response => {
                        window.alertProcessandoHide();   
                        window.toastSucesso('desativação realizada com sucesso!!' ); 
                        this.$router.push('/v2/administracao/pergunta'); 
                        // this.proxima();
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel desativar ' , error.data.message); 
                    });  
                }
            ); 
        }
        
        finalizarlight( ) {  
            window.alertProcessando(); 
            perguntaService.finalizarlight(  this.model.id )
            .then(response => {
                window.alertProcessandoHide();   
                window.toastSucesso('desativação realizada com sucesso!!' ); 
                window.close() 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel desativar ' , error.data.message); 
            });   
        }


        
        excluirComentario( comentario: any ) {  
            window.alertConfimacao('Confirma a Exclusão do comentário ', '',
                () => { 
                    window.alertProcessando();
                    respostaComentarioService
                        .excluir(comentario.id )
                        .then(response => { 
                            this.buscarModel(); 
                            window.toastSucesso('formatação realizada com sucesso!!' ); 
                            window.alertProcessandoHide();
                        })
                        .catch(error => {
                            console.log(error);
                            window.alertProcessandoHide();
                        });
                }
            ); 
        }

 
        formatarResposta( ) { 
            if( this.admin ){
                window.alertProcessando();
                perguntaService
                    .formatarResposta(this.$route.params.id )
                    .then(response => { 
                        this.buscarModel(); 
                        window.alertProcessandoHide();
                        window.toastSucesso('formatação realizada com sucesso!!' ); 
                        // this.$router.push('/v2/administracao/pergunta/edit/' +  this.$route.params.id); 
                    })
                    .catch(error => {
                        console.log(error);
                        window.alertProcessandoHide();
                    }); 
                return;
            }
            window.alertConfimacao('Confirma a formatação das Respostas ', '',
                () => { 
                    window.alertProcessando();
                    perguntaService
                        .formatarResposta(this.$route.params.id )
                        .then(response => { 
                            this.buscarModel(); 
                            window.toastSucesso('formatação realizada com sucesso!!' ); 
                            window.alertProcessandoHide();
                        })
                        .catch(error => {
                            console.log(error);
                            window.alertProcessandoHide();
                        });
                }
            ); 
        }

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper'); 
			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer'); 
			if(header){  
                header.style.marginLeft = null ;   
			} 
			if(footer){ 
				footer.hidden = false ; 
			} 
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
		

    }




   
        

