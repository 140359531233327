import { render, staticRenderFns } from "./_TagFormAdicionarMultiple.vue?vue&type=template&id=8ece6774&scoped=true"
import script from "./_TagFormAdicionarMultiple.vue?vue&type=script&lang=ts"
export * from "./_TagFormAdicionarMultiple.vue?vue&type=script&lang=ts"
import style0 from "./_TagFormAdicionarMultiple.vue?vue&type=style&index=0&id=8ece6774&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ece6774",
  null
  
)

export default component.exports