

















	/* eslint-disable @typescript-eslint/camelcase */ 


	import { tipoReferenciaService  }  from '@/servicegerente';


	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        private tipoReferencia: any = null;

		private urlRetorno: any= '/tipoReferencia';


        private config = { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: tipoReferenciaService.getUrl() + '/' + this.$route.params.id + '/referencia/datatable'
			},
			columns: [
				{ data: 'id', name: 'id' },
				{ data: 'nome', name: 'nome' },
			],
		};


		created() { 
			window.alertProcessando();
			tipoReferenciaService.getTipoReferencia( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.tipoReferencia = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a tipo Referencia', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.urlRetorno);
			});   
		} 
	}
	
 

