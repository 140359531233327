
































































































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { bateriaService , bateriaQuestaoService , moduloService }  from '@/servicegerente'; 
	import { Component, Vue } from 'vue-property-decorator';
	import $ from "jquery"   
    import BateriaQuestaoCadastro from './_BateriaQuestaoCadastro.vue'
    import BateriaQuestaoEditar from './_BateriaQuestaoEditar.vue'

	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 

    @Component({
        components: {  BateriaQuestaoCadastro , BateriaQuestaoEditar },
    })
    export default class GuiaShow extends Vue { 

        
		@gerente.State
		public modulo!: any

        public url_retorno: any = '/v2/administracao/bateria' ;
        public model: any = null ;
        public modal_atualizar: any =  'modal_bateria_questao_update_' ;
        public dados: any =  null ;
        public ids: any =  null ;
        public identificador_api_min: any =  null ;
        public identificador_api_max: any =  null ;

		public  reloadDatatable = false ; 
		public  disciplina_id: any = null ; 
		public  banca_id: any = null ; 
		public  pesquisa: any = null ; 
        
        public config = {  
            order: [  [2, "asc"] , [1, "asc"] ],
            ajax: {
                url: bateriaService.getUrl() + '/' + this.$route.params.id + '/perguntas/datatable',
            },
            columns: [ 
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'pergunta_id',  name: 'pergunta_id'  },  
                { data: 'disciplina_id',  name: 'disciplina_id'  },  
                { data: 'nivel',  name: 'nivel'  },  
                { data: 'pergunta.baterias_count',  name: 'pergunta.baterias_count' , orderable: false, searchable: false },  
                { data: 'pergunta.status',  name: 'pergunta.status' , orderable: false, searchable: false },  
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ], 
            columnDefs: [ 
                {
                    targets: 6,
                    render: ( row: any, type: any, val: any, meta: any) => {  
                        let texto = '' ; 
                        let buttom = '' ;  
                        for (const key in val) { 
                            if(key != 'action'){
                                buttom = buttom + ' data-' + key + '="'+ val[key] +'"'  
                            } 
                        }
                        
                        texto = texto + '<button title="Adicionar" class="btn btn-success btn-sm" btn-editar '+ buttom +'  data-toggle="modal" data-target="#' +
                        this.modal_atualizar  + '"><i class="fa fa-edit"></i></button>';   
                        
                        texto = texto + '<button class="btn btn-primary btn-sm" btn-visualizar-pergunta '+ buttom +' title="Excluir"><i class="fa fa-eye "></i></button>';   

                        return texto;  

                        
                    }, 
                } 
            ], 
        };

        
		salvar() {
            if( this.banca_id != null && this.pesquisa != null && this.disciplina_id != null ){
                window.alertProcessando();
                const dados = {
                    banca_id: this.banca_id,  
                    pesquisa: this.pesquisa,  
                    disciplina_id: this.disciplina_id 
                };   
                moduloService.cadastrarBateria( this.modulo.id, this.$route.params.id , dados )
                .then(response => {
                    window.toastSucesso(response);  
                    window.alertProcessandoHide(); 
                    this.reloadDatatable = !this.reloadDatatable;
                })
                .catch(error => {  
                    window.alertProcessandoHide(); 
                    console.log(error);
                }); 
            } 
		} 
        
		questionariar() { 
            window.alertProcessando();  
            bateriaService.questionariar( this.$route.params.id  )
            .then(response => {
                window.toastSucesso(response);  
                window.alertProcessandoHide(); 
                this.reloadDatatable = !this.reloadDatatable;
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
            });  
		} 
        
		buscar() {
            if( this.banca_id != null && this.pesquisa ){
                window.alertProcessando();
                const dados = {
                    banca_id: this.banca_id,  
                    pesquisa: this.pesquisa 
                };   
                moduloService.buscarPerguntaIds( this.modulo.id, this.$route.params.id , dados )
                .then(response => {
                    this.ids = response;
                    window.alertProcessandoHide(); 
                })
                .catch(error => {  
                    window.alertProcessandoHide(); 
                    console.log(error);
                }); 
            } 
		} 
        
        
		salvarImport() { 
            window.alertProcessando(); 
            let dados = {};
            if( this.identificador_api_min != null && this.identificador_api_max != null  ){
                dados = {
                    identificador_api_min: this.identificador_api_min,  
                    identificador_api_max: this.identificador_api_max,  
                };   
            }
            moduloService.cadastrarBateriaImport( this.modulo.id, this.$route.params.id , dados )
            .then(response => {
                window.toastSucesso(response);  
                window.alertProcessandoHide(); 
                this.reloadDatatable = !this.reloadDatatable;
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
            });  
		} 

		created() { 
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else{
                window.alertProcessando();
                bateriaService.getModel( this.$route.params.id )
                .then(response => {
                    this.model = response ;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.alertProcessandoHide();
                    
                    window.toastErro('Não foi possivel achar Configuração');
                }); 
            } 
		}
 
        bateriaQuestaoAdicionada(event: any) { 
            this.reloadDatatable = !this.reloadDatatable;
        }
 
        public tableatualizada( ) {   

            $('[btn-visualizar-pergunta]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('pergunta_id'); 
                window.open('#/v2/administracao/pergunta/show/' + id);  
            });

            $('[btn-editar]').click(  (btn) => {   
                this.dados =    $(btn.currentTarget).data( );  
            });

            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        bateriaQuestaoService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });
        }

	}
 

