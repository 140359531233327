


















/* eslint-disable @typescript-eslint/camelcase */ 

	import { tagAdminService  }  from '@/servicegerente';

	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        private url_retorno: any = '/tag' ;
        private tag: any = null ;

        private config = { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: tagAdminService.getUrl() + '/' + this.$route.params.id + '/assuntos/datatable'
			},
			columns: [
			{ data: 'nome', name: 'assunto.nome' },
			{ data: 'descricao', name: 'assunto.descricao' }, 
			],
		};


		created() { 
			window.alertProcessando();
			tagAdminService.getTag( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.tag = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o tag', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}


	}
	
 
