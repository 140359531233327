


















    /* eslint-disable @typescript-eslint/camelcase */ 

    import { perguntaAdminService } from '@/servicegerente';

    import TagFormAdicionar from './_TagFormAdicionar.vue';

    import $ from "jquery";


    import { Watch, Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { 
            'tag-form-adicionar': TagFormAdicionar
        },
    })
    export default class Principal extends Vue { 

        private tags: any= null; 

        private pergunta: any= null; 
        
        private reloadDatatable: any= false; 

        created() {
            window.alertProcessando();
            perguntaAdminService.getPergunta(this.$route.params.id)
                .then(response => {
                    window.alertProcessandoHide();
                    this.pergunta = response;
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o Pergunta', error.data);
                    window.alertProcessandoHide(); 
                });
 
        }
            

        private config: any= {  
            order: [  [1, "asc"] ],
            lengthMenu : [ [ -1],   [  "Todos"]  ],
            dom: '<"top"r>t<"bottom"><"clear">',
            ajax: {
                url: perguntaAdminService.getUrl() + '/' + this.$route.params.id + '/tag/datatable'
            },
            columns: [
                {  data: 'nome', name: 'tag.nome'  },
                { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center' }
            ],
        };  
        
        
        tagAdicionada(event: any) {
            this.tags = event; 
            this.$emit('tagAalterada', event);
            this.reloadDatatable = !this.reloadDatatable;
        }

        reload(event: any) {
            this.buscarTag();
            this.reloadDatatable = !this.reloadDatatable; 
        }

        buscarTag() {
            window.alertProcessando();
            perguntaAdminService.buscarTag(this.$route.params.id )
                .then(response => {
                    this.tags = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os Tags para adiocionar", error.data);
                    window.alertProcessandoHide();
                });
        }


        @Watch('pergunta')
		changePergunta(newVal: any) {
			 this.buscarTag();
        }
        

         public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perguntaAdminService.removerTag( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
                            this.tags = response;
                            this.$emit('tagAalterada', event);
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Permissao removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover a Permissao' , ''); 
                        });  
                    }
                ); 
            });

        }




    }


 

