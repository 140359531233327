














































































































































































































































 
	
	/* eslint-disable @typescript-eslint/camelcase */ 
	import PlanoTable from './_PlanoTable.vue'  
    
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { userService  }  from '@/servicegerente'; 

	@Component({
		components: { 
            PlanoTable
        },
	 })
    export default class UsuarioShow extends Vue {
		
        public model: any = null;
        
		public relatorio_dia_semana: any = null;
		public relatorio_dia_hora: any = null;
		public relatorio_disciplina: any = null;
 
		public apiUrl: any= null;
		public urlRetorno: any= null;
		public urlEditar: any= null;
		public urlPlano: any= null;
		public urlPontuacao: any= null;
		public urlPontuacaoEdit: any= null;



		public errors: any = { };


        public titulo: any= null;
		public mensagem: any= null;
		public userId: any= null;

        public config: any= { 
			order: [[ 4, "desc" ]],
			ajax: { 
				url: userService.getUrl() + '/' + this.$route.params.id + '/plano/datatable'
			},
			columns: [
				{ data: 'plano_id', name: 'usuario_plano.plano_id'  },
				{ data: 'nome', name: 'planos.nome' },
				{ data: 'valor_mensal', name: 'planos.valor_mensal' }, 
				{ data: 'id_responsavel', name: 'id_responsavel' }, 
				{ data: 'data_fim', name: 'data_fim' }, 
				{ data: 'modulo_id', name: 'modulo_id' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  


		salvarMensagem() {
			window.alertProcessando();
			const dados = {
				titulo : this.titulo, 
				mensagem : this.mensagem,  
				userId : this.$route.params.id,  
			};  
			userService.notificar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide(); 
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


		created() { 
			this.apiUrl =  userService.getUrl() + '/' + this.$route.params.id; 
            this.urlRetorno =  '/usuario'; 
            this.urlEditar =  '/usuario/edit/' + this.$route.params.id; 
            this.urlPlano =  '/usuario/' + this.$route.params.id + '/plano' ; 
            
            
            
            this.buscarRelatorioDiaSemana();
            this.buscarRelatorioDiaHora();
            this.buscarRelatorioDisciplina();

			window.alertProcessando();
			userService.getUsuario( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
                this.urlPontuacao =  '/usuario/' + this.model.identificador + '/pontuacao/historico' ; 
                this.urlPontuacaoEdit =  '/usuario/' + this.model.identificador + '/pontuacao' ; 
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o usuario', error.data);
				window.alertProcessandoHide();
			}); 
        }
        
        buscarRelatorioDiaSemana() { 
            // window.alertProcessando();  
            userService.buscarTentativasUsuarioDiaSemana(  this.$route.params.id  )   
			.then(response => {
				// window.alertProcessandoHide();
				this.relatorio_dia_semana = response;
			})
			.catch(() => { 
				// window.alertProcessandoHide(); 
            });  
        }

        buscarRelatorioDiaHora() {  
            userService.buscarTentativasUsuarioDiaHora(  this.$route.params.id  )   
			.then(response => { 
				this.relatorio_dia_hora = response;
			})
			.catch(() => {  
                // window.alertProcessandoHide(); 
            });  
        }


        buscarRelatorioDisciplina() {  
            userService.buscarTentativasUsuarioDisciplina(  this.$route.params.id  )   
			.then(response => { 
				this.relatorio_disciplina = response;
			})
			.catch(() => {  
                // window.alertProcessandoHide(); 
            });  
        }


        get totalRespostaDiaSemana(): number {
            let total = 0; 
            for (let i = 0 ; i <= this.relatorio_dia_semana.length - 1; i++) { 
				total +=  parseInt(this.relatorio_dia_semana[i].count);
            }  
            return total;
        }


        get totalRespostaHoraDia(): number {
            let total = 0; 
            for (let i = 0 ; i <= this.relatorio_dia_hora.length - 1; i++) { 
				total +=  parseInt(this.relatorio_dia_hora[i].count);
            }  
            return total;
        }
 
	} 

