








































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import {   ementaService } from '@/servicegerente'; 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	   
	@Component({
		components: {  },
	 })
    export default class GuiaEdit extends Vue {
  
 
        public model: any = null; 
		public nome: any= null;  
		public ativo: any= null;

		public urlRetorno: any= '/v2/administracao/ementa/show/' + this.$route.params.id; 
		public errors: any = { };
  
        created() {  
            window.alertProcessando();
            ementaService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o configuração ', error.data);
                    window.alertProcessandoHide(); 
                }); 
        }
 
        @Watch('model')
		tabelaAtualizada(newVal: any) {  
			this.nome = this.model.nome;   
			this.ativo = this.model.ativo; 
        }
         
		salvar() {
			window.alertProcessando();
			const dados = {  
				nome: this.nome,  
				ativo: this.ativo,  
			};   
			ementaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
  
    }
 
