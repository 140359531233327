







































	
	import { resumoAdminService }  from '@/servicegerente';
	

	import { Watch, Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class ResumoVisualizacao extends Vue { 

        private model: any = null ;
        private tema: any = false ;


		@Watch('tema')
		tabelaAtualizada(newVal: any) {
			const temaColor: any = document.head.querySelector('meta[name="theme-color"]');
			if(this.tema){
				if(temaColor){
					temaColor.content= '#111';
				} 
			}
			else{
				if(temaColor){
					temaColor.content= '#00a65a';
				} 
			}  
		}



		created() {
 
			window.alertProcessando();
			resumoAdminService.getResumo( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				 
				window.toastErro('Não foi possivel achar Resumo');
            });
             
		}

		 


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = true ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
				header.hidden = false ; 
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}




	}

 

