






















	// import FormUsuarioPlano from './_PlanoFormAdicionar.vue'  
	// import Curso from './_Curso.vue'  
	// import FormBonificarPRO from './_formBonificarPRO.vue'  
	 
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
    import $ from "jquery" 

    import { usuarioService  }  from '@/servicegerente';  


	@Component({
		components: { 
			// 'plano-form-adicionar': FormUsuarioPlano,
			// 'plano-form-bonificar': FormBonificarPRO,
			// 'curso': Curso,
        },
	 })
    export default class UsuarioPlano extends Vue {

		@Prop() readonly usuario!: any;
		@Prop() readonly modulo!: any;
 
		private planos: any= null; 
		private reloadDatatable: any= false; 
 
				 
		// private url_retorno: any= '/usuario'; 
		// private url_historico: any= '/usuario/' + this.$route.params.id + '/plano/historico'; 
		 
		
		private config: any= { 
			order: [[ 4, "desc" ]],
            // dom: "<'row'<'col-xs-12'<'col-xs-12' ><'col-xs-12' >>r>"+
            //     "<'row'<'col-xs-12't>>"+
            //     "<'row'<'col-xs-12'<'col-xs-12'p><'col-xs-12' >>>",
            lengthMenu:  [ [ 5 ],   [  5]  ],

			ajax: { 
				url: usuarioService.getUrl() + '/' + this.usuario.id + '/modulo/' + this.modulo.id + '/plano/datatable'
			},
			columns: [
				{ data: 'plano_id', name: 'usuario_plano.plano_id'  },
				{ data: 'nome', name: 'planos.nome' },
				{ data: 'valor_mensal', name: 'planos.valor_mensal' }, 
				{ data: 'id_responsavel', name: 'id_responsavel' }, 
				{ data: 'data_fim', name: 'data_fim' },  
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};  

		created() { 
			// window.alertProcessando();
			// userService.getUsuario( this.$route.params.id )  
			// .then(response => {
			// 	window.alertProcessandoHide();
			// 	this.usuario = response;
			// })
			// .catch(error => {
			// 	window.toastErro('Não foi possivel achar o Usuário', error.data);
			// 	window.alertProcessandoHide();
			// 	this.$router.push(this.url_retorno);
			// });  
 
		}


		@Watch('usuario')
		usuarioAlterado(newVal: any) {
			// this.buscarPlanos();
		}


		 
		planoAdicionado(event: any) {
			this.planos = event; 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		planoAlterado(event: any) { 
			this.reloadDatatable = !this.reloadDatatable; 
		}

		buscarPlanos() {
			// window.alertProcessando();
			usuarioService.buscarPlanos( this.$route.params.id )   
			.then(response => {
				this.planos = response;
				// window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os planos para adiocionar", "");
				// window.alertProcessandoHide();
			});   
		}


		public tableatualizada( ) {  

			//  $('[btn-excluir]').click(  (btn) => {  
            //     const id =   $(btn.currentTarget).data('id');  
            //     window.alertConfimacao('Confirma a Exclusão ','' , 
            //          () => {  
            //             window.alertProcessando(); 
            //             usuarioService.removerPlano( this.$route.params.id,  id )
            //             .then(response => {
			// 				window.alertProcessandoHide();  
			// 				this.planos = response;
            //                 this.reloadDatatable = !this.reloadDatatable; 
            //                 window.toastSucesso('plano removido com sucesso!!' ); 
            //             })
            //             .catch(error => { 
			// 				this.reloadDatatable = !this.reloadDatatable; 
            //                 window.alertProcessandoHide();
            //                 window.toastErro('Não foi possivel remover o plano' , ''); 
            //             });  
            //         }
            //     ); 
            // });

		}
		


	}


	  

