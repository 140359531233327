
























































































































































































































































 
/* eslint-disable @typescript-eslint/camelcase */ 
  
	import { moduloAdminService , tipoReferenciaService }  from '@/servicegerente';

	import Editor from '@tinymce/tinymce-vue'
	
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
		
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class ModuloCadastro extends Vue {


        private tipoReferencias: any = null;



		private nome: any = null;
		private descricao: any= null;
		private tipo_referencia_id: any= null;
		private nivel: any= 'Aberto';
		private imagem: any= 'livro.jpg';
		private valor: any= 'Gratis'; 
		private is_desafio: any= false;


		private has_noticia: any= false;
		private has_conceito: any= false;
		private has_simulado: any= false;
		private has_prova_anterior: any= false;
		private ativo: any= true;
		private restrito: any= false;

		private has_pergunta: any= false;
		private has_material: any= false;
		private has_relatorio: any= false;
		private has_audio: any= false;

		private termo_uso: any= '';
		private termo_simulado: any= '';

 


		private urlRetorno: any=  '/modulo';

		private errors: any = { };

		  
		created() { 
            tipoReferenciaService.getAll()
                .then(response => {
                    this.tipoReferencias = response;
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar as tipo Referencias');
                }); 
        }


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao, 
				nivel :this.nivel, 
				imagem :this.imagem, 
				valor :this.valor, 
				is_desafio :this.is_desafio, 
				has_conceito :this.has_conceito, 
				has_simulado :this.has_simulado, 
				has_noticia :this.has_noticia, 
				has_prova_anterior :this.has_prova_anterior, 
				has_pergunta :this.has_pergunta, 
				has_material :this.has_material, 
				has_relatorio :this.has_relatorio, 
				tipo_referencia_id :this.tipo_referencia_id, 
				ativo :this.ativo, 
				restrito :this.restrito, 
				has_audio :this.has_audio, 
				termo_simulado :this.termo_simulado, 
				termo_uso :this.termo_uso, 
			};  
			 
			 
			moduloAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


	}

  

