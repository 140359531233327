





























































































































































































































/* eslint-disable @typescript-eslint/camelcase */ 
 /* eslint-disable @typescript-eslint/no-explicit-any */ 
  	/* eslint-disable @typescript-eslint/no-unused-vars */ 
	import {cardService, moduloService , manzaTinyEditorColorsMap }  from '@/servicegerente';
    import Editor from '@tinymce/tinymce-vue' 
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {   
			'editor': Editor
        },
	 })
    export default class AudioEdicao extends Vue {

        public model: any = null; 
        public colors: any = []; 
         
		

        public modulos: any = null;
        public disciplinas: any = null; 
        public assuntos: any = null; 
         

		public titulo: any = null; 
		public conteudo: any = null; 
		public nivel: any = null; 
		public tag_id: any = null; 
		public pergunta_id: any = null; 
		public tipo: any = null; 
		public videoaula: any = null; 
		public assunto_id: any= null; 
		

		public proximo_secundario_id: any = null; 
		public fonte_id: any = null; 
		public proximo_id: any = null; 
		public anterior_id: any = null; 
 
 
		public modulo_id: any= null;  
		public disciplina_id: any= null; 
	   
		public urlRetorno: any= '/v2/administracao/card'; 
		public errors: any = { };

		

		@Watch('disciplina_id')
		disciplinaAlterada(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.assuntos = null ;
			this.assunto_id = null ; 
		}

       	created() {
			window.alertProcessando(); 
			cardService.getCard(this.$route.params.id , true) 
			.then(response => {
				this.model = response ;  
				moduloService.buscarTodasDisciplina( response.modulo_id ) 
				.then(response => {
					this.disciplinas = response ; 
					this.buscarAssunto(this.disciplina_id);
				})
				.catch(error => {
					window.toastErro('Não foi possivel achar as disciplinas');
				}); 
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a audio aula', error.data);
				window.alertProcessandoHide(); 
			}); 

			this.colors = manzaTinyEditorColorsMap;

			moduloService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			});

		}


		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.modulo_id = this.model.modulo_id; 
			this.titulo = this.model.titulo; 
			this.nivel = this.model.nivel; 
			this.tipo = this.model.tipo; 
			this.disciplina_id = this.model.disciplina_id; 
			this.proximo_secundario_id = this.model.proximo_secundario_id; 
			this.proximo_id = this.model.proximo_id; 
			this.anterior_id = this.model.anterior_id; 
			this.fonte_id = this.model.fonte_id; 
			this.videoaula = this.model.videoaula; 
			this.pergunta_id = this.model.pergunta_id; 
			this.tag_id = this.model.tag_id; 
			this.conteudo = this.model.conteudo;  
			this.assunto_id = this.model.assunto_id;  
			 
		}


		
		
		buscarAssunto( id: any) { 
			if(this.disciplinas){
				const filtrada = this.disciplinas.filter( ( disciplina: any) => { 
					if (disciplina.id == id) {
						return true;
					}
				})[0]; 
				if(filtrada){
					this.assuntos = filtrada.assuntos ;
				} 
			}
		}

		 
		salvar() {
			window.alertProcessando();

			const dados = {
				modulo_id: this.modulo_id,
				titulo: this.titulo,
				nivel: this.nivel,
				tipo: this.tipo,
				disciplina_id: this.disciplina_id,
				proximo_secundario_id: this.proximo_secundario_id,
				proximo_id: this.proximo_id,
				anterior_id: this.anterior_id,
				fonte_id: this.fonte_id,
				videoaula: this.videoaula,
				pergunta_id: this.pergunta_id,
				tag_id: this.tag_id,
				assunto_id: this.assunto_id,
				conteudo: this.conteudo
			}    
			 
			 
			cardService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  '/v2/administracao/card/show/' +   this.$route.params.id )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
	
 
		
	}

 


