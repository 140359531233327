

























     
	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { tagService ,  assuntoAdminService  }  from '@/servicegerente'; 
	import $ from "jquery" ;
    import { authHeader } from '@/servicegerente';  

	@Component({
		components: { 
            
          },
	 })
    export default class FormAssuntoTagMulti extends Vue {

        @Prop( ) readonly assunto!: any

        public tag: any = null;

        public add_tag_id = 'modal-seguranca-assunto-adicionar-tag'; 
        
        multiAlterado(valor: any){
            this.tag = valor;
        }

        AdicionarTag() {
            if (this.tag) { 
                window.alertProcessando();
                const dados = {
                    tag :this.tag, 
                };    
                assuntoAdminService.adicionarTag( this.assunto.id ,  dados )
                .then(response => {
                    window.toastSucesso("tag adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('tagAdicionado', response);
                    // ( $('#' + this.add_tag_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 
        
        public processa(params: any){
            return {
                term: params.term,  
                assunto: this.assunto.id ,  
            };
        }


        public ajaxOptions = {
            url: tagService.getUrl() + '/buscar/ajax'  ,  
            dataType: 'json',
            type: "GET",
            delay: 1000,
            headers: authHeader(), 
            data: this.processa, 
            processResults: function(data: any ) {
                return {
                    results: $.map(data, function (item) {
                    return {
                        text: item.nome    , 
                        id: item.id
                    }
                }) 
                }	 
            },
            cache: true
        } 
 
	}
	 
