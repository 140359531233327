import { VuexModule, Module,  Mutation, Action } from 'vuex-module-decorators'
    

const KJUR = require('jsrsasign');  

const KEY = 'ambiente-variavel-modulo';
const KEY_TEMA = 'ambiente-variavel-tema';

const modulo = localStorage.getItem(KEY);  

const tema = localStorage.getItem(KEY_TEMA);  
 

@Module({
    namespaced: true, 
})

class User extends VuexModule {
      
 

    //=========================================================================================
    //================================ TEMA ===================================================
    //=========================================================================================


    public tema: boolean = tema ?   JSON.parse( tema ) : false; 

    @Mutation
    public alterarTemaMutation(  tema: boolean ) {
        this.tema = tema; 
    }
     
    @Mutation 
    public resetTemaMutation( ) {  
        this.tema = false ; 
    }
 
    @Action
    public alterarTema(tema: boolean) {  
        localStorage.removeItem(KEY_TEMA);
        localStorage.setItem(KEY_TEMA, JSON.stringify(tema));
        this.context.commit('alterarTemaMutation', tema )   
    }
 
    @Action
    public resetTema( ): void { 
        localStorage.removeItem(KEY_TEMA);
        this.context.commit('resetTemaMutation' ) ;  
    }

    


}
export default User