import { render, staticRenderFns } from "./_Curso.vue?vue&type=template&id=7a2bb123&scoped=true"
import script from "./_Curso.vue?vue&type=script&lang=ts"
export * from "./_Curso.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2bb123",
  null
  
)

export default component.exports