/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..'; 
import { segurancaModel } from './index.model';  
import axios  from 'axios'; 
 
const url = segurancaModel.getUrl() + '/controle' ; 
  
import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}
 
   


function atualizacaoDiaria() { 
	return  new Promise( (resolve, reject) => {
		axios.get( url  + "/atualizacao/diaria" , { headers: authHeader() }  ) 
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}


function atualizacaoDisciplinaModulo() { 
	return  new Promise( (resolve, reject) => {
		axios.get( url  + "/atualizacao/modulo/disciplina" , { headers: authHeader() }  ) 
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}



function atualizacaoRespostas() { 
	return  new Promise( (resolve, reject) => {
		axios.get( url  + "/atualizacao/respostas" , { headers: authHeader() }  ) 
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}

function atualizacaoUsuarioGeral() { 
	return  new Promise( (resolve, reject) => {
		axios.get( url  + "/atualizacao/usuarios" , { headers: authHeader() }  ) 
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}





function enviarMensagemTodosUsuario(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/email/todos/usuarios' , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function testeEnviarMensagem(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/email/teste' , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 



function processaImport(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/processa/import' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 


function processarImportAnulada(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/processa/import/anulada' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
  


function inserirImport(   dados: any   ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/inserir/import' , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
   



  
  


function processarImagemDasPerguntas(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/processa/imagem/correcao' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

  


function limparPerguntasDuplicada(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/limpar/questoes/duplicada' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  

function limparPerguntasFinalizada(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/limpar/questoes/finalizada' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  

function processarPerguntaDuplicada(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/processar/questoes/duplicada' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function adicionarPerguntaFirestoreJob(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/adicionar/pergunta/firestorejob' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function migrarPerguntaPrincipalToSecundario(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/processa/questoes/principal/secundario' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function limparPerguntasGrande(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/limpar/questoes/grande' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function limparPerguntasSuspensa(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/limpar/questoes/suspensa' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

function limparTabelaPerguntaAssunto(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/limpar/tabela/perguntaassunto' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
 

function limparTabelaTagPergunta(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/limpar/tabela/tagpergunta' , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
 

function migrarS3(    ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url  + '/processa/s3' , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 


function atualizacaoCardCacheJob( dados: any   ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/processa/cache/card' , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 


export const controleService = { 
	atualizacaoCardCacheJob,
    atualizacaoDiaria,
	enviarMensagemTodosUsuario,
	testeEnviarMensagem,
	atualizacaoDisciplinaModulo,
	atualizacaoRespostas, 
	atualizacaoUsuarioGeral, 
	processaImport,
	processarImportAnulada,
	inserirImport,
	processarImagemDasPerguntas,
	limparPerguntasDuplicada,
	limparPerguntasGrande,
	limparPerguntasSuspensa,
	limparPerguntasFinalizada,
	processarPerguntaDuplicada,
	adicionarPerguntaFirestoreJob,
	limparTabelaPerguntaAssunto,
	limparTabelaTagPergunta,
	migrarS3,
	migrarPerguntaPrincipalToSecundario,
};
