















































 
    /* eslint-disable @typescript-eslint/camelcase */ 
	 
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { perguntaAdminService , disciplinaAdminService }  from '@/servicegerente' ; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormPerfilPermissao extends Vue {

        @Prop( ) readonly assuntos!: any
        @Prop( ) readonly pergunta!: any 



        private assuntos_list: any = null; 

        private assunto: any = null; 

        private disciplinas: any = null; 
		private disciplina: any = null;

        private add_assunto_id = 'modal-seguranca-pergunta-adicionar-assunto'; 

        @Watch('disciplina')
		alterouDisciplina(newVal: any) {
			if(this.disciplina){
				this.buscarAssunto(this.disciplina);
				return;
			}
			this.assuntos_list = [];
			// this.assuntos_list = this.assuntos;
		}


        // @Watch('assuntos')
		// alterouAssuntos(newVal: any) { 
		// 	this.assuntos_list = this.assuntos;
		// }

        created() {  
            // this.assuntos_list = this.assuntos;
			disciplinaAdminService.getAll() 
			.then(response => {
				this.disciplinas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os disciplinas');
			});  
		}



        buscarAssunto(id: any) {
            window.alertProcessando();
            perguntaAdminService.buscarAssuntoDisciplina(this.pergunta.id  , id )
                .then(response => {
                    this.assuntos_list = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro("Não foi possivel achar os assuntos para adiocionar", error.data);
                    window.alertProcessandoHide();
                });
        }


         



        AdicionarAssunto() {
            if (this.assunto) { 
                window.alertProcessando();
                const dados = {
                    assunto : this.assunto  
                };    
                perguntaAdminService.adicionarAssunto( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("assunto adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('assuntoAdicionado', response);  
                    ( $('#' + this.add_assunto_id) as any ) .modal('toggle');  
                    this.disciplina = null ;
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }

 
