





























































































































 
	 
	/* eslint-disable @typescript-eslint/camelcase */ 

	 
	import { assuntoAdminService, resumoAdminService, moduloAdminService , disciplinaAdminService }  from '@/servicegerente';

	import Editor from '@tinymce/tinymce-vue'
  
	import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PermissaoCadastro extends Vue {

        private modulos: any = null;
        private disciplinas: any = null; 
		private assuntos: any = null;
         
		private titulo: any = null;
		private tipo: any= null;
		private disciplina_restrito: any= "1";
		private restrito: any= null; 
		private conteudo: any= null; 
		private modulo_id: any= null; 
		private disciplina_id: any= null; 
		private pdf: any= null; 
		private assunto_id: any= null;  

	  

		private urlRetorno: any= '/resumo';

		private errors: any = { };

        created() { 

			this.buscarTodosAssunto( ) ; 

			moduloAdminService.getAll() 
			.then(response => {
				this.modulos = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
			});

			disciplinaAdminService.getAll() 
			.then(response => {
				this.disciplinas = response ;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas');
			}); 
		}



		@Watch('disciplina_id')
		mudouDisciplina(newVal: any) {
			if(this.disciplina_id){
				this.buscarAssunto(this.disciplina_id);
				return;
			}
			this.buscarTodosAssunto( );
		}

		buscarAssunto(id: any) {
			window.alertProcessando();
			disciplinaAdminService.buscarTodosAssuntoDaDisciplina(id) 
			.then(response => {
				this.assuntos = response ;
				window.alertProcessandoHide();

			})
			.catch(error => {
				window.toastErro('Não foi possivel achar os assunto');
				window.alertProcessandoHide();
			}); 
		}

		buscarTodosAssunto( ) {
			assuntoAdminService.getAll() 
				.then(response => {
					this.assuntos = response ;
				})
				.catch(error => {
					window.toastErro('Não foi possivel achar os assunto');

				});
		}




		salvar() {
            window.alertProcessando();
            
            const dados = new FormData();
             
			if( this.tipo == 'PDF'){
				dados.append('file_pdf',this.pdf );
			}
			if( this.tipo == 'TEXTO'){
				dados.set('conteudo', this.conteudo );
			}
			
			dados.set('titulo', this.titulo);
			dados.set('tipo', this.tipo);
			dados.set('modulo_id', this.modulo_id);
			dados.set('disciplina_id', this.disciplina_id);
			dados.set('disciplina_restrito', this.disciplina_restrito);
			dados.set('restrito', this.restrito);
			dados.set('assunto_id', this.assunto_id);
 
			resumoAdminService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 


		handleFileUpload(){
			this.pdf = this.$refs.pdf.files[0];
		}


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
	}
	
   
