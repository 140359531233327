





































 
	
	/* eslint-disable @typescript-eslint/camelcase */  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { userService  }  from '@/servicegerente'; 
    import { namespace } from 'vuex-class';
    const gerente = namespace('gerente') 

	@Component({
		components: {  
        },
	 })
    export default class UsuarioShow extends Vue {
		  
        @gerente.State
		public modulo!: any
		  
        private titulo: any= null;
		private mensagem: any= null;
		
        
        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			} 
        }

		salvarMensagem() {
			window.alertProcessando();
			const dados = {
				titulo : this.titulo, 
				mensagem : this.mensagem,  
				moduloId : this.modulo.id,  
			};  
			userService.notificarModulo( dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide(); 
			})
			.catch(error => {  
				window.alertProcessandoHide(); 
				console.log(error);
			});  
		}  
	} 

