




















/* eslint-disable @typescript-eslint/camelcase */ 


	import { tagAdminService  }  from '@/servicegerente'; 
	import $ from "jquery"   
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

        private url_retorno: any = '/tag' + '/show/' + this.$route.params.id ;
        private tag: any = null ;

        private config = { 
			order: [[ 0, "asc" ]],
			ajax: { 
				url: tagAdminService.getUrl() + '/' + this.$route.params.id + '/perguntas/datatable'
			},
			columns: [
				{ data: 'pergunta_id', name: 'pergunta_id' },
				{ data: 'texto_clean', name: 'texto_clean' , orderable: false },
				{ data: 'videoaula', name: 'pergunta.videoaula' },
				{ data: 'status', name: 'pergunta.status' },
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center' }
			],
		};


		created() { 
			window.alertProcessando();
			tagAdminService.getTag( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.tag = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o tag', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}


		public tableatualizada( ) {  
              
            $('[btn-visualizar-pergunta]').click(  (btn) => {  
				const id =   $(btn.currentTarget).data('id'); 

				window.open('#/pergunta/show/' + id);

                // this.$router.push('/tag/' + id + '/perguntas');
            });
	 
		}

	}

 
