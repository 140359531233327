










































































































    /* eslint-disable @typescript-eslint/camelcase */  

    import {   tagService , moduloService , assuntoAdminService, ementaTagService, cardService } from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
    const gerente = namespace('gerente') 
    
	import ConceitoAjaxAdicionar from './_ConceitoAjaxAdicionar.vue'
	import ConceitoAjaxMultiAdicionar from './_ConceitoAjaxMultiAdicionar.vue'
 
    @Component({
        components: {
            'conceito-form-adicionar': ConceitoAjaxAdicionar, 
            'conceito-form-adicionar-multi': ConceitoAjaxMultiAdicionar, 
         },
    })
    export default class Principal extends Vue { 

        @usuario.Getter
		public isAdmin!:  (  ) => boolean

        @gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public assunto!: any
 
        @gerente.State
		public conceito!: any
 
        @gerente.State
		public conceitos!: Array<any>

        @gerente.State
		public ementa!: any

        @gerente.State
		public card!: any
  
        
        @gerente.Action
        public resetDisciplina!: (   ) => void

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
 
        @gerente.Action
        public resetAssunto!: (   ) => void

        public limparAssunto( ) {  
            this.resetAssunto();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
  
        public limparConceito( ) {  
            this.resetConceito(); 
        }
 
        @gerente.Action
        public resetConceito!: (   ) => void
 
        @gerente.Action
        public alterarConceito!: ( conceito: any  ) => void
  
        @gerente.Action
        public resetConceitos!: (   ) => void
         
        public limparConceitos( ) {  
            this.resetConceitos(); 
        }

        @gerente.Action
        public resetCard!: (   ) => void


        
		public tagAdicionado( ) { 
			this.reloadDatatable = !this.reloadDatatable; 
		}


        public atualizaUrl( ) {  
            if(!this.disciplina){
				 this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/tags/datatable'
			}
			else if(!this.assunto){
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/tags/datatable'
			} 
            else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id +'/assunto/' + this.assunto.id + '/tags/datatable'
            }
        }


        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                this.atualizaUrl( );
            } 
        }



        public datatableKey = 0;
        public  reloadDatatable = false ; 

        public config = { 
            responsive: {
                details: { 
                    display: $.fn.dataTable.Responsive.display.modal( {
                        header: function ( row: any ) {  
                            return 'Descrição' ;
                        }, 
                    } ),

                    renderer: function ( api: any, rowIdx: any, columns: any ) {
                        const data = $.map( columns, function ( col, i ) {
                            return col.hidden ?
                                '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+ '<td class="escuro-modal-tag">'+col.data+'</td>'+
                                '</tr>' + '<tr><td><br><hr></td></tr>'+ '<tr data-dt-row="'+col.rowIndex+'" data-dt-column="'+col.columnIndex+'">'+ '<td>'+col.data+'</td>'+ '</tr>' : '';
                        } ).join('');
                            
                        return data ?
                            $('<table/>').append( data ) :
                            false;
                    } 
                }
            }, 
            order: [  [2, "asc"] ],
            ajax: {
                url: tagService.getUrl() + '/datatable',
            },
            columns: [
                { "class": "details-control text-success", "orderable": false, "data": null, "defaultContent": ""  , searchable: false },  
                { data: 'id', name: 'id',  class: 'text-center'  , searchable: false},
                { data: 'nome',  name: 'nome'   }, 
                { data: 'descricao',  name: 'descricao',  class: 'none' }, 
                { data: 'perguntas_count', name: 'perguntas_count', class: 'text-center' , searchable: false},
                { data: 'perguntas_chs_count', name: 'perguntas_chs_count', class: 'text-center' , searchable: false},
				{ data: 'cards_count', name: 'cards_count', class: 'text-center', orderable: false, searchable: false, },  
				{ data: 'ementas_count', name: 'ementas_count', class: 'text-center', orderable: false, searchable: false, },  
				{ data: 'id', name: 'id', class: 'text-center', orderable: false, searchable: false, },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ],

            columnDefs: [ 
                {
                    targets: 8,
                    render: ( row: any, type: any, val: any, meta: any ) => {  
                        let texto = '' ; 
                        let buttom = '' ;  
                        for (const key in val) { 
                            if(key != 'action' && key != 'descricao'){
                                buttom = buttom + ' data-' + key + '="'+ val[key] +'"'  
                            } 
                        } 
                        texto =  texto + '<button title="Add Ementa" class="btn btn-success btn-sm" btn-add-ementa '+ buttom +' "><i class="fa fa-plus"></i> Add Ementa </button>' ;   
                        texto =  texto + '<button title="Add Ementa" class="btn btn-warning btn-sm" btn-add-card '+ buttom +' "><i class="fa fa-plus"></i> Add Card </button>' ;   
                        return texto;  
                    }, 
                } 
            ], 

        };
 

        
        public adicionarTag( id: any ) {
            if (id  && this.ementa ) { 
                window.alertProcessando();
                const dados = {
                    ementa_id :this.ementa.id,  
                    //sequencia : 1, 
                    tag_id : id , 
                };    
                ementaTagService.cadastrar( dados )
                .then(response => {
                    window.toastSucesso("permissao adicionado com successo.");
                    window.alertProcessandoHide(); 
                    this.tagAdicionado();
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500); 
            }
        }  

        
        public adicionarCard( id: any ) {
            if (id  && this.card ) { 
                window.alertProcessando();
                const dados = {
                    fonte_id :this.card.id,   
                    tag_id : id , 
                };    
                cardService.storeCardConceito( dados )
                .then(response => {
                    window.toastSucesso("card adicionado com successo.");
                    window.alertProcessandoHide(); 
                    this.tagAdicionado();
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
                setTimeout(() => {
                    window.alertProcessandoHide();
                }, 1500); 
            }
        }  





        public tableatualizada( ) {  
              
            $('[btn-select]').click(  (btn) => {   
                this.alterarConceito($(btn.currentTarget).data( )); 
                window.toastSucesso('Conceito selecionada com sucesso!!' );  
            });


            $('[btn-add-ementa]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.adicionarTag(id);
            });

            $('[btn-add-card]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.adicionarCard(id);
            });


            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/conceito/edit/' + id); 
                // this.$router.push('/tag/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/administracao/conceito/show/' + id);
            });
            $('[btn-visualizar-assuntos]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/administracao/conceito/' + id + '/assuntos');
            });
            $('[btn-visualizar-perguntas]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/conceito/' + id + '/perguntas'); 
                // this.$router.push('/tag/' + id + '/perguntas');
            });
     
            
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        tagService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
            

            $('[btn-desativar]').click(  (btn) => { 
                if(this.assunto){
                    const id =   $(btn.currentTarget).data('id');  
                    window.alertConfimacao('Confirma a remoção da tag do assunto ','' , 
                            () => {  
                            window.alertProcessando(); 
                            assuntoAdminService.removerTag( this.assunto.id,  id )
                            .then(response => {
                                window.alertProcessandoHide();   
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.toastSucesso('tags removido com sucesso!!' ); 
                            })
                            .catch(error => { 
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.alertProcessandoHide();
                                window.toastErro('Não foi possivel remover a tags' , ''); 
                            });  
                        }
                    ); 
                } 
                  
            });


            
			$('[btn-excluir]').click(  (btn) => {  
                if(this.assunto){
                    const id =   $(btn.currentTarget).data('id');  
                    window.alertConfimacao('Confirma a remoção da tag do assunto ','' , 
                            () => {  
                            window.alertProcessando(); 
                            assuntoAdminService.removerTag( this.assunto.id,  id )
                            .then(response => {
                                window.alertProcessandoHide();   
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.toastSucesso('tags removido com sucesso!!' ); 
                            })
                            .catch(error => { 
                                this.reloadDatatable = !this.reloadDatatable; 
                                window.alertProcessandoHide();
                                window.toastErro('Não foi possivel remover a tags' , ''); 
                            });  
                        }
                    ); 
                } 
			});
 

        }
    }
 
