




















































































	/* eslint-disable @typescript-eslint/camelcase */ 
	/* eslint-disable @typescript-eslint/no-explicit-any */ 
	/* eslint-disable @typescript-eslint/no-unused-vars */ 


	import { perguntaService , moduloService  }  from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class'; 
    const gerente = namespace('gerente') 
    const usuario = namespace('usuario')
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 
         
        @gerente.State
		public modulo!: any

        @usuario.Getter
		public isAdmin!:  (  ) => boolean
 
        @gerente.State
		public disciplina!: any
 
        @gerente.State
		public conceito!: any
 
        @gerente.State
		public assunto!: any
 
        @gerente.Action
        public resetDisciplina!: (   ) => void

        public limparDisciplina( ) {  
            this.resetDisciplina();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
 
        public limparConceito( ) {  
            this.resetConceito(); 
        }
 
        @gerente.Action
        public resetConceito!: (   ) => void
  
        @gerente.Action
        public resetAssunto!: (   ) => void

        public limparAssunto( ) {  
            this.resetAssunto();
            this.atualizaUrl( );
            this.datatableKey += 1;
        }
  
        public atualizaUrl( ) {  
            if(!this.disciplina){
				 this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/perguntas/datatable'
			}
			else if(!this.assunto){
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id + '/perguntas/datatable'
			} 
            else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/' + this.disciplina.id +'/assunto/' + this.assunto.id + '/perguntas/datatable'
            } 
        }
 
        public datatableKey = 0;
        public identificador: any = 1 ; 
        public  reloadDatatable = false ; 
        public  dados: any = '' ; 
        
        
		public padrao( ) {   
            this.config.order =   [[ 2, "desc" ] , [ 5, "asc" ]  ]; 
			this.identificador = 1 ;
		}

        
		public pesquisa( ) {   
            this.config.order =   [[ 2, "desc" ] , [ 5, "asc" ]   ]; 
			this.identificador = 2 ;
		}

        public config = {
            order: [[ 2, "desc" ] , [ 5, "asc" ] ],
			ajax: { 
				url: moduloService.getUrl() + '/datatable',
                data: function ( d: any  ) {  
                    d.conceito = () => (document.querySelector('[data-kt-docs-table-filter="conceitoId"]')! as HTMLInputElement).value  
                    //console.log(d)
                    return d; 
                } 
			},
			columns: [
				{ data: 'id', name: 'pergunta.id'  },   
				{ data: 'texto_clean', name: 'texto_clean', orderable: false  },   
				{ data: 'ano', name: 'ano' },  
				{ data: 'subjects', name: 'subjects' },  
				{ data: 'status', name: 'status' },   
				{ data: 'orgao', name: 'orgao' },  
				{ data: 'correta', name: 'correta'  },    
				{ data: 'rendimento', name: 'rendimento', class: 'text-center' },
				{ data: 'tags_count', name: 'tags_count', class: 'text-center',    searchable: false, }, 
				{ data: 'cards_count', name: 'cards_count', class: 'text-center',   searchable: false, }, 
				{ data: 'videoaula', name: 'pergunta.videoaula', class: 'text-center', searchable: false,}, 
				{ data: 'score_dificuldade', name: 'pergunta.score_dificuldade', class: 'text-center' }, 
				{ data: 'baterias_count', name: 'baterias_count',    searchable: false}, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
        };

        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
            else {
                 this.atualizaUrl( );
            } 
        }
  
        public tableatualizada( dados: any ) {   
            this.dados = dados; 
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/administracao/pergunta/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/pergunta/show/' + id); 
            }); 
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perguntaService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            }); 
            $('[btn-tag-exist]').click(  (btn) => {  
                if (this.conceito) { 
                    const id =   $(btn.currentTarget).data('id');  
                    // window.alertConfimacao('Confirma a inserção da tag: ', this.conceito.nome , () => {   
                            window.alertProcessando();
                            const dados = {
                                tag : this.conceito.id,  
                            };    
                            perguntaService.adicionarTag( id ,  dados )
                            .then(response => {
                                window.toastSucesso("tag adicionado com successo.");
                                window.alertProcessandoHide();
                                this.reloadDatatable = !this.reloadDatatable; 
                                // this.tags = response;
                            })
                            .catch(error => { 
                                window.toastErro(error.message);
                                window.alertProcessandoHide();  
                                console.log(error);
                            });  
                    // }); 
                }
            });
 
            $('[btn-restrita]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.restritar( id ) ; 
            });

            $('[btn-questionairar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.questionairar( id ) ; 
            }); 
 
        }
 
        questionairar( id: any ) { 
            window.alertProcessando();
            perguntaService
                .questionairar( id )
                .then(response => {  
                    window.toastSucesso('sucesso!!' );  
                    this.reloadDatatable = !this.reloadDatatable; 
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                });
        }
        
        restritar( id: any ) { 
            window.alertProcessando();
            perguntaService
                .restritar( id )
                .then(response => {  
                    window.toastSucesso('sucesso!!' );  
                    this.reloadDatatable = !this.reloadDatatable; 
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    console.log(error);
                    window.alertProcessandoHide();
                });
        }
         
	}
	 

