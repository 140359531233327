








































	/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { perguntaAdminService  }  from '@/servicegerente';

	import $ from "jquery"  
 
    import { Component, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
    const usuario = namespace('usuario')
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 
        

        @usuario.State
		public perfis!: any


        private  reloadDatatable = false ; 
        private  dados: any = '' ; 
        private  token = '' ; 
         
        private config = {
            order: [[ 0, "desc" ]],
			ajax: { 
				url: perguntaAdminService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'pergunta.id'  },  
				{ data: 'texto_clean', name: 'texto_clean', orderable: false, searchable: false },   
				{ data: 'texto', name: 'pergunta.texto',  class: 'none' },  
				{ data: 'videoaula', name: 'videoaula' }, 
				{ data: 'status', name: 'status' },  
				{ data: 'referencia', name: 'referencia' }, 
				{ data: 'referencia_questao_nr', name: 'referencia_questao_nr' }, 
				{ data: 'tags_count', name: 'tags_count', class: 'text-center', orderable: false, searchable: false, },
				{ data: 'rendimento', name: 'rendimento', class: 'text-center'  },
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
        };

        created() {
            const temp_token = localStorage.getItem('user');
            if(temp_token){
                this.token =  JSON.parse(temp_token)   ;
            }
              
        }


        public gerarPdf( ) {  
            window.open( perguntaAdminService.getUrl() + '/datatable/pdf?token=' + this.token + '&' + $.param( this.dados));
        }



        get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}


        public tableatualizada( dados: any ) {  
             
            this.dados = dados;

            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/pergunta/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/pergunta/show/' + id);
                // this.$router.push('/pergunta/show/' + id);
            });
     
            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perguntaAdminService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-ativar]').click(  (btn) => {   
                const id =   $(btn.currentTarget).data('id'); 
                window.alertConfimacao('Confirma a Ativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perguntaAdminService.ativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('ativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel ativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perguntaAdminService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

        }



        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
 
	}
	 

