









































	/* eslint-disable @typescript-eslint/camelcase */ 
 
	import { tipoReferenciaService }  from '@/servicegerente';

	import {Watch, Component, Vue } from 'vue-property-decorator';
	@Component({
		components: {  },
	 })
    export default class TipoReferenciaEdit extends Vue {
		
		private model: any = null;


		private nome: any = null;
		private descricao: any= null; 

		private urlRetorno: any= '/tipoReferencia';
 
		private errors: any = { };

		created() {   
			window.alertProcessando();
			tipoReferenciaService.getTipoReferencia( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o tipoReferencia', error.data);
				window.alertProcessandoHide();
			}); 
		}
 
		@Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.descricao = this.model.descricao;   
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				descricao :this.descricao,  
			};  
			 
			 
			tipoReferenciaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	}
 

