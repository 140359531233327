/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..'; 
import { administradorModel } from './index.model';    
import axios  from 'axios'; 
import moment  from 'moment';   
import * as CryptoJS from 'crypto-js'; 
const secret_key =  administradorModel.getKeyCripto() ;
 

const url = administradorModel.getUrl() + '/assunto' ;
 
import  store   from '../../store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}


function getUrl(  ) { 
	return  url; 
}



function getAll( api = false ) { 

	return  new Promise((resolve, reject) => {   
		const assunto = localStorage.getItem('global_model_assuntos_todas');   
		if(assunto && !api){  
			const data_criacao = localStorage.getItem('data_global_model_assuntos_todas' );   
			if( moment()  < moment( data_criacao ).add(5, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(assunto, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		} 

		axios.get( url + '/all'  , {headers: authHeader() }  )
		.then(response => {  
			const encripted_assuntos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_assuntos_todas'   , encripted_assuntos  );    
			localStorage.setItem('data_global_model_assuntos_todas'  , moment().format('YYYY-MM-DD')  );   
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
 
	});  
 
}


 


function getAssunto( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 

 
function getPerguntasPorAssunto( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + '/perguntas'  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





 
function buscarTag( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  + "/tag/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function desativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/desativacao/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}

function ativar( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/ativacao/' + id , {}, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}





function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function adicionarTag( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/tag" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function importarTag( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/importar/tag" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 


function subirQuestoes( id: any   ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/subir/questoes" , {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}




function removerTag( id: any , tag: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/tag/' + tag ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout();				
			} 
			reject(error.response);
		}) 
	}); 
}

export const assuntoAdminService = {
	getAssunto ,
	getPerguntasPorAssunto,
	getUrl,
	getAll , 
	buscarTag,
	update,
	cadastrar,
	ativar,
	desativar,
	excluir,
	adicionarTag,
	removerTag,
	importarTag, 
	subirQuestoes,
};