








































































/* eslint-disable @typescript-eslint/camelcase */ 

	import { referenciaService  }  from '@/servicegerente';
	import $ from "jquery"  
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

		public  reloadDatatable = false ; 

        public config =  { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: referenciaService.getUrl() + '/' + this.$route.params.id + '/pergunta/datatable'
			},
			columns: [
				{ data: 'id', name: 'id' },
				{ data: 'texto_clean', name: 'texto_clean' },
				{ data: 'status', name: 'status' }, 
				{ data: 'banca_id', name: 'banca_id' }, 
				{ data: 'referencia_questao_nr', name: 'referencia_questao_nr' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};

        public url_retorno = '/referencia';
        public referencia: any = null;
        public dados: any = null;


		created() { 
			window.alertProcessando();
			referenciaService.getReferencia( this.$route.params.id )  
			.then(response => {
				window.alertProcessandoHide();
				this.referencia = response;
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar a referencia', error.data);
				window.alertProcessandoHide();
				this.$router.push(this.url_retorno);
			});  
 
		}

		
        public tableatualizada(  ) { 
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/v2/administracao/pergunta/show/' + id); 
            }); 
        }


		
 
		public  banca_id: any = null ; 
		public  pesquisa: any = null ; 
		public  ano: any = null ; 
        public ids: any =  null ;
        
		buscar() {
            if( this.banca_id != null && this.pesquisa  && this.ano  ){
                window.alertProcessando();
                const dados = {
                    banca_id: this.banca_id,  
                    pesquisa: this.pesquisa ,
                    ano: this.ano 
                };   
                referenciaService.buscarPerguntaIds(  this.$route.params.id , dados )
                .then(response => {
                    this.ids = response;
                    window.alertProcessandoHide(); 
                })
                .catch(error => {  
                    window.alertProcessandoHide(); 
                    console.log(error);
                }); 
            } 
		} 
        
        
		salvar() {
            if( this.banca_id != null && this.pesquisa != null  && this.ano ){
                window.alertProcessando();
                const dados = {
                    banca_id: this.banca_id,  
                    pesquisa: this.pesquisa, 
					ano: this.ano   
                };   
                referenciaService.cadastrarReferenciaPergunta(  this.$route.params.id , dados )
                .then(response => {
                    window.toastSucesso(response);  
                    window.alertProcessandoHide(); 
                    this.reloadDatatable = !this.reloadDatatable;
                })
                .catch(error => {  
                    window.alertProcessandoHide(); 
                    console.log(error);
                }); 
            } 
		} 


	}
	
 

