




































	import { moduloAdminService,  moduloService , disciplinaService }  from '@/servicegerente'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator'; 
    import { namespace } from 'vuex-class' 
    const gerente = namespace('gerente') 
	import FormModuloDisciplinas from './_DisciplinaFormAdicionar.vue' 
     
    @Component({
        components: { 
            'modulo-adicionar-disciplina':  FormModuloDisciplinas,
        },
    })
    export default class DisciplinaIndex extends Vue {
 
        @gerente.State
		public modulo!: any
 
        @gerente.State
		public disciplina!: any
 
        @gerente.Action
        public alterarDisciplina!: ( disciplina: any ) => void
 
        @gerente.Action
        public resetDisciplina!: (   ) => void

        public disciplinas: any= null; 

        created() {
            if(!this.modulo){
				this.$router.push('/v2/administracao'); 
			}
			else{
                this.config.ajax.url =  moduloService.getUrl() + '/' + this.modulo.id + '/disciplina/datatable';
                this.buscarDisciplina();
			}
        }
 
		disciplinaAdicionado(event: any) {
			this.disciplinas = event; 
			this.reloadDatatable = !this.reloadDatatable; 
		}
  
		public  reloadDatatable = false ; 

		public config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url: moduloService.getUrl()  
			},
			columns: [
				{ data: 'disciplina_id', name: 'modulo_disciplina.disciplina_id'  },
				{ data: 'nome', name: 'nome' },
				{ data: 'sigla', name: 'sigla' },  
				{ data: 'nr_total_perguntas', name: 'modulo_disciplina.nr_total_perguntas' },  
				{ data: 'nr_total_perguntas_validas', name: 'modulo_disciplina.nr_total_perguntas_validas' },  
                { data: 'rendimento', name: 'modulo_disciplina.rendimento', class: 'text-center' },
                { data: 'total_respostas', name: 'modulo_disciplina.total_respostas', class: 'text-center' },
                { data: 'peso', name: 'modulo_disciplina.peso', class: 'text-center' },
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};
        
        
		buscarDisciplina() {
			window.alertProcessando();
			moduloAdminService.buscarDisciplina( this.modulo.id )   
			.then(response => {
				this.disciplinas = response;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro("Não foi possivel achar os Disciplinas para adiocionar", error.data);
				window.alertProcessandoHide();
			});   
		}

		public tableatualizada( ) {   
            
            $('[btn-select]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                this.alterarDisciplina($(btn.currentTarget).data( ));
                window.toastSucesso('disciplina selecionada com sucesso!!' );  
            });


            // $('[btn-visualizar-modulos]').click(  (btn) => {  
            //     const id =   $(btn.currentTarget).data('id'); 
            //     this.$router.push('/disciplina/' + id + '/modulos');
            // });

            // $('[btn-editar]').click(  (btn) => {  
            //     const id =   $(btn.currentTarget).data('id'); 
            //     this.$router.push('/disciplina/edit/' + id  );
            // });

			$('[btn-duplicada]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a duplicadas ','' , 
                     () => {  
                        window.alertProcessando(); 
                        disciplinaService.duplicadas(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('duplicadas realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel duplicadas ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
             
		}
		 
	}

 
