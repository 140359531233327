






    /* eslint-disable @typescript-eslint/camelcase */ 
    import { Component, Vue ,Prop , Watch  } from 'vue-property-decorator'; 
    import Editor from '@tinymce/tinymce-vue';
    import tinymce from 'tinymce/tinymce';
    import 'tinymce/themes/silver'  
    import 'tinymce/icons/default' 
    import 'tinymce/plugins/advlist'  
    import 'tinymce/plugins/anchor' 
    import 'tinymce/plugins/autolink' 
    import 'tinymce/plugins/autoresize' 
    import 'tinymce/plugins/autosave' 
    import 'tinymce/plugins/charmap' 
    import 'tinymce/plugins/code' 
    import 'tinymce/plugins/codesample' 
    import 'tinymce/plugins/directionality' 
    import 'tinymce/plugins/emoticons' 

    import 'tinymce/plugins/fullpage' 
    import 'tinymce/plugins/fullscreen'  
    import 'tinymce/plugins/help' 
    import 'tinymce/plugins/hr' 
    import 'tinymce/plugins/image'  
    import 'tinymce/plugins/importcss'  
    import 'tinymce/plugins/insertdatetime' 
    import 'tinymce/plugins/link'  
    import 'tinymce/plugins/lists' 
    import 'tinymce/plugins/media' 
    import 'tinymce/plugins/nonbreaking' 
    import 'tinymce/plugins/pagebreak' 
    import 'tinymce/plugins/paste' 
    import 'tinymce/plugins/preview'  
    import 'tinymce/plugins/quickbars' // 快速工具栏
    import 'tinymce/plugins/save' 
    import 'tinymce/plugins/searchreplace'   
    import 'tinymce/plugins/tabfocus' 
    import 'tinymce/plugins/table' 
    import 'tinymce/plugins/template'  
    import 'tinymce/plugins/textcolor'  
    import 'tinymce/plugins/textpattern' 
    import 'tinymce/plugins/toc' 
    import 'tinymce/plugins/visualblocks' 
    import 'tinymce/plugins/visualchars'  
    import 'tinymce/plugins/wordcount'
    import 'tinymce/plugins/print'


    @Component({
        components: {
            Editor
        },
    })
    export default class HomeView extends Vue {
        
        @Prop( ) model!: any    
        
        public value: any = ""

        created() {
            this.value = this.model;
        }
        
        @Watch('model')
		modelAtualizado(newVal: any) {
			if (newVal && newVal !== this.value) {
                this.value = newVal
            }
		}
        
        @Watch('value')
		valueAtualizado(newVal: any) {
            this.$emit("valueAtualizado", this.value); 
		}

        mounted() {
            tinymce.init({});
        }
 
        init = {

            height: 450,
            menubar: true,
            relative_urls: false,
            remove_script_host: false,
            fontsize_formats: '7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 22pt 24pt 36pt 48pt',

            // cleanup: true,
            language_url: './tinymce/langs/pt_BR.js',  
            language: 'pt_BR',  
            skin_url: './tinymce/skins/ui/oxide',  
            plugins: 'help paste print template emoticons preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern autosave ', 
            
            
            toolbar: 'undo redo | formatselect fontselect fontsizeselect selectall | template fullscreen | bold italic underline strikethrough backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent |  charmap preview  removeformat | help | cut copy paste pastetext | link anchor table image | styleselect  | blockquote subscript superscript | media   emoticons hr pagebreak insertdatetime print | code  searchreplace visualblocks |  indent2em lineheight formatpainter axupimgs',  
            
            
            emoticons_database_url: './tinymce/emoticons/js/emojis.js',
               
            // font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',  
            
            // lineheight_formats: '0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5',  
             
            branding: false,  
             
            resize: true,  
            elementpath: false,  
            
            content_css: './tinymce/index.css',  
              
            
            templates : [
                {
                    title: 'Date modified example',
                    description: 'Adds a timestamp indicating the last time the document modified.',
                    content: '<p>Last Modified: <time class="mdate">This will be replaced with the date modified.</time></p>'
                }, 
            ], 
            color_cols: 10,
            color_map: [                
                '#000000', 'Black',
                '#202020', 'Gray',
                '#404040', 'Gray',
                '#606060', 'Gray',
                '#808080', 'Medium gray',
    
                '#9e9e9e', 'Medium gray',
                '#bcbcbc', 'Medium gray',
                '#d9d9d9', 'ligth gray',
                '#E6E6E6', 'ligth gray',
                '#f7f7f7', 'ligth gray',
    
    
    
                '#2f06e8', 'BLUE',
                '#5122ee', 'BLUE',
                '#723df4', 'BLUE',
                '#9459f9', 'BLUE',
                '#b574ff', 'BLUE', 
    
                '#006fb2', 'BLUE',
                '#1781c5', 'BLUE',
                '#2e92d8', 'BLUE',
                '#45a4eb', 'BLUE',
                '#5cb5fe', 'BLUE',
                
    
                '#0c4106', 'green',
                '#009c00', 'green',
                '#08af04', 'green',
                '#0fc308', 'Green',
                '#17d60b', 'Green', 
                '#1ee90f', 'Green', 
    
                '#36ef59', 'green',
                '#56f372', 'green',
                '#76f78b', 'green',
                '#95fba4', 'Green', 
    
    
    
                '#00a39b', 'BLUE',
                '#19b6ae', 'BLUE',
                '#33c9c0', 'BLUE',
                '#4cdcd3', 'BLUE',
                '#65efe5', 'BLUE', 
    
                '#61f4ac', 'BLUE',
                '#7df7c1', 'BLUE',
                '#99fad6', 'BLUE',
                '#b4fcea', 'BLUE',
                '#d0ffff', 'BLUE',
    
    
                '#07E5E1', 'BLUE',
                '#04FAF7', 'BLUE',
                '#2BFAF7', 'BLUE',
                '#5DFDFA', 'BLUE',
                '#7AFDFB', 'BLUE', 
    
                '#98FCFA', 'BLUE',
                '#B7FDFC', 'BLUE',
                '#CDFEFD', 'BLUE',
                '#E1FEFD', 'BLUE',
                '#EFFFFF', 'BLUE',
                
    
                
    
                '#430000', 'BLUE',
                '#56100e', 'BLUE',
                '#6a201c', 'BLUE',
                '#7d302b', 'BLUE',
                '#903f39', 'BLUE', 
    
                '#983933', 'BLUE',
                '#ab4942', 'BLUE',
                '#bd5951', 'BLUE',
                '#d0695f', 'BLUE',
                '#e2796e', 'BLUE',
                
    
    
    
                '#9b3f31', 'BLUE',
                '#b45747', 'BLUE',
                '#cd6e5d', 'BLUE',
                '#e68672', 'BLUE',
                '#ff9d88', 'BLUE', 
    
                '#916b4e', 'BLUE',
                '#ab8365', 'BLUE',
                '#c49b7c', 'BLUE',
                '#deb292', 'BLUE',
                '#f7caa9', 'BLUE',
    
    
                
    
                '#8e0000', 'BLUE',
                '#a90602', 'BLUE',
                '#c50c04', 'BLUE',
                '#e01306', 'BLUE',
                '#fb1909', 'BLUE', 
    
                '#f32f18', 'BLUE',
                '#f64e31', 'BLUE',
                '#f96d4a', 'BLUE',
                '#fc8b63', 'BLUE',
                '#ffaa7c', 'BLUE',
                
    
                
    
                '#a70073', 'BLUE',
                '#bc0485', 'BLUE',
                '#d10897', 'BLUE',
                '#e50ba8', 'BLUE',
                '#fa0fba', 'BLUE', 
    
                '#d80cd6', 'BLUE',
                '#e22ee0', 'BLUE',
                '#ec50eb', 'BLUE',
                '#f572f5', 'BLUE',
                '#ff94ff', 'BLUE',
    
    
    
    
                '#800087', 'BLUE',
                '#9d0da2', 'BLUE',
                '#ba1bbd', 'BLUE',
                '#d828d8', 'BLUE',
                '#f535f4', 'BLUE', 
    
                '#f040ee', 'BLUE',
                '#f455f3', 'BLUE',
                '#f869f7', 'BLUE',
                '#fb7dfb', 'BLUE',
                '#ff91ff', 'BLUE',
                
                
                
    
                '#3f0e3b', 'BLUE',
                '#5b1559', 'BLUE',
                '#781d77', 'BLUE',
                '#771e76', 'BLUE',
                '#963b94', 'BLUE', 
    
                '#b558b1', 'BLUE',
                '#d376cf', 'BLUE',
                '#f293ed', 'BLUE',
                '#ffa6ff', 'BLUE',
                '#f0b1eb', 'BLUE',
                
    
    
    
                '#9ea800', 'BLUE',
                '#cdd300', 'BLUE',
                '#fbff00', 'BLUE', 
                '#feff2a', 'BLUE',
                '#fdff3b', 'BLUE',
    
                '#feff54', 'BLUE', 
                '#ffff7d', 'BLUE',
                '#ffffa7', 'BLUE',
                '#fdffaf', 'BLUE',
                '#ffffe9', 'BLUE', 
                
    
    
            
                '#a75902', 'BLUE',
                '#d37f03', 'BLUE',
                '#ffa504', 'BLUE',
                '#fba726', 'BLUE',
                '#fcbd3c', 'BLUE', 
    
                '#fdd253', 'BLUE',
                '#fee869', 'BLUE',
                '#fffe80', 'BLUE',
                '#fff38a', 'BLUE',
                '#ffffc4', 'BLUE',
                
    
            
    
                '#745f00', 'BLUE',
                '#967d0f', 'BLUE',
                '#b89b1f', 'BLUE',
                '#dbb92e', 'BLUE',
                '#fdd73d', 'BLUE', 
    
                '#fdd73e', 'BLUE',
                '#fde15b', 'BLUE',
                '#feeb78', 'BLUE',
                '#fef595', 'BLUE',
                '#ffffb2', 'BLUE',
    
    
    
                '#b2ec1c', 'BLUE',
                '#c5f13a', 'BLUE',
                '#d9f657', 'BLUE',
                '#ecfa75', 'BLUE',
                '#ffff92', 'BLUE', 
    
                '#ffff8e', 'BLUE',
                '#efec74', 'BLUE',
                '#e0d95b', 'BLUE',
                '#d0c641', 'BLUE',
                '#c0b327', 'BLUE',
                
    
                '#f1c40f', 'BLUE',
        
                // '250ff3', 'BLUE',
                // '4022f6', 'BLUE',
                // '5b35f9', 'BLUE',
                // '7547fc', 'BLUE',
                // '905aff', 'BLUE', 
    
                // '6c4be7', 'BLUE',
                // '9d78ef', 'BLUE',
                // 'cea6f7', 'BLUE',
                // 'ffd3ff', 'BLUE',
                // 'ffd3ff', 'BLUE',
                 
            ],
        }  
    }
